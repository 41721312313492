import { DASHBOARD_HOME, RESET_STATE } from "../actions/Types";
    
    const INIT_STATE = {
      dashboard:[]
    };
    
    export default function DashboardReducer (state = INIT_STATE, action)  {
      const { type, payload } = action;
      switch (type) {
        case DASHBOARD_HOME:
          return {
            ...state,
            dashboard: payload.data
          };
        case RESET_STATE:
          return { ...INIT_STATE };
        default:
          return state;
      }
    };
    