const categories = [
    {
      "id": 1,
      "category_name": "Audio CDs",
      "special_note": "20% Off",
      "is_display_on_homepage": 1,
      "is_active": 1,
      "sequence": 1,
      "image":"https://picsum.photos/200/300"
    },
    {
      "id": 2,
      "category_name": "Video CDs",
      "special_note": "30% Off",
      "is_display_on_homepage": 0,
      "is_active": 0,
      "sequence": 1,
      "image":"https://picsum.photos/200/300"
  
    },
    {
      "id": 3,
      "category_name": "Electronics",
      "special_note": "5% Off",
      "is_display_on_homepage": 1,
      "is_active": 1,
      "sequence": 1,
      "image":"https://picsum.photos/200/300"
    },
    {
      "id": 4,
      "category_name": "Home Appliances",
      "special_note": "10% Off",
      "is_display_on_homepage": 0,
      "is_active": 0,
      "sequence": 1,
      "image":"https://picsum.photos/200/300"
  
    },
  ]

  export default categories;