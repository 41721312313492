import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';
import './Page404.css';
import Andriod from '../assets/images/android.png';
import Apple from '../assets/images/apple.png';

export default function Page404() {
  return (
    <>
      <main>
        <header>
          <section className="headerSection">
            <div className="logoContainer">
              <img src="./assets/images/samanvaya_saraswati.png" alt="" width="416" />
            </div>
            <h1>Download Samanvaya Saraswathi App</h1>
            <h3>Available for android and ios both</h3>
          </section>
        </header>

        <section className="mainContainer">
          <div className="description">
            <h4>For Android Users Download App</h4>
            <a href="https://play.google.com/store/apps/details?id=com.rushipeetham.samanvayasaraswati">
              <img src={Andriod} alt="andriod" width="150" height="50" />
            </a>
          </div>
          <div className="keyFeatures">
            <h4>For Ios(Apple) Users Download App From Below Link</h4>
            <a href="https://apps.apple.com/in/app/samanvaya-saraswathi/id6448216331">
              <img src={Apple} alt="apple" width="150" height="50" />
            </a>
          </div>
        </section>

        <section className="cta">
          <div className="description">
            <h1>Description</h1>
            <p style={{ textAlign: 'justify' }}>
              Samanvaya Saraswathi App gives you Audio and Video Discourses, articles From Rushipeetham Articles From
              Brahma Sree Samavedam SHANMUKHA Sarma garu
            </p>
            <p style={{ textAlign: 'justify' }}>
              Rushipeetham Charitable Trust, established to preserving Indian classical Arsadharma, is headed by the
              Parameswara himself. Rushipeetham acts as a supporting platform for preserving Vedic Culture and promoting
              Vedic scholars and sahitivettas. Working with Chittasuddhi (purity of cause) and satsankalpam (good
              cause), Rushipeetham is committed to upholding Indian dharmika values and traditions. All sections of our
              society are part of this effort, not just the erudite, as all humanity can benefit from our efforts to
              build a better society.
            </p>
            <p style={{ textAlign: 'justify' }}>
              Vande Sree Matharam:
              <br />
              Brahma Sree Samavedam SHANMUKHA Sarma garu is a profound speaker and blessed with wonderful oratory skills
              on our Sastras , Puranas, Itihasas and Vedas. Sree Samavedam garu started his spiritual journey early in
              his age with the aim of protecting the Veda Parampara and also sharing the wealth of knowledge that was
              gifted to us by our ancient sages and “Rushis” through his discourses. Some of his discourses includes –
              Siva Puranam, RudhraBhashyam, Siva Leela Vilasam, Sree Krishna Tathvam, Narayaneeyam, Lalitha Sahasra Nama
              Vybhavam, Sri Vishnu Sahasra Nama Stotra Bhasyamu, Soundharya Lahari and Bhagavatha Sapthaham and the list
              goes on…His first lecture on “Agni” at the Sivaramakrishna kshetram in Vijayawada laid the foundation for
              the endeavor and after that he never looked back. He won many accolades and won many followers during his
              journey.
            </p>
            <p style={{ textAlign: 'justify' }}>
              Philosophy of his Teachings the Six Facets of Shanmukha Sarma garu:
              <br />
              Sree Samavedam garu is called “Samanvaya Saraswathi”, for bridging various topics from different Sastras
              and puranas to the context which is unparalleled. In his teachings, he goes on explaining the meaning of
              the subject in all the dimensions of bhava ardha , loukika ardha, vedantha and tatvika ardha. Sree
              Samavedam garu emphasize on “Bharateeya Sanatana Dharma” and tries to instill the values. The discourses
              of Sri Sarma garu will be so lively that one gets immensely inquisitive to know more and more about the
              subject.
            </p>
            <p style={{ textAlign: 'justify' }}>
              Samanvaya Saraswathi: <br />
              Sree Samavedam garu has started “RushiPeetham” to channelize his thoughts on spirituality , Vedic knowledge
              and Bharateeyata through Rushi Peetham – magazine and through TRUST - promoting the contributions of the
              eminent personalities and vedic scholars in the areas of art, culture and also in the research and
              development.
            </p>
          </div>
        </section>
      </main>
    </>
  );
}
