import formDataJWTServer from '../api/formDataJWTServer';
import { SetNewAlert } from './AlertActions';
import { HandleExceptionWithSecureCatch, HandleManualException } from './CombineCatch';
import { 
    DELETE_IMAGE, 
    DELETE_FILES
} from './Types';

const DeleteFiles = (filePath) => async(dispatch) => {
    try{
        const response = await formDataJWTServer.delete(`/api/delete-file?file_path=${filePath}`);
        if (response.data.meta.status === 200) {
            // dispatch(
			// 	SetNewAlert({
			// 		msg: 'File deleted successfully!',
			// 		alertType: 'success',
			// 	})
			// );
			dispatch({
				type: DELETE_FILES,
				payload: response.data,
			});
		} else {
            dispatch(
				SetNewAlert({
					msg: 'Something went wrong, file could not deleted!',
					alertType: 'danger',
				})
			);
			dispatch(HandleManualException(response.data));
		}
    }catch(e){
        dispatch(HandleExceptionWithSecureCatch(e));
    }
} 

export {
	DeleteFiles
};
