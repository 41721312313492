import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
// @mui
import {
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Button,
    MenuItem,
    IconButton,
    InputLabel,
    Stack,
    CircularProgress
} from '@mui/material';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { notificationValidationSchema } from '../../utils/validation/NotificationValidation';
import { GetCategoryById, GetCategoryForDropdown } from '../../actions/CategoriesAction';
import { AddNotification, EditNotification } from '../../actions/NotificationAction';
import { UploadNotificationImage } from '../../actions/UploadActions';
import { SetNewAlert } from '../../actions/AlertActions';
import { ShowUploadLoader } from '../../actions/CommonAction';
import { DeleteFiles } from '../../actions/DeleteAction';
import { NotificationDeviceTypeArr, NotificationLinkTypeArr } from '../common/constants';
// ----------------------------------------------------------------------

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
        <DialogTitle sx={{ m: 0, p: 2,}} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 12,
                        top: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};// END BootstrapDialogTitle 

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};// END BootstrapDialogTitle

export default function NotificationDialogBox(props) {

    /* START Configure Data On Page */
    const dispatch = useDispatch();
    const {data} = props;
    const [upload, setUpload] = useState([]);
    const [isImageUploaded, setIsImageUploaded] = useState(false);
    const [showProductList, setShowProductList] = useState(false);
    // let productKey = data?(data.notification_product?.product_key===null)?"":data?.notification_product?.product_key:"";
    const { 
            addNotificationLog,
            editNotificationLog, 
            deleteNotificationLog, 
            notificationImage, 
            uploadNotificationImageLog, 
            showUploadLoader, 
            categoriesForDropdown,
            categoryById,
            deleteFileLog,
        } = useSelector((state) => ({
        addNotificationLog: state.notifications.addNotificationLog,
        editNotificationLog: state.notifications.editNotificationLog,
        deleteNotificationLog: state.notifications.deleteNotificationLog,
        notificationImage: state.upload.notificationImage,
        uploadNotificationImageLog: state.upload.uploadNotificationImageLog,
        deleteFileLog: state.upload.deleteFileLog,
        showUploadLoader: state.common.showUploadLoader,
        categoriesForDropdown: state.categories.categoriesForDropdown,
        categoryById: state.categories.categoryById,
    })); // END useSelector

    /* END Configure Data On Page */

    const checkTitle = (e)=>{
        if(values.title === undefined || !values.title){
            dispatch(SetNewAlert({
                msg: "Please enter notification title first",
                alertType: "danger",
            }));
            e.preventDefault();
            return false;
        } 
        if(values.link_type === undefined || !values.link_type){
            dispatch(SetNewAlert({
                msg: "Please select notification link type first",
                alertType: "danger",
            }));
            e.preventDefault();
            return false;
        } 
        return true;
    }// END checkTitle
    
    /* START Handle Change Action */

    const handleImgUploadChange = (e) => {
        if((e.target.files && e.target.files[0]) && checkTitle(e)){
            const ext = e.target.value.split('.').pop();
            if(ext !== "jpg" && ext !== "png" && ext !== "jpeg"){
                dispatch(SetNewAlert({
                    msg: "Only png, jpg and jpeg type file allowed",
                    alertType: "danger",
                }));
            }else{
                const formData = new FormData();
                formData.append('upload_for', 'NOTIFCATION_IMAGES');
                formData.append('image', e.target.files[0]);
                formData.append('category_name', "NOTIFICATION");
                formData.append('product_name', values.title.substring(0,20));
                formData.append('notification_link_type', values.link_type);
                dispatch(ShowUploadLoader({loading:true,type:"notificationImage"}))
                dispatch(UploadNotificationImage(formData));
                if(e.target.files.length !==0){
                    setFieldValue("image","something");
                }
                else {
                    setFieldValue("image","");
                }
            }           
        }    
    }// END handleImgUploadChange

    const handleImageDelete = (data) => {
        const filteredArray = upload.filter((item) => item.file_path !== data.file_path);
        setUpload(filteredArray);        
        dispatch(DeleteFiles(data.file_path));
        if(filteredArray.length===0){
            setIsImageUploaded(false);
            setFieldValue("image","");
        }
    }// END handleImageDelete

    const handleNotificationForChange = (e) => {
        // console.log("notification device type value selected - ", e.target.value)
    }// END handleNotificationForChange

    const handleNotificationLinkChange = (e) => {
       // console.log('handleNotificationLinkChange', e.target.value, NotificationLinkTypeArr);
    }// END handleNotificationLinkChange

    // Removed uploaded files/images which were browsed but cancel without submitting 
    const cancelClick = (data) =>{
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        if(data !== null){
            const extraImagesArr = upload.splice(data.notification_images.length);
            // eslint-disable-next-line no-plusplus
            for(let i = 0; i < extraImagesArr.length; i++){
                dispatch(DeleteFiles(extraImagesArr[i].file_path));
            }     
        }        
        props.onClose();
    }// END cancelClick

    const handleCategoryChange = (e) => {
        dispatch(GetCategoryById(e.target.value))
        setShowProductList(true)
        setFieldValue("product","");
        // setFieldValue("product_key","");
    }// END handleCategoryChange
 
    const handleProductChange = (e) => {
        setFieldValue("product",e.target.value); 
    }// END handleProductChange

    /* END Handle Change Action */

    /* START Use Effect */

    React.useEffect(()=>{
        if(data){
            if(data?.notification_images?.length!==0){
                const notificationImageArray = upload;
                data.notification_images.forEach(element => notificationImageArray.push({"file_path":element.file_path || ''}));
                setUpload(notificationImageArray);
                setIsImageUploaded(true)
                setFieldValue("image","something");
            }
            else {
                setIsImageUploaded(false)
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[data]);

    const firstRun = React.useRef(true);
    React.useEffect(()=>{
        if (firstRun.current) {
            firstRun.current = false;
            return;
        }
        props.onClose()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[addNotificationLog, editNotificationLog, deleteNotificationLog, deleteFileLog])

    const secondRun = React.useRef(true);
    React.useEffect(()=>{
        if (secondRun.current) {
            secondRun.current = false;
            return;
        }
        const notificationImageArray = upload;
        notificationImageArray.push({"file_path":notificationImage.url});
        setUpload(notificationImageArray);
        setIsImageUploaded(true)
        dispatch(ShowUploadLoader({loading:false,type:""}))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[uploadNotificationImageLog]);

    React.useEffect(()=>{
        dispatch(GetCategoryForDropdown())
        //   dispatch(GetUploadedFile(27))
        if(data && data?.notification_product){
            dispatch(GetCategoryById(data?.notification_product?.category_id))
            setShowProductList(true)
        }
    },[data]);

    /* END Use Effect */

    /* START Formik Config */

    const formik = useFormik({
        enableReinitialize:true,
        initialValues: {
            title:data?data.notification_title:"",
            message:data?data.notification_message:"",
            image:data?data.notification_images[0]:"",
            device_type: data?data.device_type:"",
            link_type: data?(data.notification_link_type===null)?"No Link":data?.notification_link_type:"",
            web_url: data?(data.notification_web_url===null)?"":data?.notification_web_url:"",
            category: data?(data.notification_product?.category_id===null)?"":data?.notification_product?.category_id:"",
            product: data?(data.notification_product_id===null)?"":data?.notification_product_id:"",
            // product_key: data?(data.notification_product?.product_key===null)?"":data?.notification_product?.product_key:"",
        },
        validationSchema: notificationValidationSchema,
        onSubmit: (value) => {

            const notificationImage = [];
            upload?.map((value,index)=>{
                notificationImage.push(value.file_path)
                return null;
            });
            
            if(value.device_type === "Both" || value.device_type === "All"){
                return  dispatch(SetNewAlert({
                    msg: "Please enter valid device type",
                    alertType: "danger",
                }));
            }

            if(value.link_type === NotificationLinkTypeArr[1].value){
                // const urlRegex = /^(http[s]?:\/\/){0,1}(w{3,3}?\.)[-a-z0-9+&@#/%?=~_|!:,.;]*[-a-z0-9+&@#/%=~_|]/;
                const urlRegex = /((?:https?:\/\/|www\.)(?:[-a-z0-9]+\.)*[-a-z0-9]+.*)/i;
                const result =  value.web_url.match(urlRegex);              
                if(
                    value.web_url === "" || 
                    value.web_url === undefined || 
                    result === null
                ){
                    return  dispatch(SetNewAlert({
                        msg: "Please enter valid web URL",
                        alertType: "danger",
                    }));
                }
            }

            if(value.link_type === NotificationLinkTypeArr[2].value){
                if(value.category === "" || value.category === undefined){
                    return  dispatch(SetNewAlert({
                        msg: "Please select a target category",
                        alertType: "danger",
                    }));
                }
            }

            if(value.link_type === NotificationLinkTypeArr[2].value){
                if(value.product === "" || value.product === undefined){
                    return  dispatch(SetNewAlert({
                        msg: "Please select a target product",
                        alertType: "danger",
                    }));
                }
            }

            const reqObj = {
                "notification_title": value.title,
                "notification_message": value.message,
                "notification_image_urls": notificationImage,
                "device_type": value.device_type,
                "notification_link_type": value.link_type,
                "notification_product_id":  "",
                "notification_product_key":  "",
                "notification_web_url":  ""
            }
            
            if(value.link_type === NotificationLinkTypeArr[1].value && value.web_url !== "")
                reqObj.notification_web_url =  value.web_url;
            else if(value.link_type === NotificationLinkTypeArr[2].value){
                reqObj.notification_product_id =  value.product;
                reqObj.notification_product_key = categoryById.products.find((event) => event.id === value.product).product_key;
            }
            
            console.log('Submit Notification Request Object', reqObj);
            if(data && isImageUploaded) {
                dispatch(EditNotification(reqObj,data.id));
            }
            else if(isImageUploaded){
                dispatch(AddNotification(reqObj));               
            }
            return true;            
        },
    });// END useFormik

    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik;

    /* END Formik Config */

    return (
        <>
            <Dialog
                open={props.open}
                // onClose={props.onClose}
                className="dialog-box-section">
                <BootstrapDialogTitle id="customized-dialog-title" onClose={()=> cancelClick(data)}>
                    Notification
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12}>
                            <TextField
                                id="outlined-name-input"
                                label="Notification Title*"
                                inputProps={{ maxLength: 256 }}
                                type="text"
                                value={values.title}
                                fullWidth
                                error={Boolean(touched.title && errors.title)}
                                helperText={touched.title && errors.title}
                                {...getFieldProps('title')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                multiline
                                rows={2}
                                id="outlined-name-input"
                                label="Notification Message*"
                                inputProps={{ maxLength: 1024 }}
                                type="text"
                                value={values.message}
                                fullWidth
                                error={Boolean(touched.message && errors.message)}
                                helperText={touched.message && errors.message}
                                {...getFieldProps('message')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <TextField
                                id="device_type"
                                select
                                label="Notification Device Type*"
                                fullWidth
                                error={Boolean(touched.device_type && errors.device_type)}
                                helperText={touched.device_type && errors.device_type}
                                onChange={(e)=>{
                                    handleNotificationForChange(e)
                                    formik.handleChange(e)
                                }}
                                value={values?.device_type === "Both" ? "All" : values?.device_type }
                                {...getFieldProps('device_type')}
                            >
                                {NotificationDeviceTypeArr?.map((option) => (
                                    // disabled={!(option.value==="all")}
                                    <MenuItem key={option.value} value={option.value} >
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <TextField
                                id="link_type"
                                name="link_type"
                                select
                                label="Notification Link Type*"
                                fullWidth
                                error={Boolean(touched.link_type && errors.link_type)}
                                helperText={touched.link_type && errors.link_type}
                                onChange={(e)=>{
                                    handleNotificationLinkChange(e)
                                    formik.handleChange(e)
                                }}
                                value={values?.link_type}
                                // {...getFieldProps('link_type')}
                            >
                                {NotificationLinkTypeArr?.map((option) => (
                                    // disabled={!(option.value==="all")}
                                    <MenuItem key={option.value} value={option.value} >
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        {                            
                            values?.link_type === NotificationLinkTypeArr[1].value ?
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField
                                    id="outlined-name-input"
                                    label="Notification Web URL*"
                                    inputProps={{ maxLength: 250 }}
                                    type="text"
                                    value={values?.web_url}
                                    fullWidth
                                    error={Boolean(touched.web_url && errors.web_url)}
                                    helperText={touched.web_url && errors.web_url}
                                    {...getFieldProps('web_url')}
                                />
                            </Grid> : 
                            values?.link_type === NotificationLinkTypeArr[2].value ?
                            <>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                        id="category"
                                        name="category"
                                        select
                                        label="Target Category*"
                                        onChange={(e)=>{
                                            handleCategoryChange(e)
                                            formik.handleChange(e)
                                        }}
                                        value={values.category}
                                        fullWidth
                                        error={Boolean(touched.category && errors.category)}
                                        helperText={touched.category && errors.category}
                                        // {...getFieldProps('category')}
                                    >
                                        {
                                            categoriesForDropdown?.map((option) => (
                                                <MenuItem key={option.id} value={option.id}>
                                                    {option.category_name}
                                                </MenuItem>
                                            ))
                                        }
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                        id="productId"
                                        select
                                        name="status"
                                        label="Target Product*"
                                        value={values.product}
                                        onChange={(e)=>{
                                            handleProductChange(e)
                                            formik.handleChange(e)
                                        }}
                                        fullWidth
                                        error={Boolean(touched.product && errors.product)}
                                        helperText={touched.product && errors.product}
                                        // {...getFieldProps('product')}
                                    >
                                        {
                                            showProductList?categoryById?.products?.map((option) => 
                                                <MenuItem key={option.id} value={option.id}>
                                                    {option.product_name}
                                                </MenuItem>
                                            ):null
                                        }
                                    </TextField>
                                </Grid>                                 
                            </> : ""
                        }
                        <Grid item xs={12}>
                            <InputLabel sx={{mb:"10px",fontWeight:600}}>
                                Notification Image
                            </InputLabel>
                            <Grid item xs={12}>
                                <Grid item xs={12}>
                                    {
                                        (showUploadLoader?.loading && showUploadLoader.type==="notificationImage") ?
                                        <CircularProgress style={{ color: '#FFA728' }} />:
                                        <IconButton color="#393939" aria-label="upload picture" component="label" onClick={checkTitle}>
                                            <input hidden accept="image/*" type="file" onChange={handleImgUploadChange} />
                                            <CloudUploadOutlinedIcon fontSize='large' />
                                        </IconButton>
                                    }
                                    <div className='errorMessage'>{touched.image && errors.image}</div> 
                                </Grid>
                                {
                                    isImageUploaded ? upload?.map(
                                        (value,index)=>
                                        // eslint-disable-next-line react/jsx-key
                                        <Stack direction="row"  alignItems="center">
                                            <Grid item xs={8}>
                                                <TextField
                                                    key={index+1}
                                                    id="image"
                                                    name='image'
                                                    type="text"
                                                    fullWidth
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    value={value?.file_path?.split("/").pop() || ''}
                                                    error={Boolean(touched.image && errors.image)}
                                                    helperText={touched.image && errors.image}
                                                    // {...getFieldProps('image')}
                                                />
                                            </Grid>
                                            <CloseIcon onClick={()=>handleImageDelete(value)} style={{marginLeft:"10px",cursor:"pointer"}}/>
                                        </Stack>
                                    )
                                    : ''                                    
                                }
                                {/* <div className='errorMessage'>{touched.image && errors.image}</div> */}
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ justifyContent: "end" ,pr:"25px"}}>
                    <Button  variant="outlined" onClick={()=> cancelClick(data)} sx={{minWidth:'100px',color:(theme) => theme.palette.text.darkGrey,borderColor:(theme) => theme.palette.text.darkGrey}} >Cancel</Button>
                    <Button variant="contained" disabled={showUploadLoader.loading} onClick={formik.handleSubmit} style={{minWidth:'100px'}}>Send</Button>
                </DialogActions>
            </Dialog>
        </>
    );// END Return
    
}// END NotificationDialogBox
