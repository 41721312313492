
// material
import { Stack,Avatar, styled } from '@mui/material';
// component
import Iconify from '../../../components/iconify';
// ----------------------------------------------------------------------

export default function PagesMoreMenu(props) {

	const handleClose = () => {
		props.handleEdit();
	};

	const handleDelete = () => {
		props.handleDelete();
	};

	const handleView = () => {
		props.handleView()
	}

	const handleCancelation = () => {
		props.handleCancel()
	}

	const StyledAvatar = styled(Avatar)(({ theme }) => ({
		// border: '1px solid #393939',
		// backgroundColor: 'transparent',
		// color: '#393939',
		cursor: 'pointer',
		width: 34,
		height: 34
	}));

	return (
		<>
			<Stack direction="row" spacing={1}>
				{	
					props?.handleEdit && 
					<StyledAvatar
						onClick={handleClose}
						sx={{
							backgroundColor: 'blue',
							color: '#FFFFFF',
							border: '1px solid blue'
						}}
						>
						<Iconify
							icon={'eva:edit-2-outline'}
							sx={{ width: 22, height: 22}}
						/>
					</StyledAvatar>
				}
				{	
					props?.handleView && 
					<StyledAvatar
						sx={{
							backgroundColor: 'green',
							color: '#FFFFFF',
						}}
						onClick={handleView}
						>
						<Iconify
							icon={'eva:eye-outline'}
							sx={{ width: 22, height: 22}}
						/>
					</StyledAvatar>
				}
				{	
					props?.handleDelete && 
					<StyledAvatar
						sx={{
						
							backgroundColor: 'red',
							color: '#FFFFFF',
							border: '1px solid red'

						}}
						onClick={handleDelete}>
						<Iconify
							icon={'eva:trash-2-outline'}
							sx={{ width: 22, height: 22}}
						/>
						{/* <Icon src={getIcon('eva:shopping-bag-fill')} /> */}
					</StyledAvatar>
				}
				{	
					props?.handleCancel && 
					<StyledAvatar
						sx={{					
							backgroundColor: props.disableCancel? '#ffa728' : '#edd1b6' ,
							color: '#FFFFFF',
							border: `1px solid ${props.disableCancel? '#ffa728'  : '#edd1b6'} `
						}}
						title={props.disableCancel? 'Cancel user subscription' : 'User subscription already cancelled'}
						onClick={props.disableCancel && handleCancelation}>
						<Iconify
							icon={'mdi:book-cancel'}
							sx={{ width: 22, height: 22}}							
						/>
					</StyledAvatar>
				}
			</Stack>    
		</>
	);
}
