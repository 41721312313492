import React from "react";
import { styled } from '@mui/material/styles';

import {
  Badge,
  Avatar,
  Grid
  
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 30,
  height: 30,
  border: `2px solid ${theme.palette.background.paper}`,
}));

export default function ImageUpload(props) {
  const onCloseIconClick = (data) => {
    props.handleDelete(data);
  };

    return (
      <Grid item xs={12} className="image-swiper-container">
        {props.imageArray?.map((value,index)=><Badge
            sx={{marginRight:'10px'}}
            overlap="circular"
            onClick={()=>onCloseIconClick(value)}
            anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
            badgeContent={
                <SmallAvatar alt="Remy Sharp" sx={{ bgcolor: "red",cursor:'pointer'}}>
                  <CloseIcon />
                  </SmallAvatar>
            }
            >
            {/* <Avatar alt="Travis Howard" variant='circle' src="https://rushipeetham.com/wp-content/uploads/Sivanjali-copy-1-300x300.jpg" sx={{ width: 100, height: 100 }} /> */}
            <Avatar alt="Travis Howard" variant='circle' src={value.url || value.file_path} sx={{ width: 100, height: 100 }} />
            </Badge>)}
                           
      </Grid>
    );
}

