import { CHANGE_PASSWORD, FORGOT_PASSWORD, LOGIN, LOG_OUT, RESET_STATE } from '../actions/Types';

const INIT_STATE = {
	authLog: null,
	isLogged: false,
	isLoggedOut: false,
	loggedUser: null,
	loggedUserId: null,
	changePasswordLog: false,
	forgotPasswordLog: false,
};

export default function AuthReduer(state = INIT_STATE, action) {
	const { type, payload } = action;

	switch (type) {
		case LOGIN:
			return {
				...state,
				loggedUser: payload.data,
				isLogged: true,
			};
		case LOG_OUT:
			return {
				...state,
				isLoggedOut: !state.isLoggedOut,
			};
		case CHANGE_PASSWORD:
			return {
				...state,
				changePasswordLog: !state.changePasswordLog,
			};
		case FORGOT_PASSWORD:
			return {
				...state,
				forgotPasswordLog: !state.forgotPasswordLog,
			};
		case RESET_STATE:
			return { ...INIT_STATE };
		default:
			return state;
	}
}
