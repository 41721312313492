import {
  CREATE_FEED,
  DELETE_FEED,
  GET_ALL_FEED,
  GET_FEED_BY_ID,
  GET_MOST_FEV_FEED,
  RESET_STATE,
  UPDATE_FEED,
} from '../actions/Types';

const INIT_STATE = {
  mostFevFeed: [],
  feeds: [],
  pageInfo: {},
  addFeedLog: false,
  editFeedLog: false,
  deleteFeedLog: false,
  feedById: {},
};

export default function FeedsReducer(state = INIT_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_FEED:
      return {
        ...state,
        feeds: payload.data.data,
        pageInfo: payload.data,
      };
    case UPDATE_FEED:
      return {
        ...state,
        editFeedLog: !state.editFeedLog,
      };
    case CREATE_FEED:
      return {
        ...state,
        addFeedLog: !state.addFeedLog,
      };
    case DELETE_FEED:
      return {
        ...state,
        deleteFeedLog: !state.deleteFeedLog,
      };

    case GET_FEED_BY_ID:
      return {
        ...state,
        feedById: payload.data,
      };
    case GET_MOST_FEV_FEED:
      return {
        ...state,
        mostFevFeed: payload.data,
        pageInfo: payload.data,
      };
    case RESET_STATE:
      return { ...INIT_STATE };
    default:
      return state;
  }
}
