import JWTServer from '../api/withJWTServer';
import { SetNewAlert } from './AlertActions';
import { HandleExceptionWithSecureCatch, HandleManualException } from './CombineCatch';
import {
	CREATE_TAGS,
	DELETE_TAGS,
	GET_ALL_TAGS,
	GET_TAGS_BY_ID,
	GET_TAGS_FOR_DROPDOWN,
	UPDATE_TAGS,
} from './Types';

const GetAllTags = (page, limit, searchValue,statusValue) => async (dispatch) => {
	try {
		const response = await JWTServer.get(`/api/get-all-product-tags?page=${page}&limit=${limit}&search=${searchValue}&status=1`);

		if (response.data.meta.status === 200) {
			dispatch({
				type: GET_ALL_TAGS,
				payload: response.data,
			});
		} else {
			dispatch(HandleManualException(response.data));
		}
	} catch (e) {
		dispatch(HandleExceptionWithSecureCatch(e));
	}
};

const GetTagsForDropdown = () => async (dispatch) => {
	try {
		const response = await JWTServer.get(`/api/get-all-product-tags?status=1`)
		if (response.data.meta.status === 200) {
			dispatch({
				type: GET_TAGS_FOR_DROPDOWN,
				payload: response.data,
			});
		} else {
			dispatch(HandleManualException(response.data));
		}
	} catch (e) {
		dispatch(HandleExceptionWithSecureCatch(e));
	}
};

const AddTags = (params) => async (dispatch) => {
	// console.log("ADD ROLE PARAMS",params);
	try {
		const response = await JWTServer.post('/api/create-product-tag', params);

		if (response.data.meta.status === 200) {
			dispatch({
				type: CREATE_TAGS,
				payload: response.data,
			});
			dispatch(
				SetNewAlert({
					msg: response.data.meta.message,
					alertType: 'success',
				})
			);
		} else {
			dispatch(HandleManualException(response.data));
		}
	} catch (e) {
		dispatch(HandleExceptionWithSecureCatch(e));
	}
};

const EditTags = (params, categoryId) => async (dispatch) => {
	try {
		const response = await JWTServer.put(`/api/update-product-tag/${categoryId}`, params);
		if (response.data.meta.status === 200) {
			dispatch({
				type: UPDATE_TAGS,
				payload: response.data,
			});
			dispatch(
				SetNewAlert({
					msg: response.data.meta.message,
					alertType: 'success',
				})
			);
		} else {
			dispatch(HandleManualException(response.data));
		}
	} catch (e) {
		dispatch(HandleExceptionWithSecureCatch(e));
	}
};

const DeleteTags = (params, status) => async (dispatch) => {
	try {
		const response = await JWTServer.delete(`/api/delete-product-tag/${params}`);
		// console.log('Tags deleted response', response);
		if (response.data.meta.status === 204 || response.data.meta.status === 200) {
			dispatch({
				type: DELETE_TAGS,
				payload: response.data,
			});
			dispatch(
				SetNewAlert({
					msg: response.data.meta.message,
					alertType: 'success',
				})
			);
		} else {
			dispatch(HandleManualException(response.data));
		}
	} catch (e) {
		dispatch(HandleExceptionWithSecureCatch(e));
	}
};

const GetCategoryById = (id) => async (dispatch) => {
	try {
		const response = await JWTServer.get(`/api/get-category-by-id/${id}`);
		if (response.data.meta.status === 200) {
			dispatch({
				type: GET_TAGS_BY_ID,
				payload: response.data,
			});
		} else {
			dispatch(HandleManualException(response.data));
		}
	} catch (e) {
		dispatch(HandleExceptionWithSecureCatch(e));
	}
};

export { GetAllTags, GetTagsForDropdown, AddTags, EditTags, DeleteTags, GetCategoryById };
