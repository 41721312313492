import {
	CREATE_NOTIFICATION,
	DELETE_NOTIFICATION,
	GET_ALL_NOTIFICATION,
	RESET_STATE,
	UPDATE_NOTIFICATION,
} from '../actions/Types';

const INIT_STATE = {
	notifications: [],
	pageInfo: {},
	addNotificationLog: false,
	editNotificationLog: false,
	deleteNotificationLog: false,
};

export default function NotificationReducer(state = INIT_STATE, action) {
	
	const { type, payload } = action;

	switch (type) {
		case GET_ALL_NOTIFICATION:
			return {
				...state,
				notifications: payload.data.data,
				pageInfo: payload.data,
			};
		case UPDATE_NOTIFICATION:
			return {
				...state,
				editNotificationLog: !state.editNotificationLog,
			};
		case CREATE_NOTIFICATION:
			return {
				...state,
				addNotificationLog: !state.addNotificationLog,
			};
		case DELETE_NOTIFICATION:
			return {
				...state,
				deleteNotificationLog: !state.deleteNotificationLog,
			};
		case RESET_STATE:
			return { ...INIT_STATE };
		default:
			return state;
	}
}
