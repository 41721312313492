import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sentenceCase } from 'change-case';
import { Helmet } from 'react-helmet-async';

/* @mui */
import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Tooltip,
} from '@mui/material';

/* components */
import Label from '../components/label';
import Scrollbar from '../components/scrollbar';

/* sections */
import { PagesHead, PagesToolbar, PagesMoreMenu } from '../sections/@dashboard/common';
import { DeleteTags, GetAllTags } from '../actions/TagsAction';

/* Dialogs */
import TagsDialogBox from '../components/DiaglogBox/TagsDialogBox';
import ViewTagsDialog from '../components/DiaglogBox/ViewTagsDialog';
import DeleteConfirmationDialog from '../components/DiaglogBox/DeleteConfirmationDialog';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'tagname', label: 'Tag Name', alignRight: false },
  { id: 'products', label: 'Attached Products', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'action', label: 'Actions', alignRight: false },
];

const statusFilterOptions = [
  { id: 1, status: 'All', value: 9 },
  { id: 2, status: 'Active', value: 1 },
  { id: 3, status: 'Inactive', value: 0 },
];

export default function ProductTagsPage() {
  /* START Config Data For Page */

  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = React.useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [data, setData] = React.useState(null);
  const [searchValue, setSearchValue] = React.useState('');
  const [viewTagDialogOpen, setViewTagDialogOpen] = React.useState(false);

  const { tags, pageInfo, addTagsLog, editTagsLog, deleteTagsLog } = useSelector((state) => ({
    tags: state.tags.tags,
    pageInfo: state.tags.pageInfo,
    addTagsLog: state.tags.addTagsLog,
    editTagsLog: state.tags.editTagsLog,
    deleteTagsLog: state.tags.deleteTagsLog,
  }));

  // -----------status filter--------------------
  const [statusValue, setStatusValue] = React.useState(statusFilterOptions[0].value);
  const handleStatusChange = (data) => {
    setStatusValue(data);
  };

  /* END Config Data For Page */

  /* START Use Effects */
  useEffect(() => {
    dispatch(GetAllTags(1, rowsPerPage, searchValue, statusValue));
    setPage(0);
  }, [statusValue]);

  useEffect(() => {
    setConfirmationDialogOpen(false);
    dispatch(GetAllTags(page + 1, rowsPerPage, searchValue, statusValue));
  }, [addTagsLog, editTagsLog, deleteTagsLog]); // END useEffect

  useEffect(() => {
    // console.log('use effect called', searchValue);
    const delayDebounce = setTimeout(() => {
      dispatch(GetAllTags(1, rowsPerPage, searchValue, statusValue));
      setPage(0);
    }, 1000);
    return () => clearTimeout(delayDebounce);
  }, [searchValue]); // END useEffect

  /* END Use Effects */

  /* START Handle Change Actions */

  // pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(GetAllTags(newPage + 1, rowsPerPage, searchValue, statusValue));
  }; // END handleChangePage

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
    dispatch(GetAllTags(1, parseInt(event.target.value, 10), searchValue, statusValue));
  }; // END handleChangeRowsPerPage

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  }; // END handleSearchChange

  const handleClearClick = () => {
    dispatch(GetAllTags(1, rowsPerPage, ''));
    setPage(0);
    setSearchValue('');
  }; // END handleClearClick

  // Open dialog click
  const handleClickOpen = (e) => {
    setData(null);
    setOpen(true);
  }; // END handleClickOpen

  const handleEdit = (data) => {
    setData(data);
    setOpen(true);
  }; // END handleEdit

  const handleDelete = (data) => {
    setConfirmationDialogOpen(true);
    setData(data);
  }; // END handleDelete

  const handleClose = () => {
    setOpen(false);
    setData(null);
  }; // END handleClose

  const handleViewTagDialogClose = () => {
    setViewTagDialogOpen(false);
    setData(null);
  }; // END handleViewTagDialogClose

  const handleViewTagDialogOpen = (data) => {
    setViewTagDialogOpen(true);
    setData(data);
  }; // END handleViewTagDialogOpen

  const handleConfirmationClose = (message) => {
    if (message) {
      dispatch(DeleteTags(data.id));
    } else {
      setConfirmationDialogOpen(false);
      setData(null);
    }
  }; // END handleConfirmationClose

  /* END Handle Change Action */

  return (
    <>
      <Helmet>
        <title> Tags | Rushipeetham </title>
      </Helmet>
      <Container>
        {open ? <TagsDialogBox open={open} data={data} onClose={handleClose} /> : null}
        {viewTagDialogOpen ? (
          <ViewTagsDialog open={viewTagDialogOpen} data={data} onClose={handleViewTagDialogClose} />
        ) : null}
        {confirmationDialogOpen ? (
          <DeleteConfirmationDialog
            message="Tag"
            isOpenConfirm={confirmationDialogOpen}
            handleClose={handleConfirmationClose}
          />
        ) : null}
        <Card>
          <PagesToolbar
            placeHolder={'Search tags...'}
            onFilterName={handleSearchChange}
            filterName={searchValue}
            handleClearClick={handleClearClick}
            buttonName="New Tag"
            handleClickOpen={handleClickOpen}
            statusFilterValue={statusValue}
            statusFilterOptions={statusFilterOptions}
            onStatusFilterChange={handleStatusChange}
          />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table aria-label="simple table" size="small">
                <PagesHead headLabel={TABLE_HEAD} />
                {tags.length !== 0 ? (
                  <TableBody>
                    {tags?.map((row, index) => (
                      <TableRow key={index}>
                        {/* <TableCell>{(page*rowsPerPage)+(index + 1)}</TableCell> */}
                        <TableCell style={{ width: '20%' }}>{row?.tag_name}</TableCell>
                        <TableCell style={{ width: '40%' }}>
                          <Tooltip
                            title={row?.product_tag_reference
                              ?.reduce(
                                (accum, item) =>
                                  `${accum}, ${item?.products?.length > 0 ? item.products[0].product_name : ' '}`,
                                ''
                              )
                              ?.substring(1)
                              .replaceAll(' ,', '')}
                          >
                            <div
                              id={row?.id}
                              style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                width: '300px',
                              }}
                            >
                              {row?.product_tag_reference
                                ?.reduce(
                                  (accum, item) =>
                                    `${accum}, ${item?.products?.length > 0 ? item.products[0].product_name : ' '}`,
                                  ''
                                )
                                ?.substring(1)
                                .replaceAll(' ,', '')}
                              ...
                            </div>
                          </Tooltip>
                        </TableCell>
                        <TableCell style={{ width: '20%' }}>
                          <Label color={(row?.is_active === 0 && 'error') || 'success'}>
                            {sentenceCase(row?.is_active ? 'Active' : 'Inactive')}
                          </Label>
                        </TableCell>
                        <TableCell style={{ width: '20%' }}>
                          <PagesMoreMenu
                            handleEdit={() => handleEdit(row)}
                            handleDelete={() => handleDelete(row)}
                            handleView={() => handleViewTagDialogOpen(row)}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                ) : (
                  <TableRow>
                    <TableCell colSpan={12}>
                      <div className="text-align-center font-18 font-bold py-15" style={{ textAlign: 'center' }}>
                        No data found...
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            component="div"
            count={pageInfo.total ? pageInfo.total : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </>
  ); // END return
} // END ProductTagsPage
