import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { 
	Container, 
	Typography, 
	Card 
} from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Logo from '../components/logo';
import BackgroundImage from '../assets/login-background.png'
// sections
import { LoginForm } from '../sections/auth/login';

// ----------------------------------------------------------------------
const StyledRoot = styled('div')(({ theme }) => ({
	[theme.breakpoints.up('md')]: {
		display: 'flex'
	},
}));

const StyledSection = styled('div')(({ theme }) => ({
	width: '50%',
	maxWidth: 480,
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	boxShadow: theme.customShadows.card,
	backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
	maxWidth: 480,
	margin: 'auto',
	// width: 'auto',
	width: '40%',
	minWidth: '40%',
	marginLeft: '5% !important',
	marginRight : '5% !important',
	minHeight: '72vh',
	display: 'block',
	justifyContent: 'center',
	flexDirection: 'column',
	padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
	const mdUp = useResponsive('up', 'md');

  	return (
		<>
			<Helmet>
				<title> Login | Rushipeetham </title>
			</Helmet>
			<StyledRoot style={{backgroundImage:`url(${BackgroundImage})`}}>
				<Container style={{minHeight:'100vh',minWidth:'100vw',justifyContent:'center',alignItems:'center',display:'flex'}} >
					<Card style={{width:'70%',margin:'auto',display:'flex',background:'rgba(247, 247, 247, 0.8)',border:'1px solid #F3F3F3',backdropFilter:'blur(7.5px)',borderRadius:'15px'}}>
						<Logo
							sx={{
								position: 'absolute',
								top: { xs: 16, sm: 24, md: 100 },
								left: { xs: 16, sm: 24, md: 40 },
							}}
							disabledLink
						/>
						{
							mdUp && (
							<StyledSection style={{backgroundColor:'transparent'}}>
								<Typography variant="h3" sx={{ px: 5, mt: 2 }} color="#1C2434" fontFamily="Inter">
									Login to your account
								</Typography>
								<Typography sx={{ px: 5, mt: 2}} color="#717171" fontFamily="Inter"> 
									Please login to access your admin panel
								</Typography>
								{/* <img src="/assets/illustrations/illustration_login.png" alt="login" /> */}
							</StyledSection>)
						}
						{/* <Container maxWidth="sm" style={{width:'50%',paddingLeft:'0px',paddingRight:'0px'}}> */}
						<StyledContent>
							{/* 
								<Typography variant="h4" gutterBottom>
								Sign in to Rushipeetham
								</Typography>
								<Typography variant="body2" sx={{ mb: 5 }} /> 
							*/}		

							<LoginForm />
							
						</StyledContent>
						{/* </Container> */}
					</Card>				
				</Container>
			</StyledRoot>		
		</>
  	);
}
