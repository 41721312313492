import JWTServer from "../api/withJWTServer";
import { HandleExceptionWithSecureCatch, HandleManualException } from "./CombineCatch";
import { DASHBOARD_HOME } from "./Types";

const GetDashboardData = () => async (dispatch) => {
    try {
      const response = await JWTServer.get(`/api/admin-dashboard`);
      if(response.data.meta.status===200){
        dispatch({
          type: DASHBOARD_HOME,
          payload: response.data,
        });
      }
      else {
        dispatch(HandleManualException(response.data))
      }

     
    } catch (e) {
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };




  export {
     GetDashboardData,
  }