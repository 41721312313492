import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox,FormLabel, InputLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';

import { loginValidationSchema } from '../../../utils/validation/LoginValidation';
import { ShowLoadingButton } from '../../../actions/CommonAction';
import { LoginUser } from '../../../actions/AuthActions';
import ForgotPasswordDialog from '../../../components/DiaglogBox/ForgotPasswordDialog';

// ----------------------------------------------------------------------

export default function LoginForm() {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [showPassword, setShowPassword] = useState(false);
	const [showForgotPasswordDialog, setShowForgotPasswordDialog] = useState(false);

	const { isLogged, showLoadingButton,loggedUser } = useSelector((state) => ({
		isLogged: state.auth.isLogged,
		showLoadingButton: state.common.showLoadingButton,
		loggedUser: state.auth.loggedUser,
	}));

	useEffect(() => {
		dispatch(ShowLoadingButton(false));
	}, []);

	const firstRun = useRef(true);
	useEffect(() => {
		if (firstRun.current) {
		firstRun.current = false;
		return;
		}
		navigate('/dashboard', { replace: true });
	}, [loggedUser]);

	const handleForgotPasswordDialog = () => {
		setShowForgotPasswordDialog(!showForgotPasswordDialog);
	}

	const handleForgotPasswordDialogClose = () => {
		setShowForgotPasswordDialog(false);
	}

	const formik = useFormik({
		initialValues: {
			email: '',
			password: '',
		},
		validationSchema: loginValidationSchema,
		onSubmit: (value) => {
			dispatch(ShowLoadingButton(true));
			dispatch(
				LoginUser({
					username: value.email,
					password: value.password,
				})
			);
		},
	});

	const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

	return (
		<FormikProvider value={formik} >
			<>
				{
					showForgotPasswordDialog?
					<ForgotPasswordDialog
					open = {showForgotPasswordDialog}
					onClose={handleForgotPasswordDialogClose}
					/>:null
				}
				<form onSubmit={formik.handleSubmit}>
					<Stack spacing={3} className="login-form">
						<InputLabel  htmlFor="login-email">
							Email
						</InputLabel>
						<TextField 
							id="login-email"
							name="email" 
							placeholder='Enter your email'
							inputProps={{ maxLength: 50,style: { background:'#FFFFFF',border:'1px solid #D9D9D9',borderRadius:'10px'} }}
							{...getFieldProps('email')}
							error={Boolean(touched.email && errors.email)}
							helperText={touched.email && errors.email}
							style={{marginTop:'2px'}}
							// FormHelperTextProps={{
							//   sx: {
							//     backgroundColor: '#F3F3F3 !important',
							//     marginLeft:'0px !important',
							//     marginRight: '0px !important',
							//     marginTop: '0px !important'
							//   },
							// }}
						/>
						<InputLabel  htmlFor="password">
							Password
						</InputLabel>
						<TextField
							id="password"
							name="password"
							placeholder='Enter your password'
							inputProps={{ maxLength: 20,style: { background:'#FFFFFF',border:'1px solid #D9D9D9',borderRadius:'10px',borderRight:'none'} }}
							type={showPassword ? 'text' : 'password'}
							style={{marginTop:'2px'}}
							InputProps={{
								endAdornment: (
								<InputAdornment position="end">
									<IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
									<Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
									</IconButton>
								</InputAdornment>
								),
								style:{
								background:'#FFFFFF', border:'1px solid #D9D9D9',borderRadius:'10px'
								}
							}}
							{...getFieldProps('password')}
							error={Boolean(touched.password && errors.password)}
							helperText={touched.password && errors.password}
						/>
					</Stack>
					<Stack direction="row" alignItems="center" justifyContent="center" sx={{ my: 2 }}>
						{/* <Checkbox name="remember" label="Remember me" /> */}
						<Link variant="subtitle2" underline="hover" color='#1C2434' style={{cursor:'pointer'}} fontWeight='300' onClick={handleForgotPasswordDialog}>
							Forgot password?
						</Link>
					</Stack>
					<LoadingButton id="btnLogin" loading={showLoadingButton}  fullWidth size="large" type="submit" variant="contained" onClick={formik.handleSubmit} style={{backgroundColor:'#5F4823'}}>
						Login
					</LoadingButton>
				</form>
			</>
		</FormikProvider>
	);
}
