import * as Yup from "yup";


export const subscriptionValidationSchema = Yup.object().shape({
	// email: Yup.string().email('Enter valid email address').required("Email ID is required"),
	subscriptionType : Yup.string().required("Subscription type is required"),
	device_type: Yup.string().required("Device type is required"),
	// email: Yup.array().of(
	// 	Yup.object().shape({
	// 		id: Yup.string().required(),
	// 		email: Yup.string().required(),
	// 	})
    // ).required('Valid email is required.')
});