import { createStore, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';
import reducers from './reducers';

const persistStateToLocalStorage = (prevState) => {
    try{
        const serializedState = JSON.stringify(prevState);
        localStorage.setItem("rushipeetham_state", serializedState);
    } catch(e){
        // console.log(e);
        
    }
}
/* eslint-disable consistent-return */
const fetchPersistedStateFromLocalStorage = () => {
    try {
        const serializedState = localStorage.getItem("rushipeetham_state");
        if(serializedState === null){
            return undefined;
        } 
        return JSON.parse(serializedState);
    } catch(e){
        // console.log(e);
    }
}

const persistedState = fetchPersistedStateFromLocalStorage();

const dataStore = createStore(
    reducers,
    persistedState,
    applyMiddleware(ReduxThunk)
);

dataStore.subscribe(() => persistStateToLocalStorage(dataStore.getState()));

export default dataStore;