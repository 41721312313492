import { CREATE_BANNER, DELETE_BANNER, GET_ALL_BANNER, RESET_STATE, UPDATE_BANNER } from "../actions/Types";
    
    const INIT_STATE = {
      banners:[],
      pageInfo:{},
      addBannerLog:false,
      editBannerLog: false,
      deleteBannerLog : false,
    };
    
    export default function BannersReducer (state = INIT_STATE, action)  {
      const { type, payload } = action;
    
      switch (type) {
        case GET_ALL_BANNER:
          return {
            ...state,
            banners: payload.data.data,
            pageInfo: payload.data
          };
        case UPDATE_BANNER:
          return {
            ...state,
            editBannerLog: !state.editBannerLog,
          };
        case CREATE_BANNER:
          return {
            ...state,
            addBannerLog: !state.addBannerLog,
          };
        case DELETE_BANNER:
          return {
            ...state,
            deleteBannerLog: !state.deleteBannerLog,
          };
        case RESET_STATE:
          return { ...INIT_STATE };
        default:
          return state;
      }
    };
    