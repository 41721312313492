import JWTServer from '../api/withJWTServer';
import { SetNewAlert } from './AlertActions';
import { HandleExceptionWithSecureCatch, HandleManualException } from './CombineCatch';
import { GET_MOST_FEV_FEED, CREATE_FEED, DELETE_FEED, GET_ALL_FEED, GET_FEED_BY_ID, UPDATE_FEED } from './Types';

const GetAllFeeds =
  (page, limit, searchValue = '', filterValue = 0, statusValue) =>
  async (dispatch) => {
    try {
      const url = `/api/get-all-feeds?page=${page}&limit=${limit}&search=${searchValue}&status=1`;
      const response = await JWTServer.get(url);
      if (response.data.meta.status === 200) {
        dispatch({
          type: GET_ALL_FEED,
          payload: response.data,
        });
      } else {
        dispatch(HandleManualException(response.data));
      }
    } catch (e) {
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };

const GetFeedById = (id) => async (dispatch) => {
  try {
    const response = await JWTServer.get(`/api/get-feed-by-id/${id}`);
    // console.log("response from action getFeedbyid",response)
    if (response.data.meta.status === 200) {
      dispatch({
        type: GET_FEED_BY_ID,
        payload: response.data,
      });
    } else {
      dispatch(HandleManualException(response.data));
    }
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};

const AddFeed = (params) => async (dispatch) => {
  try {
    const response = await JWTServer.post('/api/create-feed', params);
    console.log('response of add', response);

    if (response.data.meta.status === 200) {
      dispatch({
        type: CREATE_FEED,
        payload: response.data,
      });
      dispatch(
        SetNewAlert({
          msg: response.data.meta.message,
          alertType: 'success',
        })
      );
    } else {
      dispatch(HandleManualException(response.data));
    }
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};

const EditFeed = (params, feedId) => async (dispatch) => {
  try {
    const response = await JWTServer.put(`/api/update-feed/${feedId}`, params);
    if (response.data.meta.status === 200) {
      dispatch({
        type: UPDATE_FEED,
        payload: response.data,
      });
      dispatch(
        SetNewAlert({
          msg: response.data.meta.message,
          alertType: 'success',
        })
      );
    } else {
      dispatch(HandleManualException(response.data));
    }
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};

const DeleteFeed = (params) => async (dispatch) => {
  try {
    const response = await JWTServer.delete(`/api/delete-feed/${params}`);
    if (response.data.meta.status === 204) {
      dispatch({
        type: DELETE_FEED,
        payload: response.data,
      });
      dispatch(
        SetNewAlert({
          msg: response.data.meta.message,
          alertType: 'success',
        })
      );
    } else {
      dispatch(HandleManualException(response.data));
    }
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};
// ---------get most fev feed-----------
const getMostFevFeed = (page, limit, searchValue) => async (dispatch) => {
  const url = `/api/get-most-like-feeds?page=${page}&limit=${limit}&search=${searchValue}`;
  try {
    const response = await JWTServer.get(url);
    console.log('action res', response);
    if (response.data.meta.status === 200) {
      dispatch({
        type: GET_MOST_FEV_FEED,
        payload: response.data,
      });
      // dispatch(
      //   SetNewAlert({
      //     msg: response.data.meta.message,
      //     alertType: 'success',
      //   })
      // );
    } else {
      dispatch(HandleManualException(response.data));
    }
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};

export { GetAllFeeds, AddFeed, EditFeed, DeleteFeed, GetFeedById, getMostFevFeed };
