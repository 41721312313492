import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
// @mui
import {
    TextField,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    InputLabel,
    Card,
	CardContent,
	Box,	
	Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { SubscriberType } from '../common/constants';
import { GetAllSubscriptionTypes } from '../../actions/SubscriptionAction';
// ----------------------------------------------------------------------
const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                position: 'absolute',
                right: 12,
                top: 8,
                }}
            >
                <CloseIcon />
            </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function ViewSubscriptionDialog(props) {
    const {data} = props;
    const dispatch = useDispatch();
    const [description, setDescription] =  React.useState([]);
    const { addCategoryLog, editCategoryLog, subscriptionTypeList } = useSelector((state) => ({
        addCategoryLog: state.categories.addCategoryLog,
        editCategoryLog: state.categories.editCategoryLog,
        subscriptionTypeList: state.subscriptions.subscriptionTypeList || [],
    }));

    React.useEffect(() => {
		dispatch(GetAllSubscriptionTypes());
        // console.log('subscriptionTypeList', subscriptionTypeList);
        if(subscriptionTypeList.length > 0){
            setDescription(subscriptionTypeList.find((item) => item.susbcription_type === data?.subscription_id));
        }
        // console.log('subscriptionTypeList after ', subscriptionTypeList, description);
	}, [data, dispatch]); // END useEffect

    return (
        <>
            <Dialog
                open={props.open}
                // onClose={props.onClose}
                className="dialog-box-section"
                maxWidth={'sm'}
				fullWidth
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={props.onClose}>
                    Subscription
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12}>
                            <InputLabel sx={{mb:"5px",fontWeight:600}}>
                                Email
                            </InputLabel>
                            <TextField
                                id="outlined-name-input"
                                type="text"
                                fullWidth
                                InputProps={{
                                    readOnly: true,
                                }}
                                value={data?.user?.email}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <InputLabel sx={{mb:"5px",fontWeight:600}}>
                                Subscription Type
                            </InputLabel>
                            <TextField
                                id="outlined-name-input"
                                type="text"
                                fullWidth
                                InputProps={{
                                    readOnly: true,
                                }}
                                value={SubscriberType.filter((item)=>item.value.toString() === data?.subscription_id?.trim().toString())[0]?.label || ''}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <InputLabel sx={{mb:"5px",fontWeight:600}}>
                                Device Type
                            </InputLabel>
                            <TextField
                                id="device_type"
                                name="Device Type"
                                InputProps={{
                                    readOnly: true,
                                }}
                                value={ data.device_type === "Both" ? "All" : data.device_type }
                                fullWidth
                            />
                        </Grid>                       
                        <Grid item xs={12} sm={12} md={12}>
                            <Card variant="outlined">
                                <CardContent>
                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                        {description.subscription_name}<br/>
                                        &#8377; {(Math.round(description.subscription_fee * 100) / 100).toFixed(2)}<br/>
                                        {description.subscription_desc}<br/>
                                        {`${dayjs(data?.subscription_start_date, "YYYY-MM-DD+h:mm").format('DD/MM/YYYY')} to ${dayjs(data?.subscription_end_date, "YYYY-MM-DD+h:mm").format('DD/MM/YYYY')}`}<br/>
                                    </Typography>	
                                </CardContent>		
                            </Card>
                        </Grid> 
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    );// END Return
}// END ViewSubscriptionDialog
