import React, { useEffect, useState } from 'react';
import { Avatar, Stack } from '@mui/material';

export default function ViewImageList(props) {
  const [imageList, setImageList] = useState([]);
  const [showList, setShowList] = useState(true);
  const onCloseIconClick = (data) => {
    props.handleDelete(data);
  };

  // useEffect(() => {
  // 	const getImages = async () => {
  // 		if (props?.imageArray?.length > 0 ) {
  // 			const latestList = [];
  // 			props.imageArray?.map((value, index, array) => {
  // 				const URL = `${process.env.REACT_APP_BASE_URL}/api/get-image-file/${value.id}`;
  // 				// const URL = `${process.env.REACT_APP_BASE_URL}/api/get-admin-image-file/${value.id}`;
  // 				fetch(URL,{
  // 					method: "GET",
  // 					headers: {
  // 						Authorization: `Bearer ${localStorage.getItem('token')}`,
  // 					    Origin: ['http://localhost:3000', 'https://uat.playbyplay.tech'],
  // 					},
  // 					credentials: 'include',
  // 					// crossDomain: true,
  // 					// xhrFields : { withCredentials: true },
  // 					redirect: 'follow',
  // 					// redirect: 'manual',
  // 					// redirect: 'error',
  // 				    // mode: 'no-cors', //'cors'
  // 					// mode: 'cors',
  // 					// credentials: 'same-origin',
  // 					// cache: "default",
  // 				})
  // 				.then((response) => {
  // 					console.log('# response', response, response.url);
  // 					return response.blob();
  // 				})
  // 				.then((blob) => {
  // 					console.log('# blob', blob);
  // 					const _url = window.URL.createObjectURL(blob);
  // 					latestList.push({ file_path: _url });
  // 					console.log('# URL', _url);
  // 					if (index === array.length - 1) {
  // 						let arr = [...imageList];
  // 						arr = latestList;
  // 						setImageList(arr);
  // 						setShowList(true);
  // 					}
  // 				})
  // 				.catch((err) => {
  // 					console.log("# err",err);
  // 				});
  // 				return null;
  // 			});
  // 			setShowList(true);
  // 		}
  // 	};
  // 	getImages();
  // }, [props.imageArray]);

  /* Set Placeholder Image On Fetch Image Data Fail */
  // if(imageList.length === 0){
  //     setImageList([{ "file_path": '/assets/placeholders/placeholder.png'}]);
  //     setShowList(true);
  // }
  // setShowList(true);

  return (
    <>
      {showList ? (
        <Stack item xs={12} className="image-swiper-container" direction="row" spacing={1}>
          {
            // For No Authorized Image
            props.imageArray?.map((value, index) => (
              <Avatar
                key={index + 1}
                id={value.id}
                src={`${process.env.REACT_APP_BASE_URL}/api/get-admin-image-file/${value.id}`}
                alt="Travis Howard"
                variant="rounded"
                sx={{ width: 140, height: 140 }}
                // not work below code with src or source
                // source ={{
                // 	uri: `${process.env.REACT_APP_BASE_URL}/api/get-admin-image-file/${value.id}`
                // 	// uri: `${process.env.REACT_APP_BASE_URL}/api/get-image-file/${value.id}`,
                // 	// headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                // }}
              />
            ))
          }
          {/*
						// For Authorized with token 
						imageList.map((value, index) => (
							<Avatar
								key={value.file_path}
								id={value.file_path}
								alt="Travis Howard"
								variant="rounded"
								src={value.file_path}
								sx={{ width: 140, height: 140 }}
							/>
						))
					*/}
        </Stack>
      ) : (
        <></>
      )}
    </>
  ); // END return
} // END ViewImageList
