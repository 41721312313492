import PropTypes from 'prop-types';
import { useLocation , Link as RouterLink} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { capitalCase } from 'change-case';
// @mui
import { styled , alpha} from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, Typography, Breadcrumbs, Link } from '@mui/material';
// utils
import { bgBlur } from '../../../utils/cssStyles';
// components
import Iconify from '../../../components/iconify';

import useResponsive from '../../../hooks/useResponsive';
//
import AccountPopover from './AccountPopover';
import NotificationsPopover from './NotificationsPopover';


// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 92;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.15)',
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  alignContent: 'center',
  justifyContent: 'center'
}));

// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav }) {
  const { pathname } = useLocation();
  const isDesktop = useResponsive('up', 'lg');
  const { loggedUser } = useSelector((state) => ({
  loggedUser: state.auth.loggedUser,
  }));
  return (
    <StyledRoot>
      <StyledToolbar>
        {isDesktop ?
        
      <Breadcrumbs aria-label="breadcrumb">
            <Link underline="none" color ="#8D8D8D" to="/dashboard/home" component={RouterLink} fontWeight="400">
              Dashboard
            </Link>
            <Typography color="primary.main" fontWeight="500">{
              pathname === '/dashboard/bundles/bundles-details'.toString() ? 
              <>
                <Link underline="none" color ="#8D8D8D" to="/dashboard/bundles" component={RouterLink} fontWeight="400">
                  Bundles
                </Link> / Bundles-details
              </>
              : capitalCase(pathname.substring(pathname.lastIndexOf('/') + 1))
            }</Typography>
        </Breadcrumbs> :null
        }
        <IconButton
          onClick={onOpenNav}
          sx={{
            mr: 1,
            color: 'text.primary',
            display: { lg: 'none' },
          }}
        >
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>

        {/* <Searchbar /> */}
        <Box sx={{ flexGrow: 1 }} />


        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1,
          }}
        >
          {/* <LanguagePopover /> */}
        
          <StyledAccount>

            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.themeSecondary' }}>
            {loggedUser?.first_name} {loggedUser?.last_name}
              </Typography>

              {/* <Typography variant="body2" sx={{ color: 'text.themeSecondary' }}>
              {loggedUser?.roles?.role} 
              </Typography> */}
            </Box>
          </StyledAccount>
          <AccountPopover />
        </Stack>
      </StyledToolbar>
    </StyledRoot>
  );
}
