import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { styled } from '@mui/material/styles';

// @mui
import {
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Paper,
    Grid,
    Button,
    MenuItem,
    IconButton,
    InputLabel,
} from '@mui/material';


import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { categoriesValidationSchema } from '../../utils/validation/CategoriesValidation';
import { AddCategory, EditCategory } from '../../actions/CategoriesAction';


// import { makeStyles } from '@material-ui/core/styles';
// import './common/category.css';


// ----------------------------------------------------------------------
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2}} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 12,
                        top: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};
const DisplayStatusArray = [
    {
      id: 1,
      value: 1,
      label: 'Yes'
    },
    {
      id: 2,
      value: 0,
      label: 'No'
    }
  ]

export default function ViewCategoryDialog(props) {
    const dispatch = useDispatch();
    const {data} = props;
    const intialState = {
        pictures: [],
        maxFileSize: 5242880,
        imgExtension: [".jpg", ".png",".svg"],
        defaultImages: []
    }
    const { addCategoryLog, editCategoryLog } = useSelector((state) => ({
        addCategoryLog: state.categories.addCategoryLog,
        editCategoryLog: state.categories.editCategoryLog,
      }));

    const firstRun = React.useRef(true);
    React.useEffect(()=>{
      if (firstRun.current) {
        firstRun.current = false;
        return;
      }
      props.onClose()
    },[addCategoryLog,editCategoryLog])


    

    return (
        <>

            <Dialog
                open={props.open}
                // onClose={props.onClose}
                className="dialog-box-section"
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={props.onClose}>
                    Category
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12}>
                        <InputLabel sx={{mb:"5px",fontWeight:600}}>
                                Category Name
                            </InputLabel>
                            <TextField
                                id="outlined-name-input"
                                type="text"
                                fullWidth
                                InputProps={{
                                    readOnly: true,
                                }}
                                value={data.category_name}
                            />
                        </Grid>
                        <Grid item xs={12}>
                        <InputLabel sx={{mb:"5px",fontWeight:600}}>
                                Special Note
                            </InputLabel>
                            <TextField
                                multiline
                                rows={2}
                                id="outlined-name-input"
                                type="text"
                                fullWidth
                                InputProps={{
                                    readOnly: true,
                                }}
                                value={data.special_note}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <InputLabel sx={{mb:"5px",fontWeight:600}}>
                                Sequence
                            </InputLabel>
                            <TextField
                                id="outlined-name-input"
                                type="number"
                                fullWidth
                                InputProps={{
                                    readOnly: true,
                                }}
                                value={data.sequence}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <InputLabel sx={{mb:"5px",fontWeight:600}}>
                                Is Display on Homepage ? 
                            </InputLabel>
                            <TextField
                                id="DisplayStatus"
                                fullWidth
                                InputProps={{
                                    readOnly: true,
                                }}
                                value={data?.is_display_on_homepage===1?"Yes":"No"}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                        <InputLabel sx={{mb:"5px",fontWeight:600}}>
                                Status
                            </InputLabel>
                            <TextField
                                id="ActiveStatus"
                                name="status"
                                InputProps={{
                                    readOnly: true,
                                }}
                                fullWidth
                                value={data?.is_active===1?"Active":"Inactive"}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>

        </>
    );
}
