export const BannerLinkTypeArr = [
	{
		id: 1,
		value: 'No_Link',
		label: 'No Link',
	},
	{
		id: 2,
		value: 'External',
		label: 'External',
	},
	{
		id: 3,
		value: 'Internal',
		label: 'Internal',
	},
];// END BannerLinkTypeArr


export const DisplayStatusArray = [
	{
        id: 1,
        value: 1,
        label: 'Yes'
	},
	{
        id: 2,
        value: 0,
        label: 'No'
	}
]// END DisplayStatusArray

//	Option for notification dropdown array
export const NotificationDeviceTypeArr = [
    // {
    //     id: 1,
    //     value: 'Both',
    //     label: 'All',
    // },
    {
        id: 2,
        value: 'Android',
        label: 'Android',
    },
    {
        id: 3,
        value: 'Ios',
        label: 'IOS',
    },
];// END NotificationDeviceTypeArr

export const NotificationLinkTypeArr = [
    {
        id: 1,
        value: 'No_Link',
        label: 'No Link',
    },
    {
        id: 2,
        value: 'External',
        label: 'External',
    },
    {
        id: 3,
        value: 'Internal',
        label: 'Internal',
    },
];// END NotificationLinkTypeArr

export const MediaType = [
	{
		value:"PDF",
		label:"PDF"
	},
	{
		value:"VIDEO",
		label:"VIDEO"
	},
	{
		value:"MPG",
		label:"MP3"
	}
];// END MediaType For Product

export const SubscriptionType = [
	{
        id: 1,
		value:"free",
		label:"Free"
	},
	{
        id: 2,
		value:"freemium",
		label:"Freemium"
	},
	{
        id: 3,
		value:"premium",
		label:"Premium"
	}
];// END SubscriptionType For Product

export const BundleNavigationType = [
	{
        id: 1,
		value:"add",
		label:"Add_Bundle"
	},
	{
        id: 2,
		value:"edit",
		label:"Edit_Bundle"
	},
	{
        id: 3,
		value:"view",
		label:"View_Bundle"
	},
];// END SubscriptionType For Product

export const Months = [
	{
        id: 1,
		value:"01",
		label:"Jan"
	},
	{
        id: 2,
		value:"02",
		label:"Feb"
	},
	{
        id: 3,
		value:"03",
		label:"Mar"
	},
	{
        id: 4,
		value:"04",
		label:"Apr"
	},
	{
        id: 5,
		value:"05",
		label:"May"
	},
	{
        id: 6,
		value:"06",
		label:"Jun"
	},
	{
        id: 7,
		value:"07",
		label:"Jul"
	},
	{
        id: 8,
		value:"08",
		label:"Aug"
	},
	{
        id: 9,
		value:"09",
		label:"Sep"
	},
	{
        id: 10,
		value:"10",
		label:"Oct"
	},
	{
        id: 11,
		value:"11",
		label:"Nov"
	},
	{
        id: 12,
		value:"12",
		label:"Dec"
	},
];// END Months

// SubscriberType For Subscription Module
export const SubscriberType = [
	{
        id: 1,
		value:"101",
		label:"1 Month"
	},
	{
        id: 2,
		value:"102",
		label:"1 Year"
	},
];// END SubscriberType 

//	Device type array
export const DeviceTypeArr = [
    // {
    //     id: 1,
    //     value: 'Both',
    //     label: 'All',
    // },
    {
        id: 2,
        value: 'Android',
        label: 'Android',
    },
    {
        id: 3,
        value: 'Ios',
        label: 'IOS',
    },
];// END DeviceTypeArr

//	Device type array
export const PaymentModeFilterArr = [
    {
        id: 1,
        value: 'All',
        label: 'All Records',
    },
    {
        id: 2,
        value: 'Online',
        label: 'Online Records',
    },
    {
        id: 3,
        value: 'Cash',
        label: 'Cash Records',
    },
];// END PaymentModeFilterArr

export const ApiRecordLimit  = 2000;
export const ApiRecordPageNo = 1;
export const MaxDateValue = '2050-12-01';
export const MinDateValue = '2000-12-01';
export const AdminRoles = ['Admin', 'SubAdmin'];