import * as Yup from "yup";


export const notificationValidationSchema = Yup.object().shape({
        title: Yup.string()
                .trim()
                .min(3, "Minimum 3 characters required")
                .max(256, "Maximum 256 characters required")
                .required("Notification title is required."),
        message: Yup.string()
                .trim()
                .min(3, "Minimum 3 characters required")
                .max(1024, "Maximum 1024 characters required")
                .required("Notification message is required."),
        device_type: Yup.string()
                .required("Notification device type is required"),
        link_type: Yup.string()
                .required("Notification link type is required"),
        // product_key: Yup.string()
        //        .required("Notification target product key is required"),
        // web_url: Yup.string()
        //         .required("Notification web url is required"),
        // category: Yup.string()
        //         .required("Notification target category is required"),
        // product : Yup.string()
        //         .required("Notification target product is required"),
        image: Yup.string()
                .required("Notification image is required"),
  });