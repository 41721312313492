import {
  CREATE_CATEGORY,
  DELETE_CATEGORY,
  GET_ALL_CATEGORY,
    GET_CATEGORY_BY_ID,
    GET_CATEGORY_FOR_DROPDOWN,
    RESET_STATE,
    UPDATE_CATEGORY,
  } from "../actions/Types";
  
  const INIT_STATE = {
    categories:[],
    categoriesForDropdown:[],
    categoryById:{},
    pageInfo:{},
    addCategoryLog:false,
    editCategoryLog: false,
    deleteCategoryLog : false,
  };
  
  export default function CategoriesReducer (state = INIT_STATE, action)  {
    const { type, payload } = action;  
    switch (type) {
      case GET_ALL_CATEGORY:
        return {
          ...state,
          categories: payload.data.data,
          pageInfo: payload.data
        };
      case UPDATE_CATEGORY:
        return {
          ...state,
          editCategoryLog: !state.editCategoryLog,
        };
      case CREATE_CATEGORY:
        return {
          ...state,
          addCategoryLog: !state.addCategoryLog,
        };
      case DELETE_CATEGORY:
        return {
          ...state,
          deleteCategoryLog: !state.deleteCategoryLog,
        };
      case GET_CATEGORY_FOR_DROPDOWN:
      return {
        ...state,
        categoriesForDropdown: payload.data,
      };
      case GET_CATEGORY_BY_ID:
      return {
        ...state,
        categoryById: payload.data,
      };
      case RESET_STATE:
        return { ...INIT_STATE };
      default:
        return state;
    }
  };
  