import { CREATE_BUNDLE, DELETE_BUNDLE, GET_ALL_BUNDLE, GET_BUNDLE_BY_ID, RESET_STATE, UPDATE_BUNDLE, FETCH_BUNDLE_SEQUENCE } from "../actions/Types";
		
const INIT_STATE = {
	bundles:[],
	pageInfo:{},
	addBundleLog:false,
	editBundleLog: false,
	deleteBundleLog : false,
	bundleById:{},
	fetchBundleSequence:[]
};

export default function BundlesReducer (state = INIT_STATE, action)  {
	const { type, payload } = action;
	switch (type) {
		case GET_ALL_BUNDLE:
			return {
				...state,
				bundles: payload.data.data,
				pageInfo: payload.data
			};
		case UPDATE_BUNDLE:
			return {
				...state,
				editBundleLog: !state.editBundleLog,
			};
		case CREATE_BUNDLE:
			return {
				...state,
				addBundleLog: !state.addBundleLog,
			};
		case DELETE_BUNDLE:
			return {
				...state,
				deleteBundleLog: !state.deleteBundleLog,
			};
		case GET_BUNDLE_BY_ID:
			return {
				...state,
				bundleById: payload.data,
			};
		case FETCH_BUNDLE_SEQUENCE:
			return {
				...state,
				fetchBundleSequence: payload.data,
			};
		case RESET_STATE:
			return { ...INIT_STATE };
		default:
			return state;
	}
};
