import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
// @mui
import { Box, List, ListItemText } from '@mui/material';
//
import { StyledNavItem, StyledNavItemIcon } from './styles';

// ----------------------------------------------------------------------

NavSection.propTypes = {
	data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {
	
	const { loggedUser } = useSelector((state) => ({
		loggedUser: state.auth.loggedUser
	}));

	return (
		<Box {...other}>
			<List disablePadding sx={{ pt: 1, pb: 1, pl: 0, pr: 0 }}>
				{
					data.map((item) => {
						if(item.rolePermission.includes(loggedUser?.roles?.role)) return <NavItem key={item.title} item={item} />;
						return null;
					})
				}
			</List>
		</Box>
	);
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
	item: PropTypes.object,
};

function NavItem({ item }) {
	const { title, path, icon, info } = item;

	return (
		<StyledNavItem
			component={RouterLink}
			to={path}
			sx={{
				'&.active': {
					color: '#F7A728 !important',
					bgcolor: 'background.navActiveColor',
					fontWeight: 'fontWeightBold',
					borderLeft: '8px solid #F7A728',
					borderRadius: '0px',
				},
				borderLeft: '8px solid transparent',
			}}
			style={{ color: '#FFFFFF', fontWeight: 'bold' }}
		>
			<StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

			<ListItemText disableTypography primary={title} />

			{info && info}
		</StyledNavItem>
	);
}
