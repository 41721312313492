import * as Yup from "yup";


export const productValidationSchema = Yup.object().shape({
	productName: Yup.string()
		.trim()
		.min(3, "Minimum 3 characters required")
		.max(50, "Too Long!")
		// .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed")
		.required("Product name is required."),
	shortDescription: Yup.string()
		.trim()
		.min(3, "Minimum 3 characters required")
		.max(100, "Maximum 100 characters required")
		.required("Short description is required."),
	longDescription: Yup.string()
		.trim()
		.min(3, "Minimum 3 characters required")
		.max(200, "Maximum 200 characters required")
		.required("Long description is required."),
	price: Yup.number().positive().min(0).required("Price is required"),
	rating: Yup.number().positive().min(1).max(5).required("Rating is required"),
	category: Yup.string().required("Category is required"),
	image: Yup.string().required("Product image is required"),
	sequence: Yup.number().positive().required("Sequence is required"),
	displayOnHomePage: Yup.string().required("Banner should be display on Home page is required."),
	isNewProduct: Yup.string().required("Is new product field is required"),
	isActive : Yup.string().required("Status field is required"),
	productMediaType: Yup.string().required("Media type is required"),
	// noOfAllowedPages: Yup.number().positive().required("No. of allowed pages is required"),
	// productMediaUrl: Yup.string().required("Product media required"),
	productMediaUrl: Yup.string().required("Product PDF required"),
	// videoTitle: Yup.string().required("Video title required"),
	// videoId: Yup.string().required("Video title required"),
	// productTags: Yup.string().required('required-field'),
	// productTags: Yup.array().of(Yup.object().shape({id: Yup.string().required('tag id required'),tag_name: Yup.string().required('tag_name require')})),
	
	// releaseDateOfMagazine: Yup.date().required("Release date of magazine is required"),
	// emagazineYear: Yup.string().required("E-Magazine year is required"),
	// emagazineMonth: Yup.string().required("E-Magazine month is required"),
});