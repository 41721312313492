import {
	GET_FILE_BY_FOLDER_NAME,
	RESET_STATE,
	UPLOAD_BANNER_IMAGE,
	UPLOAD_PRODUCT_FILES,
	UPLOAD_PRODUCT_IMAGE,
	UPLOAD_NOTIFICATION_IMAGE,
	UPLOAD_BUNDLE_IMAGE
} from '../actions/Types';

const INIT_STATE = {
	bannerImage: {},
	uploadBannerImageLog: false,

	productImage: {},
	uploadProductImageLog: false,

	productFile: {},
	uploadProductFileLog: false,

	notificationImage: {},
	uploadNotificationImageLog: false,
	
	bundleImage: {},
	uploadBundleImageLog: false,

	fileByFolder: {
		mp3_files: [],
		pdf_files:[]
	},
};

export default function UploadReducer(state = INIT_STATE, action) {
	
	const { type, payload } = action;

	switch (type) {
		
		case UPLOAD_BANNER_IMAGE:
			return {
				...state,
				bannerImage: payload.data,
				uploadBannerImageLog: !state.uploadBannerImageLog,
			};

		case UPLOAD_PRODUCT_IMAGE:
			return {
				...state,
				productImage: payload.data,
				uploadProductImageLog: !state.uploadProductImageLog,
			};
		
		case UPLOAD_NOTIFICATION_IMAGE:
			return {
				...state,
				notificationImage: payload.data,
				uploadNotificationImageLog: !state.uploadNotificationImageLog,
			};
		
		case UPLOAD_BUNDLE_IMAGE:
			console.log('UPLOAD_BUNDLE_IMAGE reducer payload.data', payload.data);
			return {
				...state,
				bundleImage: payload.data,
				uploadBundleImageLog: !state.uploadBundleImageLog,
			};

		case UPLOAD_PRODUCT_FILES:
			return {
				...state,
				productFile: payload.data,
				uploadProductFileLog: !state.uploadProductFileLog,
			};

		case GET_FILE_BY_FOLDER_NAME:
			return {
				...state,
				fileByFolder: payload.data,
			};
		case RESET_STATE:
			return { ...INIT_STATE };

		default:
			return state;

	}
}
