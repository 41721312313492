import JWTServer from "../api/withJWTServer";
import { SetNewAlert } from "./AlertActions";
import { HandleExceptionWithSecureCatch, HandleManualException } from "./CombineCatch";
import { 
	CREATE_SUBSCRIPTION, 
	DELETE_SUBSCRIPTION, 
	GET_ALL_SUBSCRIPTION, 
	GET_SUBSCRIPTION_BY_ID, 
	UPDATE_SUBSCRIPTION, 
	GET_ALL_SUBSCRIPTION_TYPE,
	CANCEL_SUBSCRIPTION,
} from "./Types";
import { PaymentModeFilterArr } from '../components/common/constants';

const GetAllSubscriptions = (page,limit,searchValue,filterType = PaymentModeFilterArr[0].value) => async (dispatch) => {
	try {
		let url = `/api/get-all-user-subscriptions?page=${page}&limit=${limit}&search=${searchValue}&status=1`;
		if(filterType !== PaymentModeFilterArr[0].value) url+=`&filter_payment_mode=${filterType}`;
		const response = await JWTServer.get(url);
		if(response.data.meta.status===200){
			dispatch({
				type: GET_ALL_SUBSCRIPTION,
				payload: response.data,
			});
		}
		else {
			dispatch(HandleManualException(response.data))
		}
	} catch (e) {
		dispatch(HandleExceptionWithSecureCatch(e));
	}
};// END GetAllSubscriptions

const GetAllSubscriptionTypes = () => async (dispatch) => {
	try {
		const response = await JWTServer.get(`/api/get-all-subscription-types`);
		if(response.data.meta.status===200){
			dispatch({
				type: GET_ALL_SUBSCRIPTION_TYPE,
				payload: response.data,
			});
		}
		else {
			dispatch(HandleManualException(response.data))
		}
	} catch (e) {
		dispatch(HandleExceptionWithSecureCatch(e));
	}
};// END GetAllSubscriptionTypes

const AddSubscription = (params) => async (dispatch) => {
	try {
		const response = await JWTServer.post("/api/create-cash-subscription",params);
		if(response.data.meta.status===200){
			dispatch({
				type: CREATE_SUBSCRIPTION,
				payload: response.data,
			});
			dispatch(SetNewAlert({
				msg: response.data.meta.message,
				alertType: "success",
			}));
		}
		else {
			dispatch(HandleManualException(response.data))
		}
		
	} catch (e) {
		dispatch(HandleExceptionWithSecureCatch(e));
	}
};// END AddSubscription

const EditSubscription = (params,categoryId) => async (dispatch) => {
	try {
		const response = await JWTServer.put(`/api/update-cash-subscription/${categoryId}`,params);
		if(response.data.meta.status===200){
			dispatch({
				type: UPDATE_SUBSCRIPTION,
				payload: response.data,
			});
			dispatch(SetNewAlert({
				msg: response.data.meta.message,
				alertType: "success",
			}));
		}
		else {
			dispatch(HandleManualException(response.data))
		}
		
	} catch (e) {
		dispatch(HandleExceptionWithSecureCatch(e));
	}
};// END EditSubscription

const DeleteSubscription = (params,status) => async (dispatch) => {
	try {
		const response = await JWTServer.delete(`/api/delete-cash-subscription/${params}`);
		if(response.data.meta.status===204){
			dispatch({
				type: DELETE_SUBSCRIPTION,
				payload: response.data,
			});
			dispatch(SetNewAlert({
				msg: response.data.meta.message,
				alertType: "success",
			}));
		}
		else {
			dispatch(HandleManualException(response.data))
		}
		
	} catch (e) {
		dispatch(HandleExceptionWithSecureCatch(e));
	}
};// END DeleteSubscription

const CancelSubscription = (params,status) => async (dispatch) => {
	try {
		const response = await JWTServer.get(`/api/cancel-cash-subscription-by-id/${params}`);
		if(response.data.meta.status===200){
			dispatch({
				type: CANCEL_SUBSCRIPTION,
				payload: response.data,
			});
			dispatch(SetNewAlert({
				msg: response.data.meta.message,
				alertType: "success",
			}));
		}
		else {
			dispatch(HandleManualException(response.data))
		}
		
	} catch (e) {
		dispatch(HandleExceptionWithSecureCatch(e));
	}
};// END CancelSubscription

const GetSubscriptionById = (id) => async (dispatch) => {
	try {
		const response = await JWTServer.get(`/api/get-cash-subscription-by-id/${id}`);  
		if(response.data.meta.status===200){
			dispatch({
				type: GET_SUBSCRIPTION_BY_ID,
				payload: response.data,
			});
		}
		else {
			dispatch(HandleManualException(response.data))
		}		
	} catch (e) {
		dispatch(HandleExceptionWithSecureCatch(e));
	}
};// END GetSubscriptionById

export {
	GetAllSubscriptions,
	AddSubscription,
	EditSubscription,
	DeleteSubscription,
	GetSubscriptionById,
	GetAllSubscriptionTypes,
	CancelSubscription
}