import * as Yup from "yup";


export const changePasswordValidationSchema = Yup.object().shape({
    password: Yup.string()
    .matches(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/, 'Password should have at least eight characters, at least one letter, one number')
    .required("Password is required"),
    confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required("Confirm password is required"),
    oldPassword: Yup.string()
    .matches(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/, 'Old password should have at least eight characters, at least one letter, one number')
    .required("Old password is required"),

  });