import {
    DELETE_IMAGE, 
    DELETE_FILES
} from '../actions/Types';

const INIT_STATE = {
	deleteFile: {},
	deleteFileLog: false
};

export default function DeleteReducer(state = INIT_STATE, action) {
	const { type, payload } = action;
	switch (type) {
		
		case DELETE_FILES:
			return {
				...state,
				deleteFile: payload,
				deleteFileLog: !state.deleteFileLog,
			};

		default:
			return state;

	}
}
