import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sentenceCase } from 'change-case';
import { Helmet } from 'react-helmet-async';
// @mui
import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Container,
  IconButton,
  TableContainer,
  TablePagination,
  Tooltip,
  Box,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
// components
import Label from '../components/label';
import Scrollbar from '../components/scrollbar';
// sections
import { PagesHead, PagesToolbar, PagesMoreMenu } from '../sections/@dashboard/common';
import DeleteConfirmationDialog from '../components/DiaglogBox/DeleteConfirmationDialog';
import { DeleteFeed, GetAllFeeds, GetFeedById, getMostFevFeed } from '../actions/FeedAction';
import { GetCategoryForDropdown } from '../actions/CategoriesAction';
import FeddDialogBox from '../components/DiaglogBox/FeedDialogBox';
import ViewFeedDialog from '../components/DiaglogBox/ViewFeedDialog';

// ----------------------------------------------------------------------

const TABLE_HEAD2 = [
  { id: 'srno', label: 'Sr No.', alignRight: false },
  // { id: 'sequence', label: 'Sequence', alignRight: false },
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'Description', label: 'Description', alignRight: false },
  { id: 'countRs', label: 'Views', alignRight: false },
  { id: 'action', label: 'Action' },
];
export default function MostFevFeed(props) {
  /* START Config Data For Page */

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = React.useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [data, setData] = React.useState(null);
  const [fevView, setFevView] = useState(false);
  console.log('data', data);
  const [searchValue, setSearchValue] = React.useState('');
  const [viewFeedDialogOpen, setViewFeedDialogOpen] = React.useState(false);
  const filterCatArr = [{ id: 0, value: 0, label: 'All Records' }];
  const statusFilterOptions = [
    { id: 1, status: 'All', value: 9 },
    { id: 2, status: 'Active', value: 1 },
    { id: 3, status: 'Inactive', value: 0 },
  ];
  const [filteredValue, setFilteredValue] = React.useState(filterCatArr[0].value);
  const [statusValue, setStatusValue] = React.useState(statusFilterOptions[0].value);

  const { feedById, mostFevFeed, feeds, pageInfo, addFeedLog, editFeedLog, deleteFeedLog } = useSelector((state) => ({
    feeds: state?.feeds?.feeds,
    pageInfo: state?.feeds.pageInfo,
    addFeedLog: state?.feeds.addFeedLog,
    editFeedLog: state?.feeds.editFeedLog,
    deleteFeedLog: state?.feeds.deleteFeedLog,
    mostFevFeed: state?.feeds?.mostFevFeed,
    feedById: state.feeds.feedById,
  }));
  console.log('mostFevFeed', mostFevFeed);
  console.log('pageInfo', pageInfo);

  // const [mostFevFeedData, setMostFevFeedData] = useState(mostFevFeed);
  // console.log('mostFevFeedData', mostFevFeedData);

  useEffect(() => {
    dispatch(getMostFevFeed(1, rowsPerPage, ''));
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(getMostFevFeed(newPage + 1, rowsPerPage, searchValue, filteredValue, statusValue));
  }; // END handleChangePage

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
    dispatch(getMostFevFeed(1, parseInt(event.target.value, 10), searchValue, filteredValue, statusValue));
  }; // END handleChangeRowsPerPage

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  }; // END handleSearchChange

  const handleClearClick = () => {
    dispatch(getMostFevFeed(1, rowsPerPage, ''));
    setPage(0);
    setSearchValue('');
  }; // END handleClearClick

  // Open dialog click
  const handleClickOpen = (e) => {
    setData(null);
    setOpen(true);
  }; // END handleClickOpen

  const handleClose = () => {
    setOpen(false);
    setData();
  }; // END handleClose

  const handleViewFeedDialogClose = () => {
    setViewFeedDialogOpen(false);
    setData(null);
  }; // END handleViewFeedDialogClose

  const handleViewFeedDialogOpen = (data) => {
    console.log('dadsgcusg', data);
    setViewFeedDialogOpen(true);
    setData(data);
  }; // END handleViewFeedDialogOpen

  const handleImageModalClose = () => {
    // setImageDialogOpen(false);
  }; // END handleImageModalClose

  const handleShowAudioLoader = (data) => {
    // setShowAudioLoader(data);
  }; // END handleShowAudioLoader

  const handleFilterChange = (data) => {
    setFilteredValue(data);
  }; // END handleFilterChange

  const handleStatusChange = (data) => {
    setStatusValue(data);
  }; // END handleFilterChange

  /* END Handle Change Action */

  return (
    <>
      <Helmet>
        <title> Latest Feeds | Rushipeetham </title>
      </Helmet>
      <Container>
        {viewFeedDialogOpen ? (
          <ViewFeedDialog
            open={viewFeedDialogOpen}
            data={data}
            feedId={data.feed_id}
            onClose={handleViewFeedDialogClose}
            handleLoader={(data) => handleShowAudioLoader(data)}
          />
        ) : null}

        <Card>
          {/* <PagesToolbar
            placeHolder={'Search Most Fev feeds...'}
            onFilterName={handleSearchChange}
            filterName={searchValue}
            handleClearClick={handleClearClick}
            buttonName=""
            handleClickOpen={handleClickOpen}
            filterValue={filteredValue}
            onFilterChange={handleFilterChange}
            filterTitle={'Filter by category'}
            arrayOfFilterOptions={filterCatArr}
            statusFilterValue={statusValue}
            statusFilterOptions={statusFilterOptions}
            onStatusFilterChange={handleStatusChange}
          /> */}

          <Box>
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table aria-label="simple table" size="small">
                  <PagesHead headLabel={TABLE_HEAD2} />
                  {mostFevFeed.data?.length !== 0 ? (
                    <TableBody>
                      {mostFevFeed.data?.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell>{index + 1}</TableCell>
                          {/* <TableCell>{row?.sequence}</TableCell> */}
                          <TableCell>{row?.feed_title}</TableCell>

                          <TableCell>
                            <Tooltip title={row?.feed_description}>
                              <div
                                id={index + 1}
                                style={{
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  width: '300px',
                                }}
                              >
                                {row?.feed_description}...
                              </div>
                            </Tooltip>
                          </TableCell>

                          <TableCell>{row.countRs}</TableCell>
                          <TableCell align="center">
                            <PagesMoreMenu
                              handleView={() => {
                                handleViewFeedDialogOpen(row);
                                // props.setView();
                                // navigate(-1);
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  ) : (
                    <TableRow>
                      <TableCell colSpan={12}>
                        <div className="text-align-center font-18 font-bold py-15" style={{ textAlign: 'center' }}>
                          No data found...
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>
            <TablePagination
              rowsPerPageOptions={[10, 20, 30]}
              component="div"
              count={pageInfo.total ? pageInfo.total : 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        </Card>
      </Container>
    </>
  );
}
