import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import { sentenceCase } from 'change-case';
import { Helmet } from 'react-helmet-async';
// @mui
import {
	Card,
	Table,
	Stack,
	Paper,
	Button,
	TableRow,
	TableBody,
	TableCell,
	Container,
	Typography,
	IconButton,
	TableContainer,
	TablePagination,
} from '@mui/material';
import { Visibility } from '@mui/icons-material';
import BlockIcon from '@mui/icons-material/Block';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { PagesHead, PagesToolbar, PagesMoreMenu } from '../sections/@dashboard/common';
import { GetAllUsers } from '../actions/UserAction';
import UserBlockConfiramtion from '../components/DiaglogBox/UserBlockConfirmation';
import { UserListToolbar } from '../sections/@dashboard/theme-sample/user';
// ----------------------------------------------------------------------
const TABLE_HEAD = [
	{ id: 'srno', label: 'Sr No.', alignRight: false },
	// { id: 'username', label: 'Username', alignRight: false },
	{ id: 'fullName', label: 'Username', alignRight: false },
	{ id: 'email', label: 'Email ID', alignRight: false },
	{ id: 'status', label: 'Status', alignRight: false }
];

export default function UsersPage() {
	
	/* START Config Data For Page */

	const dispatch = useDispatch();
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
	const [data, setData] = React.useState(null);
	const [searchValue, setSearchValue] = React.useState("");
	const [status, setStatus] = React.useState(0);
	const { users, pageInfo,userChangeLog } = useSelector((state) => ({
		users: state.users.users.data,
		pageInfo: state.users.pageInfo,
		userChangeLog: state.users.userChangeLog,
	}));// END useSelector
	/* END Config Data For Page */

	/* START Use Effects */

	useEffect(() => {
		setConfirmationDialogOpen(false);
		dispatch(GetAllUsers(page+1,rowsPerPage,searchValue));
	}, [userChangeLog]); // END useEffect

	useEffect(()=>{
		const delayDebounce = setTimeout(()=>{
		dispatch(GetAllUsers(1,rowsPerPage,searchValue));
			setPage(0);
		},1000)
		return ()=> clearTimeout(delayDebounce)
	},[searchValue]); // END useEffect

	/* END Use Effect */

	/* START Handle Change Action */

	// pagination
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
		dispatch(GetAllUsers(newPage+1,rowsPerPage,searchValue));
	};// END handleChangePage

	const handleChangeRowsPerPage = (event) => {
		setPage(0);
		setRowsPerPage(parseInt(event.target.value, 10));
		dispatch(GetAllUsers(1,parseInt(event.target.value, 10),searchValue));
	};// END handleChangeRowsPerPage

	const handleSearchChange = (event) => {
		setSearchValue(event.target.value)
	};// END handleSearchChange

	const handleClearClick = () => {
		dispatch(GetAllUsers(1,rowsPerPage,""));
		setPage(0);
		setSearchValue("");
	};// END handleClearClick

	// Open dialog click
	const handleClickOpen = (e) => {
		setData(null);
	};// END handleClickOpen

	const handleEdit = (data) => {
		setData(data);
	};// END handleEdit

	const handleDelete = (data,status) => {
		setConfirmationDialogOpen(true);
		setData(data);
		setStatus(status)
	};// END handleDelete

	const handleClose = () => {
		setData(null);
	};// END handleClose

	const handleConfirmationClose = (message) => {
		if(message){
			// dispatch(DeleteCategory(data.id))
			console.log("DELETE CONFIRMATION")
		}
		else {
			setConfirmationDialogOpen(false);
			setData(null);
		}
	};// END handleConfirmationClose

	/* END Handle Change Action */
 
	return (
		<>
			<Helmet>
				<title> User | Rushipeetham </title>
			</Helmet>
			<Container>
				{
					confirmationDialogOpen?
					<UserBlockConfiramtion
						message = {status?"Unblock":"Block"}
						isOpenConfirm={confirmationDialogOpen}
						handleClose={handleConfirmationClose}
					/>:null
				}
				<Card>
					<UserListToolbar 
						placeHolder={"Search Users..."} 
						onFilterName={handleSearchChange} 
						filterName={searchValue} 
						handleClearClick={handleClearClick} 
						buttonName="New User" />
					<Scrollbar>
						<TableContainer sx={{ minWidth: 800 }}>
							<Table aria-label="simple table" size="small">
								<PagesHead headLabel={TABLE_HEAD} />
								{
									users?.length !== 0 ? 
									<TableBody>
										{
											users?.map((row, index) => (
												<TableRow key={index}>
													<TableCell>{(page*rowsPerPage)+(index + 1)}</TableCell>
													{/* <TableCell>{row?.username}</TableCell> */}
													<TableCell>{`${row?.first_name} ${row?.last_name}`}</TableCell>
													<TableCell>{row?.email}</TableCell>
													<TableCell>
														<Label color={(row?.status === 0 && 'error') || 'success'}>{sentenceCase(row?.status?"Active":"Inactive")}</Label>
													</TableCell>								
												</TableRow>
											))
										}
									</TableBody>
									: 
									<TableRow> 
										<TableCell colSpan={12}>
											<div className='text-align-center font-18 font-bold py-15' style={{'textAlign': 'center'}}>
												No data found...
											</div>
										</TableCell>
									</TableRow>
								}
							</Table>
						</TableContainer>
					</Scrollbar>
					<TablePagination
						rowsPerPageOptions={[10, 20, 30]}
						component="div"
						count={pageInfo.total?pageInfo.total:0}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				</Card>
			</Container>
		</>
	);// END return
}// END UserPage
