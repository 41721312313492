import React, { useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import { styled, alpha } from '@mui/material/styles';
import {
  Toolbar,
  Typography,
  OutlinedInput,
  InputAdornment,
  Stack,
  Button,
  MenuItem,
  TextField,
  Select,
  Box,
  SelectChangeEvent,
} from '@mui/material';
// component
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 320,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    width: 320,
    boxShadow: theme.customShadows.z8,
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

// ----------------------------------------------------------------------

PagesToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
};

export default function PagesToolbar({
  numSelected,
  filterName,
  onFilterName,
  placeHolder,
  handleClickOpen,
  buttonName,
  handleClearClick,
  searchable,
  filterable,
  arrayOfFilterOptions,
  onFilterChange,
  filterTitle,
  filterValue,
  statusFilterOptions,
  statusFilterValue,
  onStatusFilterChange,
  mostLikeBtn,
  handleMostFevFeed,
}) {
  const [filterOption, setFilterOption] = useState(filterValue);
  const handleFilterChange = (event) => {
    setFilterOption(event.target.value);
    onFilterChange(event.target.value);
  };
  const [statusFilterOption, setStatusFilterOption] = useState(statusFilterValue);
  const handleStatusFilterChange = (event) => {
    setStatusFilterOption(event.target.value);
    onStatusFilterChange(event.target.value);
  };
  if (searchable === undefined) searchable = true;
  if (filterable === undefined) filterable = false;

  return (
    <StyledRoot
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter',
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ width: '100%' }}>
          {searchable && (
            <StyledSearch
              value={filterName}
              onChange={onFilterName}
              placeholder={placeHolder || 'search...'}
              startAdornment={
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end">
                  <Iconify
                    onClick={handleClearClick}
                    icon="eva:close-outline"
                    sx={{
                      color: 'text.disabled',
                      width: 20,
                      height: 20,
                      visibility: filterName ? 'visible' : 'hidden',
                      cursor: 'pointer',
                    }}
                  />
                </InputAdornment>
              }
            />
          )}
          {filterable ? (
            <>
              <TextField
                id="filterId"
                name="filterName"
                label={filterTitle || 'Filter'}
                variant="outlined"
                select
                value={filterOption}
                onChange={handleFilterChange}
                sx={{ width: '20ch' }}
              >
                {arrayOfFilterOptions.map((option) => (
                  <MenuItem key={option.id} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

              {/* <TextField
                id="filterStatus"
                name="filterStatus"
                label="Status"
                variant="outlined"
                select
                value={statusFilterOption}
                onChange={handleStatusFilterChange}
                sx={{ width: '20ch' }}
              >
                {statusFilterOptions?.map((option) => (
                  <MenuItem key={option.id} value={option.value}>
                    {option.status}
                  </MenuItem>
                ))}
              </TextField> */}
            </>
          ) : (
            <>
              {/* {(placeHolder === "Search Notification...")?"":
              <TextField
                id="filterStatus"
                name="filterStatus"
                label="Status"
                variant="outlined"
                select
                value={statusFilterOption}
                onChange={handleStatusFilterChange}
                sx={{ width: '25ch' }}
              >
                {statusFilterOptions?.map((option) => (
                  <MenuItem key={option.id} value={option.value}>
                    {option.status}
                  </MenuItem>
                ))}
              </TextField>} */}
            </>
          )}
          <Box>
            {mostLikeBtn ? (
              <Button
                sx={{ backgroundColor: 'green', marginRight: '30px' }}
                variant="contained"
                // startIcon={<Iconify icon="eva:plus-fill" />}
                // onClick={() => prophandleMostFevFeed()}
                onClick={handleMostFevFeed}
              >
                Most Like Feeds
              </Button>
            ) : (
              ''
            )}
            <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleClickOpen}>
              {buttonName}
            </Button>
          </Box>
        </Stack>
      )}
    </StyledRoot>
  );
}
