import * as Yup from "yup";

export const bundleValidationSchema = Yup.object().shape({
	bundleName: Yup.string()
		.trim()
		.min(3, "Minimum 3 characters required")
		.max(65, "Too Long!")
		// .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed")
		.required("Bundle name is required."),
	shortDescription: Yup.string()
		.trim()
		.min(3, "Minimum 3 characters required")
		.max(100, "Maximum 100 characters required")
		.required("Short description is required."),
	longDescription: Yup.string()
		.trim()
		.min(3, "Minimum 3 characters required")
		.max(300, "Maximum 300 characters required")
		.required("Long description is required."),
	rating: Yup
		.number()
		.positive()
		.typeError('Rating must be a positive number')
		.required("Rating is required")
		.min(1, "Rating must be 1 to 5")
		.max(5, "Rating must be 1 to 5"),
	// image: Yup.string().required("Bundle image is required"),
	sequence: Yup
		.number()
		.positive()
		.typeError('Sequence must be a positive number')
		.required("Sequence is required")
		.min(1, "Sequence must be 1 to 99999")
		.max(99999, "Sequence must be 1 to 99999"),
	isActive : Yup.string().required("Field is required"),
});