import JWTServer from "../api/withJWTServer";
import { SetNewAlert } from "./AlertActions";
import { HandleExceptionWithSecureCatch, HandleManualException } from "./CombineCatch";
import { GET_USERS,GET_ALL_USERS } from "./Types";

const GetAllUsers = (page,limit,searchValue) => async (dispatch) => {
    try {
      const response = await JWTServer.get(`/api/users?page=${page}&limit=${limit}&search=${searchValue}&status=1`);
      // console.log("response of user",response)
      if(response.data.meta.status===200){
        if(limit===''){
          dispatch({
            type: GET_ALL_USERS,
            payload: response.data,
            
          });
        }else{
        dispatch({
          type: GET_USERS,
          payload: response.data,
        });
      }
      }
      else {
        dispatch(HandleManualException(response.data))
      }

     
    } catch (e) {
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };




  export {
     GetAllUsers,
  }