import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { Navigate, useRoutes, Route } from 'react-router-dom';
import { element } from 'prop-types';
// Layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
// Component Pages
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import DashboardAppPage from './pages/DashboardAppPage';
import BannerPage from './pages/BannerPage';
import ProductsPage from './pages/ProductsPage';
import CategoriesPage from './pages/CategoriesPage';
import NotificationsPage from './pages/NotificationsPage';
import UsersPage from './pages/UsersPage';
import ProductTagsPage from './pages/ProductTagsPage';
import BundlesPage from './pages/BundlesPage';
import BundlesDetailsPage from './pages/BundlesDetailsPage';
import SubscriptionsPage from './pages/SubscriptionsPage';
import MostFevFeed from './pages/MostFevFeed';
import { ShowLoader, ShowLoadingButton, ShowUploadLoader } from './actions/CommonAction';
import { DeleteAllAlert } from './actions/AlertActions';
import PrivacyPolicyHTMLPage from './pages/Privacy-Policy';
import { AdminRoles } from './components/common/constants';
import Feedpage from './pages/Feedpage';

// ----------------------------------------------------------------------
export default function Router() {
  const dispatch = useDispatch();
  const { isLogged, loggedUser } = useSelector((state) => ({
    isLogged: state.auth.isLogged,
    loggedUser: state.auth.loggedUser,
  }));

  useEffect(() => {
    dispatch(ShowUploadLoader({ loading: false, type: '' }));
    dispatch(ShowLoader(false));
    dispatch(ShowLoadingButton(false));
    dispatch(DeleteAllAlert());
  }, []);

  document.addEventListener('wheel', (event) => {
    if (document.activeElement.type === 'number') {
      document.activeElement.blur();
    }
  });

  const routes = useRoutes([
    {
      path: '/dashboard',
      element: isLogged ? <DashboardLayout /> : <Navigate to="/login" />,
      children:
        loggedUser?.roles?.role !== AdminRoles[1]
          ? [
              { element: <Navigate to="/dashboard/home" />, index: true },
              { path: 'home', element: <DashboardAppPage /> },
              { path: 'banners', element: <BannerPage /> },
              { path: 'users', element: <UsersPage /> },
              { path: 'products', element: <ProductsPage /> },
              { path: 'categories', element: <CategoriesPage /> },
              { path: 'notifications', element: <NotificationsPage /> },
              { path: 'tags', element: <ProductTagsPage /> },
              { path: 'most_like_feeds', element: <MostFevFeed /> },
              // { path: 'bundles',
              // 	children: [
              // 		{ element: <BundlesPage />,  index: true },
              // 		{ path: 'bundles-details', element: <BundlesDetailsPage /> }
              // 	]
              // },
              { path: 'subscriptions', element: <SubscriptionsPage /> },
              { path: 'latest_feeds', element: <Feedpage /> },
            ]
          : [
              { element: <Navigate to="/dashboard/subscriptions" />, index: true },
              { path: 'subscriptions', element: <SubscriptionsPage /> },
            ][
              ({ element: <Navigate to="/dashboard/latestfeeds" />, index: true },
              { path: 'mostfevfeed', element: <MostFevFeed /> })
            ],
    },
    {
      path: '/',
      children: [
        { element: <Navigate to="/login" />, index: true },
        { path: 'login', element: <LoginPage /> },
      ],
    },
    {
      path: 'profile',
      element: <LoginPage />,
    },
    {
      path: 'privacy-policy',
      element: <PrivacyPolicyHTMLPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/home" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);
  return routes;
}
