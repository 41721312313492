import { CREATE_PRODUCT, DELETE_PRODUCT, GET_ALL_PRODUCT, GET_PRODUCT_BY_ID, RESET_STATE, UPDATE_PRODUCT } from "../actions/Types";
    
    const INIT_STATE = {
      products:[],
      pageInfo:{},
      addProductLog:false,
      editProductLog: false,
      deleteProductLog : false,
      productById:{},
      newlyAddedProduct: []
    };
    
    export default function ProductsReducer (state = INIT_STATE, action)  {
      const { type, payload } = action;
    
      switch (type) {
        case GET_ALL_PRODUCT:
          return {
            ...state,
            products: payload.data.data,
            pageInfo: payload.data
          };
        case UPDATE_PRODUCT:
          return {
            ...state,
            editProductLog: !state.editProductLog,
          };
        case CREATE_PRODUCT:
          return {
            ...state,
            newlyAddedProduct: { 'prod_data': payload.data, 'prod_image': payload.product_image},
            addProductLog: !state.addProductLog,
          };
        case DELETE_PRODUCT:
          return {
            ...state,
            deleteProductLog: !state.deleteProductLog,
          };

          case GET_PRODUCT_BY_ID:
            return {
              ...state,
              productById: payload.data,
            };
        case RESET_STATE:
          return { ...INIT_STATE };
        default:
          return state;
      }
    };
    