import JWTServer from "../api/withJWTServer";
import { SetNewAlert } from "./AlertActions";
import { HandleExceptionWithSecureCatch, HandleManualException } from "./CombineCatch";
import { CREATE_BANNER, DELETE_BANNER, GET_ALL_BANNER, UPDATE_BANNER } from "./Types";

const GetAllBanners = (page,limit,searchValue,statusValue) => async (dispatch) => {
    try {
      const response = await JWTServer.get(`/api/banners?page=${page}&limit=${limit}&search=${searchValue}&status=1`);
      if(response.data.meta.status===200){
        dispatch({
          type: GET_ALL_BANNER,
          payload: response.data,
        });
      }
      else {
        dispatch(HandleManualException(response.data))
      }

      
    } catch (e) {
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };

  const AddBanner = (params) => async (dispatch) => {
      // console.log("ADD ROLE PARAMS",params);
    try {
      const response = await JWTServer.post("/api/create-banner",params);
      
      if(response.data.meta.status===200){
        dispatch({
          type: CREATE_BANNER,
          payload: response.data,
        });
        dispatch(SetNewAlert({
          msg: response.data.meta.message,
          alertType: "success",
        }));
      }
      else {
        dispatch(HandleManualException(response.data))
      }
      
    } catch (e) {
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };

  const EditBanner = (params,bannerId) => async (dispatch) => {
    try {
      const response = await JWTServer.put(`/api/update-banner/${bannerId}`,params);
      if(response.data.meta.status===200){
        dispatch({
          type: UPDATE_BANNER,
          payload: response.data,
        });
        dispatch(SetNewAlert({
          msg: response.data.meta.message,
          alertType: "success",
        }));
      }
      else {
        dispatch(HandleManualException(response.data))
      }
      
    } catch (e) {
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };

  const DeleteBanner = (params,status) => async (dispatch) => {
    try {
      const response = await JWTServer.delete(`/api/delete-banner/${params}`);
      if(response.data.meta.status===204){
        dispatch({
          type: DELETE_BANNER,
          payload: response.data,
        });
        dispatch(SetNewAlert({
          msg: response.data.meta.message,
          alertType: "success",
        }));
      }
      else {
        dispatch(HandleManualException(response.data))
      }
      
    } catch (e) {
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };


  export {
     GetAllBanners,
     AddBanner,
     EditBanner,
     DeleteBanner
  }