import { combineReducers } from 'redux';
import AlertReducer from './AlertReducer';
import AuthReducer from './AuthReducer';
import CommonReducer from './CommonReducer';
import CategoriesReducer from './CategoriesReducer';
import BannersReducer from './BannersReducer';
import UploadReducer from './UploadReducer';
import ProductsReducer from './ProductReducer';
import UserReducer from './UserReducer';
import DashboardReducer from './DashboardReducer';
import NotificationReducer from './NotificationReducer';
import DeleteReducer from './DeleteReducer';
import TagsReducer from './TagsReducer';
import BundlesReducer from './BundlesReducer';
import SubscriptionReducer from './SubscriptionReducer';
import FeedsReducer from './FeedReducer';

export default combineReducers({
  auth: AuthReducer,
  alerts: AlertReducer,
  common: CommonReducer,
  categories: CategoriesReducer,
  banners: BannersReducer,
  upload: UploadReducer,
  products: ProductsReducer,
  users: UserReducer,
  dashboard: DashboardReducer,
  notifications: NotificationReducer,
  deleteReducer: DeleteReducer,
  tags: TagsReducer,
  bundles: BundlesReducer,
  subscriptions: SubscriptionReducer,
  feeds: FeedsReducer,
});
