import React,{useState} from "react";
import { styled } from '@mui/material/styles';
import { sentenceCase } from 'change-case';
import { useNavigate } from "react-router-dom";

import {
	Typography,
	Box,
	Button,
	Card,
	TableContainer,
	Table,
	TableBody,
	TableRow,
	TableCell,
	Divider,
	Stack,
	Toolbar
} from '@mui/material';
import { PagesHead } from "../../sections/@dashboard/common";
import Iconify from "../iconify/Iconify";
import Scrollbar from "../scrollbar/Scrollbar";
import Label from "../label/Label";

const TABLE_HEAD = [
	// { id: 'username', label: 'Username', alignRight: false },
	{ id: 'name', label: 'Username', alignRight: false },
	{ id: 'email', label: 'Email', alignRight: false },

];

const StyledRoot = styled(Toolbar)(({ theme }) => ({
	height: 70,
	display: 'flex',
	justifyContent: 'space-between',
	padding: theme.spacing(0, 1, 0, 3),
}));

export default function LatestUser(props) {

	const navigate = useNavigate();
	const onCloseIconClick = (data) => {
		props.handleDelete(data);
	};
	const navigateToSection = () => {
		navigate('/dashboard/users', { replace: true });
	}

	return (
		<Card>
			<StyledRoot>
				<Stack direction="row" alignItems="center" justifyContent="space-between" sx={{width:'100%'}}>
					<Typography variant="h6">
						Latest User
					</Typography>
					<Button variant="contained"  onClick={()=>navigateToSection()}>
						View all
					</Button>
				</Stack>
			</StyledRoot>
			<Scrollbar>
				<TableContainer >
					<Table aria-label="simple table" size="medium">
						<PagesHead headLabel={TABLE_HEAD} />
						<TableBody>
						{props?.latestUser?.map((row, index) => (
							<TableRow key={index}>
							{/* <TableCell>{row.username}</TableCell> */}
							<TableCell>{`${row.first_name} ${row.last_name}`}</TableCell>
							<TableCell>{row.email}</TableCell>
							</TableRow>
						))}
						</TableBody>
					</Table>
				</TableContainer>
			</Scrollbar>
		</Card>    
	);
}

