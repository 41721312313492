import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { LoadingButton } from '@mui/lab';

// @mui
import {
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Paper,
    Grid,
    Button,
    MenuItem,
    IconButton,
    InputAdornment,
    InputLabel,
} from '@mui/material';


import CloseIcon from '@mui/icons-material/Close';

import { forgotPasswordValidationSchema } from '../../utils/validation/ForgotPasswordValidation';
import { ForgotPassword } from '../../actions/AuthActions';
import Iconify from '../iconify/Iconify';
import { ShowLoadingButton } from '../../actions/CommonAction';


// import { makeStyles } from '@material-ui/core/styles';
// import './common/category.css';


// ----------------------------------------------------------------------
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2}} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 12,
                        top: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};
const DisplayStatusArray = [
    {
      id: 1,
      value: 1,
      label: 'Yes'
    },
    {
      id: 2,
      value: 0,
      label: 'No'
    }
  ]

export default function ForgotPasswordDialog(props) {
    const dispatch = useDispatch();
    const {data} = props;


    const { forgotPasswordLog, showLoadingButton} = useSelector((state) => ({
        changePasswordLog: state.auth.forgotPasswordLog,
        showLoadingButton: state.common.showLoadingButton,
      }));

    const firstRun = React.useRef(true);
    React.useEffect(()=>{
      if (firstRun.current) {
        firstRun.current = false;
        return;
      }
      props.onClose()
    },[forgotPasswordLog])


    const formik = useFormik({
        initialValues: {
          email:""
        },
        validationSchema: forgotPasswordValidationSchema,
        onSubmit: (value) => {
            const reqObj = {
                "username":value.email,
            }
            dispatch(ShowLoadingButton(true));
            dispatch(ForgotPassword(reqObj));
            
        },
      });
    
      const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps,setFieldValue } = formik;

    return (
        <>

            <Dialog
                open={props.open}
                // onClose={props.onClose}
                className="dialog-box-section"
                sx={{
                    "& .MuiDialog-container": {
                      "& .MuiPaper-root": {
                        maxWidth: "500px",
                        minWidth:'400px',
                        minHeight: '300px'
                      },
                    },
                  }}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={props.onClose}>
                    Reset Password
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12}>
                        <InputLabel  >
                        Email
                        </InputLabel>
                        <TextField
                        id="email"
                        name="email"
                        placeholder='Enter your email'
                        style={{marginTop:'2px',width:'100%',marginBottom:'50px'}}
                        {...getFieldProps('email')}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                        />
                        </Grid>
                    </Grid>
                    <LoadingButton loading={showLoadingButton}  fullWidth size="large" type="submit" variant="contained" onClick={formik.handleSubmit} style={{backgroundColor:'#5F4823'}}>
                        Reset Password
                    </LoadingButton>
                </DialogContent>
            </Dialog>

        </>
    );
}
