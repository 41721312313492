import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { styled } from '@mui/material/styles';
// @mui
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Button,
  IconButton,
  Avatar,
  CircularProgress,
  Backdrop,
  Typography,
  Box,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { UploadProductImages, UploadProductFiles, GetFileByFolderName } from '../../actions/UploadActions';
import { ShowLoader, ShowUploadLoader } from '../../actions/CommonAction';
import { SetNewAlert } from '../../actions/AlertActions';
import { DisplayStatusArray, SubscriptionType, Months, MaxDateValue, MinDateValue } from '../common/constants';
import { GetFeedById } from '../../actions/FeedAction';

// ----------------------------------------------------------------------

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 12,
            top: 8,
            color: 'black',
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: 'green',
  color: '#FFFFFF',
  cursor: 'pointer',
  width: 34,
  height: 34,
  marginLeft: 5,
}));

export default function ViewFeedDialog(props) {
  /* START Configure Data On Page */
  const dispatch = useDispatch();
  const { feedId } = props;
  console.log('feedId in view feed', feedId);
  // let productMediaFolderName = '';
  const [upload, setUpload] = useState([]);
  const [fileUrl, setFileUrl] = useState('');
  const [changeMp3, setChangeMp3] = useState(false);
  const [changePdf, setChangePdf] = useState(false);
  // const [showProductList, setShowProductList] = useState(false);
  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [isFolderNameAvailable, setIsFolderNameAvailable] = useState(false);
  const [showAudioLoader, setShowAudioLoader] = useState(false);
  const [videoArray, setVideoArray] = useState([
    {
      // title: data ? data?.feed_video[0]?.file_name : '',
      // videoId: data ? data?.feed_video[0]?.file_path : '',
      titleError: '',
      videoIdError: '',
    },
  ]);
  console.log('videoArray', videoArray);
  const [uploadedPDFPageCount, setUploadedPDFPageCount] = useState(0);

  // let audioProductList = [];
  const PDFProductList = [];
  const [tagData, setTagData] = useState([]);
  // console.log("tagData",tagData)
  const [tagValidation, setTagValidation] = useState(false);
  const [arrConstCategoryType, setArrConstCategoryType] = useState([]);
  const [isThisShortVideoCat, setIsThisShortVideoCat] = useState(false);
  const [mediaFolderName, setMediaFolderName] = useState('');
  console.log('mediaFolderName', mediaFolderName);
  const [pdfProductList, setPdfPoductList] = useState([]);
  const [audioProductList, setAudioProductList] = useState([]);

  const {
    addFeedLog,
    editFeedLog,
    tagsForDropdown,
    productImage,
    uploadProductImageLog,
    productFile,
    uploadProductFileLog,
    feedById,
    showLoader,
    showUploadLoader,
    fileByFolder,
  } = useSelector((state) => ({
    addFeedLog: state.feeds.addFeedLog,
    editFeedLog: state.feeds.editFeedLog,

    productImage: state.upload.productImage,
    uploadProductImageLog: state.upload.uploadProductImageLog,
    productFile: state.upload.productFile,
    uploadProductFileLog: state.upload.uploadProductFileLog,
    feedById: state.feeds.feedById,
    showLoader: state.common.showLoader,
    showUploadLoader: state.common.showUploadLoader,
    fileByFolder: state.upload.fileByFolder,
  }));
  const label = { inputProps: { 'aria-label': 'Switch demo' } };

  console.log('feedById in view', feedById);
  const fileByFolderRun = React.useRef(true);

  React.useEffect(() => {
    if (feedId) {
      dispatch(GetFeedById(feedId));
    }
  }, [feedId]);

  React.useEffect(() => {
    if (fileByFolderRun.current) {
      fileByFolderRun.current = false;
      return;
    }
    dispatch(ShowUploadLoader({ loading: false, type: '' }));

    if (fileByFolder?.pdf_files?.length > 0) {
      const productMediaFolderName = fileByFolder?.pdf_files[0]
        ?.split('/')
        .slice(0, -1)
        .toString()
        .replaceAll(',', '/');
      // setMediaFolderName(productMediaFolderName);
      const PDFProductList = JSON.parse(
        JSON.stringify(
          fileByFolder?.pdf_files.map((option) => ({
            file_name: option,
            file_path: option,
            is_premium: 0,
          }))
        )
      );
      setPdfPoductList(PDFProductList);
    } else if (fileByFolder?.mp3_files.length > 0) {
      const productMediaFolderName = fileByFolder?.mp3_files[0]
        ?.split('/')
        .slice(0, -1)
        .toString()
        .replaceAll(',', '/');
      setMediaFolderName(productMediaFolderName);
      const audioProduct = JSON.parse(
        JSON.stringify(
          fileByFolder?.mp3_files.map((option) => ({
            file_name: option,
            file_path: option,
            is_premium: 0,
          }))
        )
      );
      setAudioProductList(audioProduct);
    } else {
      setPdfPoductList([]);
      setAudioProductList([]);
    }
  }, [fileByFolder]); // END useEffect
  console.log('valueeee----');

  const getProductRun = React.useRef(true);
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <Dialog open={props.open} className="dialog-box-section">
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={showAudioLoader}>
        <CircularProgress color="primary" />
      </Backdrop>
      <BootstrapDialogTitle id="customized-dialog-title" onClose={props.onClose}>
        Latest Feed
      </BootstrapDialogTitle>

      <DialogContent dividers sx={{ height: '85vh', width: '500px' }}>
        {showLoader ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '400px',
              width: '400px',
            }}
          >
            <CircularProgress style={{ color: '#FFA728' }} />
          </div>
        ) : (
          <>
            {/* <Grid container sx={{ height: '60vh' }}> */}
            <Grid item xs={12}>
              <Typography sx={{ fontWeight: 'bold', marginTop: '8px', letterSpacing: '3px', marginBottom: '10px' }}>
                {feedById.feed_title}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                sx={{
                  color: 'gray',
                  fontWeight: 'bold',
                  textAlign: 'start',
                  marginBottom: '10px',
                  letterSpacing: '2px',
                }}
              >
                {feedById.feed_description}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {feedById.feed_media_type === 'IMAGE' && (
                <Box position="relative" display="flex" justifyContent="center" alignItems="center">
                  {imageLoaded ? null : <CircularProgress />}
                  <img
                    src={`${process.env.REACT_APP_BASE_URL}/api/get-admin-image-file/${feedById?.feed_image[0]?.id}`}
                    alt="view"
                    width="100%"
                    height="250"
                    style={{ display: imageLoaded ? 'block' : 'none' }}
                    onLoad={() => setImageLoaded(true)}
                  />
                </Box>
              )}

              {feedById.feed_media_type === 'VIDEO' && feedById.feed_video && (
                <Box>
                  <iframe
                    width="100%"
                    height="250"
                    src={`https://www.youtube.com/embed/${feedById.feed_video[0]?.file_path}`}
                    allowfullscreen
                    autoPlay
                    title="video"
                  />
                </Box>
              )}

              {feedById.feed_media_type === 'NONE' && (
                <Box>
                  <p> No media selected</p>
                </Box>
              )}
            </Grid>

            <Grid
              item
              xs={12}
              lg={12}
              sx={{
                background: 'white',
                zIndex: 99,
                borderTop: '1px solid gray',
                position: 'absolute',
                bottom: '20px',
                left: 0,
                width: '100%',
                display: 'flex',
                color: '#919EAB',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Button
                disabled={feedById.is_show_like_btn === 0}
                variant="outlined"
                sx={{ marginRight: '15px', marginTop: '15px' }}
              >
                Like
              </Button>
              <Button disabled={feedById.is_show_share_btn === 0} variant="outlined" sx={{ marginTop: '15px' }}>
                Share
              </Button>
            </Grid>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}
