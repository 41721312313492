import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { styled } from '@mui/material/styles';
// @mui
import {
	TextField,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Paper,
	Grid,
	Button,
	MenuItem,
	IconButton,
	InputLabel,
	Avatar
	
} from '@mui/material';
import PropTypes from 'prop-types';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { GetCategoryById, GetCategoryForDropdown } from '../../actions/CategoriesAction';
import ViewImageList from '../image-upload/ViewImageList';
import ImageUpload from '../image-upload/ImageUpload';
import Iconify from '../iconify/Iconify';
import { BannerLinkTypeArr } from '../common/constants';
// import { makeStyles } from '@material-ui/core/styles';
// import './common/category.css';
// ----------------------------------------------------------------------
const BootstrapDialogTitle = (props) => {
	const { children, onClose, ...other } = props;

	return (
		<DialogTitle sx={{ m: 0, p: 2,}} {...other}>
			{children}
			{onClose ? (
				<IconButton
					aria-label="close"
					onClick={onClose}
					sx={{
						position: 'absolute',
						right: 12,
						top: 8,
					}}
				>
					<CloseIcon />
				</IconButton>
			) : null}
		</DialogTitle>
	);
};

BootstrapDialogTitle.propTypes = {
	children: PropTypes.node,
	onClose: PropTypes.func.isRequired,
};

const StyledAvatar = styled(Avatar)(({ theme }) => ({
	backgroundColor: 'green',
	color: '#FFFFFF',
	cursor: 'pointer',
	width: 34,
	height: 34,
	marginLeft: 5
}));

export default function ViewBannerDialog(props) {
	const dispatch = useDispatch();
	const {data} = props;
	if(data?.banner_web_url && (data?.banner_web_url.toLowerCase().includes("www") && !data?.banner_web_url.toLowerCase().includes("http"))){
		data.banner_web_url = `http://${data.banner_web_url.toLowerCase()}`;
	}else{
		data.banner_web_url = `${data?.banner_web_url? data.banner_web_url.toLowerCase():""}`;
		
	}
	
	const [upload, setUpload] = useState([]);
	const [showProductList, setShowProductList] = useState(false);
	const [isImageUploaded, setIsImageUploaded] = useState(false);

	const { categoriesForDropdown,categoryById} = useSelector((state) => ({
		categoriesForDropdown: state.categories.categoriesForDropdown,
		categoryById: state.categories.categoryById,
	}));


	React.useEffect(()=>{
		dispatch(GetCategoryForDropdown())
		if(data){
			dispatch(GetCategoryById(data.banner_product?.category_id))
			setShowProductList(true)
			if(data?.banner_image?.length!==0){
				setUpload(data?.banner_image);
				setIsImageUploaded(true)
			}
			else {
				setIsImageUploaded(false)
			}
		}
	},[data])

	return (
		<>
			<Dialog
				open={props.open}
				// onClose={props.onClose}
				className="dialog-box-section"
			>
				<BootstrapDialogTitle id="customized-dialog-title" onClose={props.onClose}>
					Banner
				</BootstrapDialogTitle>
				<DialogContent dividers>
					<Grid container spacing={2}>

						<Grid item xs={12}>
							<InputLabel sx={{mb:"5px",fontWeight:600}}>
								Banner Image
							</InputLabel>
							<ViewImageList imageArray={upload} />
							{/* <Grid item xs={12}>
						   
							{upload?.map((value,index)=>{
								return  <Avatar alt="Travis Howard" variant='circle' src={value.url} sx={{ width: 100, height: 100 }} />
							})}
							</Grid> */}
						</Grid>
						
						<Grid item xs={12} sm={12} md={12}>
						<InputLabel sx={{mb:"5px",fontWeight:600}}>
								Banner Title
							</InputLabel>
							<TextField
								id="outlined-name-input"
								type="text"
								fullWidth
								InputProps={{
									readOnly: true,
								}}
								value={data.banner_title}
							/>
						</Grid>
						<Grid item xs={12}>
						<InputLabel sx={{mb:"5px",fontWeight:600}}>
								Banner Description
							</InputLabel>
							<TextField
								multiline
								rows={2}
								id="outlined-name-input"
								InputProps={{
									readOnly: true,
								}}
								type="text"
								fullWidth
								value={data.banner_description}
							/>
						</Grid>
					   {/*  
							<Grid item xs={12} sm={12} md={12}>
								<InputLabel sx={{mb:"5px",fontWeight:600}}>
									Product
								</InputLabel>
								<TextField
									id="productId"
									name="status"
									InputProps={{
										readOnly: true,
									}}
									value={data.banner_product?.product_name}
									fullWidth
								/>                            
							</Grid>
						*/}
						<Grid item xs={12} sm={12} md={12}>
							<InputLabel sx={{mb:"5px",fontWeight:600}}>
								Banner Link Type
							</InputLabel>
							<TextField
								id="device_type"
								name="Banner Link Type"
								InputProps={{
									readOnly: true,
								}}
								value={ data?.banner_link_type }
								fullWidth
							/>
						</Grid>
						{
							data?.banner_link_type !== BannerLinkTypeArr[0].value? 
							((data?.product_id!==null && data?.banner_link_type === BannerLinkTypeArr[2].value) ?
							<Grid item xs={12} sm={12} md={12}>
								<InputLabel sx={{mb:"5px",fontWeight:600}}>
									Target Product
								</InputLabel>
								<TextField
									id="product_id"
									name="Target Product"
									InputProps={{
										readOnly: true,
									}}
									value={ data?.banner_product?.product_name }
									fullWidth
								/>
							</Grid> 
							:
							<Grid item xs={12} sm={12} md={12}>
								<InputLabel sx={{mb:"5px",fontWeight:600}}>
									Banner Web URL
								</InputLabel>
								<TextField
									id="web_url"
									name="Banner Web URL"
									InputProps={{
										readOnly: true,
									}}
									value={ data?.banner_web_url }
									fullWidth
									sx={{mb:"10px",fontWeight:600}}
								/>
								<StyledAvatar onClick={()=>window.open(`${data?.banner_web_url}`, "_blank").focus()}>
									<Iconify
										icon={'eva:eye-outline'}
										sx={{ width: 22, height: 22}}
									/>
								</StyledAvatar>
							</Grid>)
							: ""
						}      
						<Grid item xs={12} sm={12} md={12}>
							<InputLabel sx={{mb:"5px",fontWeight:600}}>
								Sequence
							</InputLabel>
							<TextField
								id="outlined-name-input"
								type="number"
								fullWidth
								InputProps={{
									readOnly: true,
								}}
								value={data.sequence}
							/>
						</Grid>
						{/* <Grid item xs={12} sm={12} md={12}>
						<InputLabel sx={{mb:"5px",fontWeight:600}}>
								Is Display on Homepage ? 
							</InputLabel>
							<TextField
								id="DisplayStatus"
								fullWidth
								InputProps={{
									readOnly: true,
								}}
								value={data?.is_display_on_homepage===1?"Yes":"No"}
							/>
						   
						</Grid> */}
						<Grid item xs={12} sm={12} md={12}>
						<InputLabel sx={{mb:"5px",fontWeight:600}}>
								Status
							</InputLabel>
							<TextField
								id="ActiveStatus"
								name="status"
								InputProps={{
									readOnly: true,
								}}
								fullWidth
								value={data?.is_active===1?"Active":"Inactive"}
							/>
						</Grid>
						
						
					</Grid>
				</DialogContent>
			</Dialog>

		</>
	);
}
