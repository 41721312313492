import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { styled } from '@mui/material/styles';

// @mui
import {
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Paper,
    Grid,
    Button,
    MenuItem,
    IconButton,
} from '@mui/material';


import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { categoriesValidationSchema } from '../../utils/validation/CategoriesValidation';
import { AddCategory, EditCategory } from '../../actions/CategoriesAction';


// import { makeStyles } from '@material-ui/core/styles';
// import './common/category.css';


// ----------------------------------------------------------------------
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2}} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 12,
                        top: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};
const DisplayStatusArray = [
    {
      id: 1,
      value: 1,
      label: 'Yes'
    },
    {
      id: 2,
      value: 0,
      label: 'No'
    }
  ]

export default function CategoryDialogBox(props) {
    const dispatch = useDispatch();
    const {data} = props;
    const intialState = {
        pictures: [],
        maxFileSize: 5242880,
        imgExtension: [".jpg", ".png",".svg"],
        defaultImages: []
    }

    const [upload, setUpload] = useState(intialState);
    const { addCategoryLog, editCategoryLog } = useSelector((state) => ({
        addCategoryLog: state.categories.addCategoryLog,
        editCategoryLog: state.categories.editCategoryLog,
      }));

    const firstRun = React.useRef(true);
    React.useEffect(()=>{
      if (firstRun.current) {
        firstRun.current = false;
        return;
      }
      props.onClose()
    },[addCategoryLog,editCategoryLog])


    const formik = useFormik({
        initialValues: {
          name:data?data.category_name:"",
          specialNote:data?data.special_note===null?"":data.special_note:"",
          sequence:data?data.sequence:"",
          displayOnHomePage:data?data.is_display_on_homepage:0,
          isActive: data?data.is_active:1
        },
        validationSchema: categoriesValidationSchema,
        onSubmit: (value) => {
            if(data) {
                const reqObj = {
                    "category_name": value.name,
                    "special_note": value.specialNote,
                    "is_display_on_homepage": value.displayOnHomePage,
                    "is_active": value.isActive,
                    "sequence": value.sequence
                }
                dispatch(EditCategory(reqObj,data.id));
            }
            else {
                const reqObj = {
                    "category_name": value.name,
                    "special_note": value.specialNote,
                    "is_display_on_homepage": value.displayOnHomePage,
                    "is_active": value.isActive,
                    "sequence": value.sequence
                }
                dispatch(AddCategory(reqObj));
            }
            
        },
      });

    
      const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps,setFieldValue } = formik;

    return (
        <>

            <Dialog
                open={props.open}
                // onClose={props.onClose}
                className="dialog-box-section"
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={props.onClose}>
                    Category
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12}>
                            <TextField
                                id="outlined-name-input"
                                label="Category Name*"
                                
                                inputProps={{ maxLength: 30 }}
                                type="text"
                                fullWidth
                                error={Boolean(touched.name && errors.name)}
                                helperText={touched.name && errors.name}
                                {...getFieldProps('name')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                multiline
                                rows={2}
                                id="outlined-name-input"
                                label="Special Note"
                               
                                inputProps={{ maxLength: 75 }}
                                type="text"
                                fullWidth
                                error={Boolean(touched.specialNote && errors.specialNote)}
                                helperText={touched.specialNote && errors.specialNote}
                                {...getFieldProps('specialNote')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <TextField
                                id="sequence"
                                name='sequence'
                                label="Sequence*"
                                type="number"
                                inputProps={{ maxLength: 5 }}
                                onChange={(e)=>{
                                    if (e.target.value.length === 6) {
                                        return
                                    }
                                    setFieldValue("sequence",e.target.value)
                                    formik.handleChange(e)
                                }}
                                value={values.sequence}
                                fullWidth
                                error={Boolean(touched.sequence && errors.sequence)}
                                helperText={touched.sequence && errors.sequence}
                                // {...getFieldProps('sequence')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <TextField
                                id="DisplayStatus"
                                select
                                label="Display On Home Page"
                                fullWidth
                                error={Boolean(touched.displayOnHomePage && errors.displayOnHomePage)}
                                helperText={touched.displayOnHomePage && errors.displayOnHomePage}
                                {...getFieldProps('displayOnHomePage')}
                            >
                                {DisplayStatusArray.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <TextField
                                id="ActiveStatus"
                                select
                                name="status"
                                label="Is Active"
                                fullWidth
                                error={Boolean(touched.isActive && errors.isActive)}
                                helperText={touched.isActive && errors.isActive}
                                {...getFieldProps('isActive')}
                            >
                                {DisplayStatusArray.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ justifyContent: "end",pr:"25px" }}>
                    <Button  variant="outlined" onClick={props.onClose} sx={{minWidth:'100px',color:(theme) => theme.palette.text.darkGrey,borderColor:(theme) => theme.palette.text.darkGrey}} >Cancel</Button>
                    <Button variant="contained" onClick={formik.handleSubmit} style={{minWidth:'100px'}}>Save</Button>
                </DialogActions>
            </Dialog>

        </>
    );
}
