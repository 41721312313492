import * as Yup from "yup";


export const bannerValidationSchema = Yup.object().shape({
        // title: Yup.string()
        //         .trim()
        //         .min(3, "Minimum 3 characters required")
        //         .max(30, "Maximum 15 characters required")
        //         // .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed")
        //         .required("Banner title is required."),
        // description: Yup.string()
        //         .trim()
        //         .min(3, "Minimum 3 characters required")
        //         .max(100, "Maximum 100 characters required"),
        // category: Yup.string()
        //         .required("Target category is required"),
        // product: Yup.string()
        //         .required("Target product is required"),
        link_type: Yup.string()
                .required("Banner link type is required"),
        image: Yup.string()
                .required("Banner image is required"),
        sequence: Yup.number().positive().required("Sequence is required"),
        displayOnHomePage:  Yup.string().required("Banner should be display on Home page is required."),
        isActive : Yup.string().required("Field is required"),
        // web_url: Yup.string()
        //         .required("Notification web url is required"),
  });