import JWTServer from "../api/withJWTServer";
import { SetNewAlert } from "./AlertActions";
import { HandleExceptionWithSecureCatch, HandleManualException } from "./CombineCatch";
import { CREATE_CATEGORY, DELETE_CATEGORY, GET_ALL_CATEGORY, GET_CATEGORY_BY_ID, GET_CATEGORY_FOR_DROPDOWN, UPDATE_CATEGORY } from "./Types";

const GetAllCategories = (page,limit,searchValue,statusValue) => async (dispatch) => {
    try {
      const response = await JWTServer.get(`/api/categories?page=${page}&limit=${limit}&search=${searchValue}&status=1`);

      if(response.data.meta.status===200){
        dispatch({
          type: GET_ALL_CATEGORY,
          payload: response.data,
        });
      }
      else {
        dispatch(HandleManualException(response.data))
      }
    } catch (e) {
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };

const GetCategoryForDropdown = () => async (dispatch) => {
  try {
    const response = await JWTServer.get(`/api/categories?status=1`);
    if(response.data.meta.status===200){
      dispatch({
        type: GET_CATEGORY_FOR_DROPDOWN,
        payload: response.data,
      });
    }
    else {
      dispatch(HandleManualException(response.data))
    }

    
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};

  const AddCategory = (params) => async (dispatch) => {
      // console.log("ADD ROLE PARAMS",params);
    try {
      const response = await JWTServer.post("/api/create-category",params);

      if(response.data.meta.status===200){
        dispatch({
          type: CREATE_CATEGORY,
          payload: response.data,
        });
        dispatch(SetNewAlert({
          msg: response.data.meta.message,
          alertType: "success",
        }));
      }
      else {
        dispatch(HandleManualException(response.data))
      }

      
    } catch (e) {
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };

  const EditCategory = (params,categoryId) => async (dispatch) => {
    try {
      const response = await JWTServer.put(`/api/update-category/${categoryId}`,params);
      if(response.data.meta.status===200){
        dispatch({
          type: UPDATE_CATEGORY,
          payload: response.data,
        });
        dispatch(SetNewAlert({
          msg: response.data.meta.message,
          alertType: "success",
        }));
      }
      else {
        dispatch(HandleManualException(response.data))
      }
      
    } catch (e) {
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };

  const DeleteCategory = (params,status) => async (dispatch) => {
    try {
      const response = await JWTServer.delete(`/api/delete-category/${params}`);
      if(response.data.meta.status===204){
        dispatch({
          type: DELETE_CATEGORY,
          payload: response.data,
        });
        dispatch(SetNewAlert({
          msg: response.data.meta.message,
          alertType: "success",
        }));
      }
      else {
        dispatch(HandleManualException(response.data))
      }
      
    } catch (e) {
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };

  const GetCategoryById = (id) => async (dispatch) => {
    try {
      const response = await JWTServer.get(`/api/get-category-by-id/${id}`);  
      if(response.data.meta.status===200){
        dispatch({
          type: GET_CATEGORY_BY_ID,
          payload: response.data,
        });
      }
      else {
        dispatch(HandleManualException(response.data))
      }
  
      
    } catch (e) {
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };


  export {
     GetAllCategories,
     GetCategoryForDropdown,
     AddCategory,
     EditCategory,
     DeleteCategory,
     GetCategoryById
  }