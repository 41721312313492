import * as React from 'react';
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Grid,
  Button,
  MenuItem,
  IconButton,
  Divider,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import DialogContentText from '@mui/material/DialogContentText';
import Box from '@mui/material/Box';
import caution from '../../assets/caution.png';

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
      <DialogTitle sx={{ m: 0, p: 2}} {...other}>
          {children}
          {onClose ? (
              <IconButton
                  aria-label="close"
                  onClick={onClose}
                  sx={{
                      position: 'absolute',
                      right: 12,
                      top: 8,
                  }}
              >
                  <CloseIcon />
              </IconButton>
          ) : null}
      </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function UserBlockConfiramtion(props) {
  const [open, setOpen] = React.useState(false);
  const { isOpenConfirm } = props;


  const handleClose = () => {
    setOpen(false);
    props.handleClose(false);
  };

  const handleTrueClose = () => {
    props.handleClose(true);
  };

  return (
    <>
      <Dialog
        // open={open}
        open={isOpenConfirm}
        // onClose={handleClose}
        className="dialog-box-section"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              minWidth: "400px", 
              minHeight: "220px"
            },
          },
        }}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {props.message}
                </BootstrapDialogTitle>
        <DialogContent dividers>
        <Grid container spacing={1}>
          <DialogContentText id="alert-dialog-description">
          {`Are you sure want to ${props.message}?`}
          <br />
          </DialogContentText>
        </Grid>
          
        </DialogContent>
        <DialogActions sx={{ justifyContent: "end",pr:"25px" }}>
        <Button  variant="outlined" onClick={handleClose} sx={{minWidth:'100px',color:(theme) => theme.palette.text.darkGrey,borderColor:(theme) => theme.palette.text.darkGrey}} >Cancel</Button>
          <Button onClick={handleTrueClose}  variant="contained" style={{minWidth:'100px'}}>yes</Button>
        </DialogActions>
      </Dialog>
    </>
  );}
