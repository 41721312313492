
/* Routes */
import Router from './routes';
/* Theme */
import ThemeProvider from './theme';
/* Components */
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';
// ----------------------------------------------------------------------
export default function App() {
	return (
		<ThemeProvider>
			<ScrollToTop />
			<StyledChart />
			<Router />
		</ThemeProvider>
	);
}
