import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
// @mui
import {
	Autocomplete,
	TextField,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	Button,
	MenuItem,
	IconButton,
	Card,
	CardActions,
	CardContent,
	Box,	
	Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { subscriptionValidationSchema } from '../../utils/validation/SubscriptionValidation';
import { GetAllUsers } from '../../actions/UserAction';
import {
	SubscriberType,
	ApiRecordPageNo,
	ApiRecordLimit,
	DeviceTypeArr
} from '../common/constants';
import { ShowLoader, ShowUploadLoader } from '../../actions/CommonAction';
import { SetNewAlert } from '../../actions/AlertActions';
import { GetAllSubscriptionTypes, AddSubscription } from '../../actions/SubscriptionAction';
// ----------------------------------------------------------------------
const BootstrapDialogTitle = (props) => {
	const { children, onClose, ...other } = props;
	return (
		<DialogTitle sx={{ m: 0, p: 2 }} {...other}>
		{children}
		{onClose ? (
			<IconButton
				aria-label="close"
				onClick={onClose}
				sx={{
					position: 'absolute',
					right: 12,
					top: 8,
				}}
				>
				<CloseIcon />
			</IconButton>
		) : null}
		</DialogTitle>
	);
};

BootstrapDialogTitle.propTypes = {
	children: PropTypes.node,
	onClose: PropTypes.func.isRequired,
};

const bull = (
	<Box component="span" sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}>
		•
	</Box>
);

export default function SubscriptionDialogBox(props) {
	
	/* START Configure Data On Page */
	const dispatch = useDispatch();
	const { data } = props;
	const [usersList, setUsersList] = useState([]);
	const [validateEmail, setValidateEmail] = useState(false);
	const [description, setDescription] =  useState([]);
	const [errorColorBorder, setErrorColorBorder] = useState(null);
	const { usersdata, addSubscriptionLog, editSubscriptionLog, subscriptionTypeList } = useSelector((state) => ({
		usersdata: state?.users.allUsers,
		addSubscriptionLog: state.subscriptions.addSubscriptionLog,
		editSubscriptionLog: state.subscriptions.editSubscriptionLog,
		subscriptionTypeList: state.subscriptions.subscriptionTypeList || [],
	}));
	/* START UseEffect */
	React.useEffect(() => {
		dispatch(GetAllUsers(ApiRecordPageNo,'',''));
		dispatch(GetAllSubscriptionTypes());
		// dispatch(ShowLoader(false));
	}, [dispatch]); // END React.

	React.useEffect(() => {
		// dispatch(ShowLoader(true));
		if (data) {
			// dispatch(GetSubscriptionById(data?.id));
			if (data?.user?.email !== undefined && data?.user?.email !== '') {
				setFieldValue('email', { id: data?.user?.id, label: data?.user?.email });
			}
		}		
	}, [data]); // END React.

	React.useEffect(() => {
		if (usersdata) {
			// console.log("usersdata",usersdata)
		const arr = usersdata?.map((item, index) => ({
			id: item.id,
			label: item.email,
		}));
		setUsersList(arr);
		}
	}, [usersdata]);


	const firstRun = React.useRef(true);
	React.useEffect(() => {
		if (firstRun.current) {
			firstRun.current = false;
			return;
		}
		props.onClose();
	}, [addSubscriptionLog, editSubscriptionLog]);

	/* END UseEffect */

	/* START Handle Action Functions */

	const handleSubscriptionTypeChange = (e) => {		
		setDescription(subscriptionTypeList.find((item) => item.id===e.target.value));
		formik.handleChange(e);
	}// END handleSubscriptionTypeChange

	/* END  Handle Action Functions */

	/* START Formik */
	const formik = useFormik({
		initialValues: {
			email: data ? [{ id: data?.user?.id, label: data?.user?.email }] : null,
			subscriptionType: data ? data.subscription_id : "",
			device_type: data?data.device_type:"",
		},
		validationSchema: subscriptionValidationSchema,
		onSubmit: (value) => {
			if (Object.values(validateEmail).some((item) => item === true)) {
				return false;
			}
			if(value.device_type !== DeviceTypeArr[0].value && value.device_type !== DeviceTypeArr[1].value){
                return  dispatch(SetNewAlert({
                    msg: "Please enter valid device type",
                    alertType: "danger",
                }));
            }
			const reqObj = {
				user_id: value.email.id.toString(),
				subscription_id: value.subscriptionType,
				device_type: value.device_type				
			};
			console.log('AA REQOBJ', reqObj);
			dispatch(AddSubscription(reqObj));
			return true;
		},
	});

	const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik;
	/* END Formik */

	const subscriptionRefRUn = React.useRef(true);
	if (isSubmitting) {
		if (subscriptionRefRUn.current) {
			subscriptionRefRUn.current = false;
			// eslint-disable-next-line no-prototype-builtins
			setValidateEmail(!values?.email?.hasOwnProperty('label'));
			// setErrorColorBorder({"& .MuiOutlinedInput-root": {
			// 	border: "1px solid red"
			// },"& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
			// 	border: "1px solid #eee"
			// }});
		}
	}

	return (
		<>
			<Dialog
				open={props.open}
				// onClose={props.onClose}
				className="dialog-box-section"
				maxWidth={'sm'}
				fullWidth
			>
				<BootstrapDialogTitle id="customized-dialog-title" onClose={props.onClose}>
					Assign Subscription
				</BootstrapDialogTitle>
				<DialogContent dividers>
					<Grid item xs={12} sm={12} md={12} container spacing={2}>
						<Grid item xs={12} sm={12} md={12}>
							<Autocomplete
								id="email"
								name="email"
								variant="outlined"
								options={usersList}
								getOptionLabel={(option) => option.label || ''}
								// isOptionEqualToValue={(option, value) => option.label === value.label}
								onChange={(e, newValue) => {
									setFieldValue('email', newValue);
									// eslint-disable-next-line no-prototype-builtins
									setValidateEmail(!(newValue?.hasOwnProperty('label') || newValue?.label !== undefined));
									// eslint-disable-next-line no-prototype-builtins
									// if(!(newValue?.hasOwnProperty('label') || newValue?.label !== undefined) === false) {
									// 	setErrorColorBorder(null);
									// }else{
									// 	setErrorColorBorder({"& .MuiOutlinedInput-root": {
									// 		border: "1px solid red ! important"
									// 	},  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
									// 		border: "1px solid #eee"
									// 	}});
									// }
									formik.handleChange(e);
								}}
								// sx={errorColorBorder}								
								inputprops={{
									disabled: data?.length > 0	
								}}
								filterSelectedOptions
								// defaultValue={values.email}
								value={values.email}
								renderInput={(params) => (
									<TextField
										fullWidth
										variant="outlined"
										{...params}										
										label="Email *"
										// name="email"
										placeholder="email"
									/>
								)}
								// error={Boolean(touched.subscriptionType && errors.subscriptionType)}
								// helperText={touched.subscriptionType && errors.subscriptionType}
							/>
							<div className="errorMessage">{validateEmail && `Email is required`}</div>
						</Grid>
						<Grid item xs={12} sm={12} md={12}>
							<TextField
								id="subscriptionType"
								name="subscriptionType"
								select
								label="Subscription Type *"				
								onChange={(e)=>{
									handleSubscriptionTypeChange(e);									
								}}
								value={values.subscriptionType}
								fullWidth
								error={Boolean(touched.subscriptionType && errors.subscriptionType)}
								helperText={touched.subscriptionType && errors.subscriptionType}
								// {...getFieldProps('subscriptionType')}
							>
								{subscriptionTypeList.map((option) => (
									<MenuItem key={option.id} value={option.id}>
										{option.subscription_name}
									</MenuItem>
								))}
							</TextField>
						</Grid>		
						<Grid item xs={12} sm={12} md={12}>
							<TextField
								id="device_type"
								select
								label="Device Type *"
								fullWidth								
								error={Boolean(touched.device_type && errors.device_type)}
								helperText={touched.device_type && errors.device_type}
								value={values?.device_type === "Both" ? "All" : values?.device_type }
								{...getFieldProps('device_type')}
							>
								{DeviceTypeArr?.map((option) => (
									// disabled={!(option.value==="all")}
									<MenuItem key={option.value} value={option.value} >
										{option.label}
									</MenuItem>
								))}
							</TextField>
						</Grid>	
						{
							values.subscriptionType !== "" && 							
							<Grid item xs={12} sm={12} md={12}>
								<Card variant="outlined">
									<CardContent>
										<Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
											{description.subscription_name}<br/>
											&#8377; {(Math.round(description.subscription_fee * 100) / 100).toFixed(2)}<br/>
											{description.subscription_desc}<br/>
											{`${dayjs().format('DD/MM/YYYY')} to ${dayjs().add(description.subscription_validity_days, 'day').format('DD/MM/YYYY')}`}<br/>
										</Typography>		
									</CardContent>		
								</Card>
							</Grid>
						}						
					</Grid>
				</DialogContent>
				<DialogActions sx={{ justifyContent: 'end', pr: '25px' }}>
					<Button
						variant="outlined"
						onClick={props.onClose}
						sx={{
							minWidth: '100px',
							color: (theme) => theme.palette.text.darkGrey,
							borderColor: (theme) => theme.palette.text.darkGrey,
						}}
					>
						Cancel
					</Button>
					<Button variant="contained" onClick={formik.handleSubmit} style={{ minWidth: '100px' }}>
						Save
					</Button>
				</DialogActions>
			</Dialog>
		</>
	); // END return
} // END SubscriptionDialogBox
