import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { styled } from '@mui/material/styles';
import { sentenceCase } from 'change-case';
// @mui
import {
  Autocomplete,
  Chip,
  DoneIcon,
  DeleteIcon,
  Paper,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputLabel,
  Avatar,
  CircularProgress,
  Backdrop,
  Stack,
  Checkbox,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { GetProductById } from '../../actions/ProductActions';
import { ShowLoader, ShowUploadLoader } from '../../actions/CommonAction';
import ViewImageList from '../image-upload/ViewImageList';
import Iconify from '../iconify/Iconify';
import { GetCategoryForDropdown } from '../../actions/CategoriesAction';
import { GetFileByFolderName } from '../../actions/UploadActions';
import { Months } from '../common/constants';
// ----------------------------------------------------------------------

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 12,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: 'green',
  color: '#FFFFFF',
  cursor: 'pointer',
  width: 34,
  height: 34,
  marginLeft: 5,
}));

export default function ViewProductDialog(props) {
  /* START Configure Data On Page */
  const dispatch = useDispatch();
  const { data } = props;
  // console.log('data on view ', data);
  const [tagsData, setTagsData] = React.useState([{ id: 0, tag_name: 'No Tag Available' }]);
  const [upload, setUpload] = useState([]);
  const [showProduct, setShowProduct] = useState(false);
  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const [showAudioLoader, setShowAudioLoader] = useState(false);
  let fileStrArr = [];
  let productMediaFolderName = '';
  const chkBoxValue = true;
  const labelCheckBox = {
    inputProps: {
      'aria-label': 'controlled',
    },
  }; // END labelCheckBox
  let audioProductList = [];

  const { productById, showLoader, categoriesForDropdown, showUploadLoader, fileByFolder } = useSelector((state) => ({
    productById: state.products.productById,
    showLoader: state.common.showLoader,
    categoriesForDropdown: state.categories.categoriesForDropdown,
    showUploadLoader: state.common.showUploadLoader,
    fileByFolder: state.upload.fileByFolder,
  })); // END useSelector
  
  // console.log('productByIdinview', productById);

  if (productById?.product_media_type === 'MPG') {
    if (productById?.product_mpg.length > 0) {
      fileStrArr = productById?.product_mpg?.[0]?.file_path.split('/');
      audioProductList = productById?.product_mpg.map((option) => ({
        filePath: option.file_name,
        fileName: option.file_name,
        is_premium: option.is_premium,
      }));
    } else if (fileByFolder?.mp3_files.length > 0) {
      fileStrArr = fileByFolder?.mp3_files[0]?.split('/');
      audioProductList = fileByFolder?.mp3_files.map((option) => ({
        filePath: option,
        fileName: option,
        is_premium: 0,
      }));
    }
    if (fileStrArr.length > 0) {
      productMediaFolderName = fileStrArr.slice(0, -1).toString().replaceAll(',', '/');
    }
  }
  // const viewFile = (url) => {
  //     const URL = `${process.env.REACT_APP_BASE_URL}/get-file/${url}`
  //     if(URL){
  //         window.open(URL, "_blank");
  //     }
  // }

  /* END Configure Data On Page */

  /* START Use Effect */

  React.useEffect(() => {
    dispatch(GetCategoryForDropdown());
  }, []); // END React.useEffect

  React.useEffect(() => {
    // console.log(
    //   'use effect data',
    //   data,
    //   data?.product_tag_references
    //     ?.map((item, index) =>
    //       item.product_tag.map((innerItem, innerIndex) => ({ id: innerItem.id, tag_name: innerItem.tag_name }))
    //     )
    //     .flat()
    // );
    if (data) {
      dispatch(GetProductById(data.id));
      dispatch(ShowLoader(true));
      setTagsData(
        data?.product_tag_references
          ?.map((item, index) =>
            item.product_tag.map((innerItem, innerIndex) => ({ id: innerItem.id, tag_name: innerItem.tag_name }))
          )
          .flat()
      );
    }
  }, [data]); // END React.useEffect

  const firstRun = React.useRef(true);
  React.useEffect(() => {
    // console.log(
    //   'use effect productById',
    //   productById,
    //   productById?.product_tag_references
    //     ?.map((item, index) =>
    //       item.product_tag.map((innerItem, innerIndex) => ({ id: innerItem.id, tag_name: innerItem.tag_name }))
    //     )
    //     .flat()
    // );
    if (firstRun.current) {
      firstRun.current = false;
      return;
    }
    if (productById?.product_media_type === 'MPG') {
      if (productById?.product_mpg.length !== 0) {
        // dispatch(GetFileByFolderName({"folder_name":productById?.product_mpg[0].file_path}));
        dispatch(GetFileByFolderName({ folder_name: productMediaFolderName.trim() }));
      }
    }
    setShowProduct(true);
    dispatch(ShowLoader(false));
  }, [productById]); //  END React.useEffect

  /* END Use Effect */

  /* START Action Function */
  const viewFile = async (url) => {
    // console.log('url', url);
    if (productById?.product_media_type === 'MPG') {
      const URL = `${process.env.REACT_APP_BASE_URL}/api/get-folder-file-by-path?media_file=${url}`;
      setShowAudioLoader(true);
      fetch(URL, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
        .then((response) => response.blob())
        .then((blob) => {
          // RETRIEVE THE BLOB AND CREATE LOCAL URL
          const _url = window.URL.createObjectURL(blob);
          setShowAudioLoader(false);
          window.open(_url, '_blank').focus(); // window.open + focus
        })
        .catch((err) => {
          console.log(err);
          setShowAudioLoader(false);
        });
    } else {
      setShowAudioLoader(true);
      const URL = `${process.env.REACT_APP_BASE_URL}/api/get-pdf-file/${url}`;
      fetch(URL, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
        .then((response) => response.blob())
        .then((blob) => {
          // RETRIEVE THE BLOB AND CREATE LOCAL URL
          const _url = window.URL.createObjectURL(blob);
          setShowAudioLoader(false);
          window.open(_url, '_blank').focus(); // window.open + focus
        })
        .catch((err) => {
          console.log(err);
          setShowAudioLoader(false);
        });
    }
  }; // END viewFile

  const findCategoryNameById = (id) => {
    const found = categoriesForDropdown.find((e) => e.id === id);
    if (found) {
      return found.category_name;
    }
    return null;
  }; // END findCategoryNameById

  /* END Action Function */

  return (
    <>
      <Dialog
        open={props.open}
        // onClose={props.onClose}
        className="dialog-box-section"
      >
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={showAudioLoader}>
          <CircularProgress color="primary" />
        </Backdrop>
        <BootstrapDialogTitle id="customized-dialog-title" onClose={props.onClose}>
          Product
        </BootstrapDialogTitle>
        <DialogContent dividers>
          {showLoader ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '400px',
                width: '400px',
              }}
            >
              <CircularProgress style={{ color: '#FFA728' }} />
            </div>
          ) : (
            <>
              <Grid container spacing={2}>
                {/*
                                    <Grid item xs={12}>
                                        <InputLabel sx={{mb:"5px",fontWeight:600}}>
                                            Banner Image
                                        </InputLabel>
                                        <Grid item xs={12}>
                                    
                                        {upload?.map((value,index)=>{
                                            return  <Avatar alt="Travis Howard" variant='circle' src={value.url} sx={{ width: 100, height: 100 }} />
                                        })}
                                        </Grid>
                                    </Grid> 
                                */}
                <Grid item xs={12} sm={12} md={12}>
                  <InputLabel sx={{ mb: '5px', fontWeight: 600 }}>Category</InputLabel>
                  <TextField
                    id="DisplayStatus"
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                    value={findCategoryNameById(productById?.category_id)}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <InputLabel sx={{ mb: '5px', fontWeight: 600 }}>Product Name</InputLabel>
                  <TextField
                    id="outlined-name-input"
                    type="text"
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                    value={productById?.product_name}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel sx={{ mb: '5px', fontWeight: 600 }}>Short Description</InputLabel>
                  <TextField
                    id="outlined-name-input"
                    InputProps={{
                      readOnly: true,
                    }}
                    type="text"
                    fullWidth
                    value={productById?.short_descripton}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel sx={{ mb: '5px', fontWeight: 600 }}>Long Description</InputLabel>
                  <TextField
                    multiline
                    rows={2}
                    id="outlined-name-input"
                    InputProps={{
                      readOnly: true,
                    }}
                    type="text"
                    fullWidth
                    value={productById?.long_descripton}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <InputLabel sx={{ mb: '5px', fontWeight: 600 }}>Price(₹)</InputLabel>
                  <TextField
                    id="productId"
                    name="status"
                    InputProps={{
                      readOnly: true,
                    }}
                    value={productById?.price}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <InputLabel sx={{ mb: '5px', fontWeight: 600 }}>Rating</InputLabel>
                  <TextField
                    id="productId"
                    name="status"
                    InputProps={{
                      readOnly: true,
                    }}
                    value={productById?.rating}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <InputLabel sx={{ mb: '5px', fontWeight: 600 }}>Sequence</InputLabel>
                  <TextField
                    id="outlined-name-input"
                    type="number"
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                    value={productById.sequence}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <InputLabel sx={{ mb: '5px', fontWeight: 600 }}>Is Display on Homepage ?</InputLabel>
                  <TextField
                    id="DisplayStatus"
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                    value={productById?.is_display_on_homepage === 1 ? 'Yes' : 'No'}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <InputLabel sx={{ mb: '5px', fontWeight: 600 }}>Status</InputLabel>
                  <TextField
                    id="ActiveStatus"
                    name="status"
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                    value={productById?.is_active === 1 ? 'Active' : 'Inactive'}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <InputLabel sx={{ mb: '5px', fontWeight: 600 }}>Is New Product?</InputLabel>
                  <TextField
                    id="DisplayStatus"
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                    value={productById?.is_new_product === 1 ? 'Yes' : 'No'}
                  />
                </Grid>
                {productById?.is_new_product === 1 && (
                  <Grid item xs={12} sm={12} md={12}>
                    <InputLabel sx={{ mb: '5px', fontWeight: 600 }}>Expiry Date Of New Product</InputLabel>
                    <TextField
                      id="DisplayStatus"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      value={productById?.new_product_expiry_date}
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={12} md={12}>
                  <InputLabel sx={{ mb: '5px', fontWeight: 600 }}>Tags</InputLabel>
                  <Autocomplete
                    multiple
                    // options={tagsData.map((option) => option.title)}
                    options={productById?.product_tag_references
                      ?.map((item, index) =>
                        item.product_tag.map((innerItem, innerIndex) => ({
                          id: innerItem.id,
                          tag_name: innerItem.tag_name,
                        }))
                      )
                      .flat()}
                    defaultValue={productById?.product_tag_references
                      ?.map((item, index) =>
                        item.product_tag.map((innerItem, innerIndex) => ({
                          id: innerItem.id,
                          tag_name: innerItem.tag_name,
                        }))
                      )
                      .flat()}
                    getOptionLabel={(option) => option.tag_name}
                    disabled
                    // filterSelectedOptions
                    // freeSolo
                    // limitTags={2}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip variant="outlined" label={option.tag_name} {...getTagProps({ index })} />
                      ))
                    }
                    renderInput={(params) => <TextField fullWidth readOnly variant="outlined" {...params} />}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <InputLabel sx={{ mb: '10px', fontWeight: 600 }}>Product Image</InputLabel>
                  <ViewImageList imageArray={productById?.product_image} />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <InputLabel sx={{ mb: '5px', fontWeight: 600 }}>Product Media Type</InputLabel>
                  <TextField
                    id="productId"
                    name="status"
                    InputProps={{
                      readOnly: true,
                    }}
                    value={productById?.product_media_type === 'MPG' ? 'MP3' : productById?.product_media_type}
                    fullWidth
                  />
                </Grid>
                {findCategoryNameById(productById?.category_id) === 'eMagazine' ? (
                  <>
                    <Grid item container xs={12} sm={12} md={12} spacing={3}>
                      <Grid item xs={4}>
                        <InputLabel sx={{ mb: '8px', fontWeight: 600 }}>Month Of Magazine</InputLabel>
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          id="productId"
                          name="status"
                          InputProps={{
                            readOnly: true,
                          }}
                          value={
                            dayjs(productById?.month_of_magazine, 'YYYY-MM-DD').format('MMM')
                            // Months.filter((item)=> item.value === String(new Date(productById?.month_of_magazine).getMonth()+ 1).padStart(2, '0'))[0].label || ''
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          id="productId"
                          name="status"
                          InputProps={{
                            readOnly: true,
                          }}
                          value={String(new Date(productById?.month_of_magazine).getFullYear()) || ''}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                    <Grid xs={12} sm={12} md={12} item container spacing={3}>
                      <Grid item xs={4}>
                        <InputLabel sx={{ mb: '8px', fontWeight: 600 }}>Release Date</InputLabel>
                      </Grid>
                      <Grid item xs={8}>
                        <TextField
                          id="productId"
                          name="status"
                          InputProps={{
                            readOnly: true,
                          }}
                          value={dayjs(productById?.release_date_of_magazine).format('DD-MM-YYYY')}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </>
                ) : null}
                {
                  // productById?.product_media_type !== 'VIDEO' ?
                  <Grid item xs={12} sm={12} md={12}>
                    <InputLabel sx={{ mb: '5px', fontWeight: 600 }}>Subscription Type</InputLabel>
                    <TextField
                      id="subscription"
                      name="subscription"
                      InputProps={{
                        readOnly: true,
                      }}
                      value={
                        productById?.subscription_type !== undefined && productById?.subscription_type !== null
                          ? sentenceCase(productById?.subscription_type)
                          : 'free'
                      }
                      fullWidth
                    />
                  </Grid>
                }
                {productById?.product_media_type === 'PDF' && productById?.subscription_type === 'freemium' ? (
                  <>
                    <Grid item xs={12} sm={12} md={12}>
                      <InputLabel sx={{ mb: '5px', fontWeight: 600 }}>No. Of Pages Allowed</InputLabel>
                      <TextField
                        id="pages"
                        name="Pages Allowed"
                        InputProps={{
                          readOnly: true,
                        }}
                        value={productById?.product_pdf[0]?.pdf_page_count}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <InputLabel sx={{ mb: '10px', fontWeight: 600 }}>PDF</InputLabel>
                      <StyledAvatar onClick={() => viewFile(productById?.product_pdf[0]?.id)}>
                        <Iconify icon={'eva:eye-outline'} sx={{ width: 22, height: 22, border: '3px solid blue' }} />
                      </StyledAvatar>
                    </Grid>
                  </>
                ) : productById?.product_media_type === 'MPG' ? (
                  <>
                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        id="productMediaUrl"
                        name="productMediaUrl"
                        label="MP3 Folder Name*"
                        type="text"
                        fullWidth
                        // value={productById?.product_mpg?.[0]?.file_path}
                        value={productMediaFolderName}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    {showUploadLoader?.loading && showUploadLoader.type === 'productMedia' ? (
                      <Grid item xs={12} sm={12} md={12}>
                        <CircularProgress style={{ color: '#FFA728' }} />
                      </Grid>
                    ) : audioProductList?.length === 0 ? (
                      <Grid item xs={12} sm={12} md={12}>
                        <div className="errorMessage">MP3 folder not found</div>
                      </Grid>
                    ) : (
                      audioProductList?.map((value, index) => (
                        // eslint-disable-next-line react/jsx-key
                        <Stack
                          key={index + 1}
                          direction="row"
                          spacing={2}
                          alignItems="center"
                          style={{ width: '100%', marginTop: '15px', marginLeft: '15px' }}
                        >
                          <Grid item xs={6} sm={6} md={6}>
                            <InputLabel sx={{ mb: '10px', fontWeight: 600 }}>
                              {value.fileName.split('/').pop().split('.')[0]}
                            </InputLabel>
                          </Grid>
                          <Grid item xs={4} sm={4} md={4}>
                            <StyledAvatar onClick={() => viewFile(value.filePath)}>
                              <Iconify icon={'eva:eye-outline'} sx={{ width: 22, height: 22 }} />
                            </StyledAvatar>
                          </Grid>
                          <Grid item xs={4} sm={4} md={4}>
                            <Checkbox {...labelCheckBox} disabled checked={value.is_premium === 1} />
                          </Grid>
                        </Stack>
                      ))
                    )}
                  </>
                ) : productById?.product_media_type === 'VIDEO' ? (
                  productById?.product_video?.map((value, index) => (
                    // eslint-disable-next-line react/jsx-key
                    <Grid item xs={12} sm={12} md={12} key={index + 1}>
                      <InputLabel sx={{ mb: '10px', fontWeight: 600 }}>{value.file_name}</InputLabel>
                      <StyledAvatar
                        onClick={() =>
                          window.open(`https://www.youtube.com/watch?v=${value.file_path}`, '_blank').focus()
                        }
                      >
                        <Iconify icon={'eva:eye-outline'} sx={{ width: 22, height: 22 }} />
                      </StyledAvatar>
                    </Grid>
                  ))
                ) : (
                  // <Grid item xs={12} sm={12} md={12}>
                  //     <InputLabel sx={{mb:"10px",fontWeight:600}}>
                  //         Video
                  //     </InputLabel>
                  //     <StyledAvatar
                  //         onClick={()=>viewFile(window.open(`https://www.youtube.com/watch?v=${productById?.product_video[0]?.file_path}`, "_blank").focus())}
                  //         >
                  //         <Iconify
                  //             icon={'eva:eye-outline'}
                  //             sx={{ width: 22, height: 22}}
                  //         />
                  //     </StyledAvatar>
                  // </Grid>:
                  <Grid item xs={12} sm={12} md={12}>
                    <InputLabel sx={{ mb: '10px', fontWeight: 600 }}>{productById?.product_media_type}</InputLabel>
                    <StyledAvatar
                      onClick={() => {
                        const id =
                          productById?.product_media_type === 'PDF'
                            ? productById?.product_pdf[0]?.id
                            : productById?.product_mpg[0]?.id;
                        viewFile(id);
                      }}
                    >
                      <Iconify icon={'eva:eye-outline'} sx={{ width: 22, height: 22}} />
                    </StyledAvatar>
                  </Grid>
                )}
              </Grid>
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  ); // END return
} // END ViewProductDialog
