import JWTServer from "../api/withJWTServer";
import { SetNewAlert } from "./AlertActions";
import { HandleExceptionWithSecureCatch, HandleManualException } from "./CombineCatch";
import { CREATE_PRODUCT, DELETE_PRODUCT, GET_ALL_PRODUCT, GET_PRODUCT_BY_ID, UPDATE_PRODUCT } from "./Types";

const GetAllProducts = (page,limit,searchValue="",filterValue=0,statusValue) => async (dispatch) => {
    try {
      let url = `/api/products?page=${page}&limit=${limit}&search=${searchValue}&status=1`;
      if(filterValue !== 0)
        url += `&category_id=${filterValue}`;
      const response = await JWTServer.get(url);
      if(response.data.meta.status===200){
        dispatch({
          type: GET_ALL_PRODUCT,
          payload: response.data,
        });
      }
      else {
        dispatch(HandleManualException(response.data))
      }

      
    } catch (e) {
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };

  const GetProductById = (id) => async (dispatch) => {
    try {
      const response = await JWTServer.get(`/api/get-product-by-id/${id}`);  
      // console.log("response from action getproductbyid",response)
      if(response.data.meta.status===200){
        dispatch({
          type: GET_PRODUCT_BY_ID,
          payload: response.data,
        });
      }
      else {
        dispatch(HandleManualException(response.data))
      }
  
      
    } catch (e) {
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };

  const AddProduct = (params) => async (dispatch) => {
    try {
      const response = await JWTServer.post("/api/create-product",params);
      
      if(response.data.meta.status===200){
        dispatch({
          type: CREATE_PRODUCT,
          payload: response.data,
        });
        dispatch(SetNewAlert({
          msg: response.data.meta.message,
          alertType: "success",
        }));
      }
      else {
        dispatch(HandleManualException(response.data))
      }
      
    } catch (e) {
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };

  const EditProduct = (params,productId) => async (dispatch) => {
    try {
      const response = await JWTServer.put(`/api/update-product/${productId}`,params);
      if(response.data.meta.status===200){
        dispatch({
          type: UPDATE_PRODUCT,
          payload: response.data,
        });
        dispatch(SetNewAlert({
          msg: response.data.meta.message,
          alertType: "success",
        }));
      }
      else {
        dispatch(HandleManualException(response.data))
      }
      
    } catch (e) {
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };

  const DeleteProduct = (params) => async (dispatch) => {
    try {
      const response = await JWTServer.delete(`/api/delete-product/${params}`);
      if(response.data.meta.status===204){
        dispatch({
          type: DELETE_PRODUCT,
          payload: response.data,
        });
        dispatch(SetNewAlert({
          msg: response.data.meta.message,
          alertType: "success",
        }));
      }
      else {
        dispatch(HandleManualException(response.data))
      }
      
    } catch (e) {
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };


  export {
     GetAllProducts,
     AddProduct,
     EditProduct,
     DeleteProduct,
     GetProductById
  }