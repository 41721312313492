import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { styled } from '@mui/material/styles';
import * as Yup from 'yup';
import _, { replace } from 'lodash';
// @mui
import {
  Switch,
  FormControlLabel,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Button,
  MenuItem,
  IconButton,
  InputLabel,
  Avatar,
  Stack,
  CircularProgress,
  Backdrop,
  Checkbox,
  Typography,
  Box,
  CardContent,
  Card,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import PropTypes from 'prop-types';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { UploadProductImages, UploadProductFiles, GetFileByFolderName } from '../../actions/UploadActions';
import { AddProduct, EditProduct, GetProductById } from '../../actions/ProductActions';
import { ShowLoader, ShowUploadLoader } from '../../actions/CommonAction';
import Iconify from '../iconify/Iconify';
import { SetNewAlert } from '../../actions/AlertActions';
import { DisplayStatusArray, SubscriptionType, Months, MaxDateValue, MinDateValue } from '../common/constants';
import { AddFeed, EditFeed, GetFeedById } from '../../actions/FeedAction';

// ----------------------------------------------------------------------
const MediaType = [
  {
    value: 'NONE',
    label: 'NONE',
  },
  {
    value: 'IMAGE',
    label: 'IMAGE',
  },
  {
    value: 'VIDEO',
    label: 'VIDEO',
  },
  // {
  //   value: 'MPG',
  //   label: 'MP3',
  // },
];
// --------------Style-------------
const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 12,
            top: 8,
            color: 'black',
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: 'green',
  color: '#FFFFFF',
  cursor: 'pointer',
  width: 34,
  height: 34,
  marginLeft: 5,
}));
const feedValidationSchema = Yup.object().shape({
  title: Yup.string()
    .trim()
    .min(3, 'Minimum 3 characters required')
    .max(50, 'Maximum 50 characters required')
    .required('Feed title is required.'),
  description: Yup.string()
    .trim()
    .min(3, 'Minimum 3 characters required')
    .max(100, 'Maximum 100 characters required')
    .required('Description is required.'),
  sequence: Yup.number()
    .positive('Sequence must be a positive number')
    .typeError('Sequence must be a positive number')
    .required('Sequence is required')
    .min(1, 'Sequence must be 1 to 99999')
    .max(99999, 'Sequence must be 1 to 99999'),
  feedMediaType: Yup.string().required('Feed Media Type is required'),

  productMediaUrl: Yup.string().required('Image Folder required'),
});

export default function FeddDialogBox(props) {
  const dispatch = useDispatch();
  const { data } = props;
  console.log('data in edit', data);
  const [upload, setUpload] = useState([]);
  console.log('upload', upload);
  const [fileUrl, setFileUrl] = useState('');
  const [changeMp3, setChangeMp3] = useState(false);
  // const [changeImg, setChnageImg] = useState(false);
  // const [showProductList, setShowProductList] = useState(false);
  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [isFolderNameAvailable, setIsFolderNameAvailable] = useState(false);
  const [showAudioLoader, setShowAudioLoader] = useState(false);
  const [videoArray, setVideoArray] = useState([
    {
      title: data ? data.feed_video[0]?.file_name : '',
      videoId: data ? data.feed_video[0]?.file_path : '',
      titleError: '',
      videoIdError: '',
    },
  ]);

  const [uploadedPDFPageCount, setUploadedPDFPageCount] = useState(0);

  // let audioProductList = [];
  const PDFProductList = [];
  const [tagData, setTagData] = useState([]);
  // console.log("tagData",tagData)
  const [tagValidation, setTagValidation] = useState(false);
  const [arrConstCategoryType, setArrConstCategoryType] = useState([]);
  const [isThisShortVideoCat, setIsThisShortVideoCat] = useState(false);
  const [mediaFolderName, setMediaFolderName] = useState(
    data?.feed_image?.[0]?.file_path ? data.feed_image[0]?.file_path.split('/').slice(0, -1).join('/') : ''
  );
  console.log('mediaFolderName', mediaFolderName);
  const [imgProductList, setImgPoductList] = useState([]);
  console.log('imgProductList', imgProductList);
  const [audioProductList, setAudioProductList] = useState([]);
  const [changeImg, setChangeImg] = useState(false);
  const [checkedIndex, setCheckedIndex] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [mediaTypeChanged, setMediaTypeChanged] = useState(false);
  const [blobURL, setBlobURL] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);

  const {
    addFeedLog,
    editFeedLog,
    tagsForDropdown,
    productImage,
    uploadProductImageLog,
    productFile,
    uploadProductFileLog,
    feedById,
    showLoader,
    showUploadLoader,
    fileByFolder,
  } = useSelector((state) => ({
    addFeedLog: state.feeds.addFeedLog,
    editFeedLog: state.feeds.editFeedLog,
    productImage: state.upload.productImage,
    uploadProductImageLog: state.upload.uploadProductImageLog,
    productFile: state.upload.productFile,
    uploadProductFileLog: state.upload.uploadProductFileLog,
    feedById: state.feeds.feedById,
    showLoader: state.common.showLoader,
    showUploadLoader: state.common.showUploadLoader,
    fileByFolder: state.upload.fileByFolder,
  }));
  console.log('feedById', showUploadLoader);
  const label = { inputProps: { 'aria-label': 'Switch demo' } };

  React.useEffect(() => {
    if (data) {
      dispatch(GetFeedById(data.id));
    }
  }, [data]);

  //  from here we are set data----------------
  useEffect(() => {
    if (data && data?.feed_media_type === 'IMAGE') {
      dispatch(ShowUploadLoader({ loading: true, type: 'productMedia' }));
      dispatch(
        GetFileByFolderName({
          folder_name: data.feed_image[0]?.file_path
            ? data.feed_image[0]?.file_path.split('/').slice(0, -1).join('/')
            : '',
        })
      );
      setIsFolderNameAvailable(true);
      setChangeImg(true);
      setFieldValue(
        'productMediaUrl',
        data.feed_image[0]?.file_path ? data.feed_image[0]?.file_path.split('/').slice(0, -1).join('/') : ''
      );
    }
  }, [data]);

  const fileByFolderRun = React.useRef(true);
  React.useEffect(() => {
    if (fileByFolderRun.current) {
      fileByFolderRun.current = false;
      return;
    }
    dispatch(ShowUploadLoader({ loading: false, type: '' }));

    if (fileByFolder?.image_files?.length > 0) {
      const productMediaFolderName = fileByFolder?.image_files[0]
        ?.split('/')
        .slice(0, -1)
        .toString()
        .replaceAll(',', '/');
      // setMediaFolderName(productMediaFolderName);
      const IMGProductList = JSON.parse(
        JSON.stringify(
          fileByFolder?.image_files.map((option) => ({
            file_name: option,
            file_path: option,

            // is_premium: 0,
          }))
        )
      );
      setImgPoductList(IMGProductList);

      // condition to set selected image
      if (data && !mediaTypeChanged) {
        fileByFolder?.image_files.map((option, index) => {
          if (option === data.feed_image[0]?.file_path) {
            setCheckedIndex(index);
          }
          return null;
        });
      }
    } else if (fileByFolder?.mp3_files.length > 0) {
      const productMediaFolderName = fileByFolder?.mp3_files[0]
        ?.split('/')
        .slice(0, -1)
        .toString()
        .replaceAll(',', '/');
      setMediaFolderName(productMediaFolderName);
      const audioProduct = JSON.parse(
        JSON.stringify(
          fileByFolder?.mp3_files.map((option) => ({
            file_name: option,
            file_path: option,
            is_premium: 0,
          }))
        )
      );
      setAudioProductList(audioProduct);
    } else {
      setImgPoductList([]);
      setAudioProductList([]);
    }
  }, [fileByFolder]); // END useEffect

  const getProductRun = React.useRef(true);

  React.useEffect(() => {
    if (getProductRun.current) {
      getProductRun.current = false;
      return;
    }
    dispatch(ShowLoader(false));

    if (feedById) {
      if (feedById?.product_tag_references?.length > 0) {
        setTagData(
          feedById?.product_tag_references
            ?.map((item, index) => item.product_tag.map((innerItem, innerIndex) => innerItem.id))
            .flat() || []
        );
      }
      // setShowProductList(true)
      if (feedById?.feed_image?.length !== 0) {
        setUpload(feedById?.feed_image);
        setIsImageUploaded(true);
        setFieldValue('image', feedById?.feed_image);
      } else {
        setIsImageUploaded(false);
      }
      if (feedById?.feed_media_type === 'IMAGE') {
        if (feedById?.feed_image.length !== 0) {
          setIsFileUploaded(true);
          setFileUrl(feedById?.feed_image[0]?.file_path);
          setFieldValue('productMediaUrl', feedById?.feed_image[0].file_path?.split('/').pop());
          const productMediaFolderName = feedById?.feed_image[0]?.file_path
            ?.split('/')
            .slice(0, -1)
            .toString()
            .replaceAll(',', '/');
          setMediaFolderName(productMediaFolderName);
          console.log('productMediaFolderName', productMediaFolderName);
          const PDFProductList = JSON.parse(JSON.stringify(feedById?.feed_image));
          // setImgPoductList(ImgProductList);
          dispatch(GetFileByFolderName({ folder_name: productMediaFolderName }));
          dispatch(ShowUploadLoader({ loading: true, type: 'productMedia' }));
          setIsFolderNameAvailable(true);
          // setChnageImg(true);
        } else {
          setIsFileUploaded(true);
          setIsFolderNameAvailable(true);
        }
      } else if (feedById.product_media_type === 'MPG') {
        if (feedById?.product_mpg.length !== 0) {
          setIsFileUploaded(true);
          setFileUrl(feedById?.product_mpg[0].file_path);
          setFieldValue('productMediaUrl', feedById?.product_mpg[0].file_path);
          // dispatch(GetFileByFolderName({"folder_name":feedById?.product_mpg[0].file_path}))
          // eslint-disable-next-line react-hooks/exhaustive-deps
          const productMediaFolderName = feedById?.product_mpg[0].file_path
            ?.split('/')
            .slice(0, -1)
            .toString()
            .replaceAll(',', '/');
          setMediaFolderName(productMediaFolderName);
          const audioProduct = JSON.parse(JSON.stringify(feedById?.product_mpg));
          setAudioProductList(audioProduct);
          dispatch(GetFileByFolderName({ folder_name: productMediaFolderName }));
          dispatch(ShowUploadLoader({ loading: true, type: 'productMedia' }));
          setIsFolderNameAvailable(true);
        } else {
          setIsFileUploaded(false);
          setIsFolderNameAvailable(true);
        }
      } else if (feedById.feed_media_type === 'VIDEO') {
        if (feedById?.feed_video.length !== 0) {
          setIsFileUploaded(true);
          setFileUrl(feedById?.feed_video[0].file_path);
          setFieldValue('productMediaUrl', 'BsolkMHI0Ok');
          const newVideoArray = [];
          feedById?.feed_video?.map((value, index) => {
            const infoToAdd = {
              title: value.file_name,
              videoId: value.file_path,
              titleError: '',
              videoIdError: '',
            };
            newVideoArray.push(infoToAdd);
            return null;
          });
          setVideoArray(newVideoArray);
        } else {
          setIsFileUploaded(false);
        }
      }
    }
  }, [feedById]); // END useEffect

  const firstRun = React.useRef(true);

  React.useEffect(() => {
    if (firstRun.current) {
      firstRun.current = false;
      return;
    }
    props.onClose();
  }, [addFeedLog, editFeedLog]); // END useEffect

  const secondRun = React.useRef(true);
  React.useEffect(() => {
    if (secondRun.current) {
      secondRun.current = false;
      return;
    }
    const productImageArray = upload;
    // productImage?.map((value, index) => {
    //   productImageArray.push({ url: value });
    //   return null;
    // });
    productImageArray?.push({ file_path: productImage.url });
    setUpload(productImageArray);
    dispatch(ShowUploadLoader({ loading: false, type: '' }));
    setIsImageUploaded(true);
  }, [uploadProductImageLog]); // END useEffect

  const uploadFileRun = React.useRef(true);
  React.useEffect(() => {
    if (uploadFileRun.current) {
      uploadFileRun.current = false;
      return;
    }
    setFileUrl(productFile?.url);
    setFieldValue('productMediaUrl', productFile?.url?.split('/').pop());
    setIsFileUploaded(true);
    dispatch(ShowUploadLoader({ loading: false, type: '' }));
  }, [uploadProductFileLog]); // END useEffect

  /* END Use Effects */

  /* START Handle Change */
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [mediaType, setMediaType] = useState('');
  const handleMediaTypeChange = (type) => {
    setMediaType(type);
    setSelectedMedia(/* Selected media data */);
    if (type === 'NONE') {
      setFieldValue('productMediaUrl', 'none feed');
    } else if (type === 'VIDEO') {
      setFieldValue('productMediaUrl', 'none feed');
    }
  };
  // useEffect(() => {
  //   setUpload(data?.feed_image);
  // }, [data]);

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const formData = new FormData();
      const ext = e.target.value.split('.').pop();
      if (values.feedMediaType === 'PDF' && ext !== 'pdf') setFieldError('productMediaUrl', 'Only pdf file allowed');
      else {
        /* To get pdf pages */
        const reader = new FileReader();
        reader.readAsBinaryString(e.target.files[0]);
        reader.onloadend = function () {
          setUploadedPDFPageCount(reader.result.match(/\/Type[\s]*\/Page[^s]/g).length);
        };
        formData.append('upload_for', 'Test_Folder');
        formData.append('file', e.target.files[0]);
        // formData.append('category_name', findCategoryNameById(values.category));
        formData.append('product_name', values.productName);
        dispatch(ShowUploadLoader({ loading: true, type: 'productMedia' }));
        dispatch(UploadProductFiles(formData));
        // setUpload(uploadedContent)
        if (e.target.files.length !== 0) {
          setFileUrl(productFile?.url);
          setFieldValue('productMediaUrl', productFile?.url?.split('/').pop());
        } else {
          setFieldValue('productMediaUrl', 'BsolkMHI0Ok');
        }
      }
    }
  }; // END handleFileChange

  const handleVideoIdChange = (e, index) => {
    const previousVideoArray = videoArray;
    const obj = previousVideoArray[index];
    setFieldValue('productMediaUrl', 'BsolkMHI0Ok');
    if (e.target.value === '' && !e.target.value.match(/^[a-zA-Z0-9_-]{11}$/)) {
      obj.videoIdError = 'Please enter valid video Id';
    } else {
      obj.videoIdError = '';
    }
    obj.videoId = e.target.value;
    previousVideoArray[index] = obj;
    setVideoArray(previousVideoArray);
  }; // END handleVideoIdChange

  const handleVideoTitleChange = (e, index) => {
    const previousVideoArray = videoArray;
    console.log('previousVideoArray', previousVideoArray);
    const obj = previousVideoArray[index];
    console.log('obj', obj);

    setFieldValue('productMediaUrl', 'BsolkMHI0Ok');
    if (e.target.value === '') {
      obj.titleError = 'Please enter video title';
    } else {
      obj.titleError = '';
    }

    obj.title = e.target.value;
    previousVideoArray[index] = obj;
    setVideoArray(previousVideoArray);
  };

  /* START Actions Functions */

  const validateVideoList = () => {
    let isValidate = true;
    setVideoArray(
      videoArray.map((value, index) => {
        if (value.title === '' || value.title === undefined) {
          value.titleError = 'Video title is required';
          isValidate = false;
        }
        if (value.videoId === '' || value.videoId === undefined) {
          value.videoIdError = 'Video id is required';
          isValidate = false;
        }
        if (value.videoId !== '' && !value.videoId.match(/^[a-zA-Z0-9_-]{11}$/)) {
          value.videoIdError = 'Please enter valid video Id';
          isValidate = false;
        }

        return value;
      })
    );
    return isValidate;
  }; // END validateVideoList

  const viewFile = async (url) => {
    // console.log("url",url)

    if (values.feedMediaType === 'MPG') {
      const URL = `${process.env.REACT_APP_BASE_URL}/api/get-folder-file-by-path?media_file=${url}`;
      setShowAudioLoader(true);
      fetch(URL, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
        .then((response) => response.blob())
        .then((blob) => {
          // RETRIEVE THE BLOB AND CREATE LOCAL URL
          const _url = window.URL.createObjectURL(blob);
          setShowAudioLoader(false);
          window.open(_url, '_blank').focus(); // window.open + focus
        })
        .catch((err) => {
          console.log(err);
          setShowAudioLoader(false);
        });
    } else {
      const URL = `${process.env.REACT_APP_BASE_URL}/api/get-folder-file-by-path?media_file=${url}`;
      setShowAudioLoader(true);
      fetch(URL, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
        .then((response) => response.blob())
        .then((blob) => {
          // RETRIEVE THE BLOB AND CREATE LOCAL URL
          const _url = window.URL.createObjectURL(blob);
          setShowAudioLoader(false);
          window.open(_url, '_blank').focus(); // window.open + focus
        })
        .catch((err) => {
          console.log(err);
          setShowAudioLoader(false);
        });
    }
  }; // END viewFile

  const initialFormValue = {
    title: data ? data.feed_title : ' ',
    description: data ? data.feed_description : ' ',
    sequence: data ? data.sequence : '',
    isActive: data ? data.is_active : 1,
    is_show_like_btn: data?.is_show_like_btn === 1 && true,
    is_show_share_btn: data?.is_show_share_btn === 1 && true,
    feedMediaType: data ? data.feed_media_type : '',
    feed_video:
      data && data.feed_media_type === 'VIDEO'
        ? [
            {
              title: videoArray[0].title,
              url: videoArray[0].videoId,
            },
          ]
        : [],
    feed_images: data && data.feed_media_type === 'IMAGE' ? data.feed_image[0]?.file_path : [],
    productMediaUrl: data
      ? data.feed_media_type === 'VIDEO'
        ? data.feed_video && data.feed_video.length > 0
          ? data.feed_video[0].file_path
          : ''
        : data.feed_media_type === 'IMAGE'
        ? data.feed_image && data.feed_image.length > 0
          ? data.feed_image[0].file_path
          : ''
        : data.feed_media_type === 'NONE'
        ? 'dummy_url'
        : ''
      : '',
  };

  console.log('initialFormValue', initialFormValue);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialFormValue,
    validationSchema: feedValidationSchema,
    onSubmit: async (value, { setSubmitting }) => {
      console.log('value in submit ', value);

      const reqObj = {
        sequence: value.sequence,
        feed_title: value.title,
        feed_description: value.description,
        feed_media_type: value.feedMediaType,
        feed_media_url:
          value.feedMediaType === 'VIDEO'
            ? [
                {
                  title: videoArray[0].title,
                  url: videoArray[0].videoId,
                },
              ]
            : [],
        feed_images: value.feedMediaType === 'IMAGE' ? [value.feed_images] : [],
        is_show_like_btn: value.is_show_like_btn === true ? 1 : 0,
        is_show_share_btn: value.is_show_share_btn === true ? 1 : 0,
        is_active: value.isActive,
      };

      if (values.feedMediaType === 'VIDEO') {
        console.log('hii');
        const isValid = await validateVideoList();
        if (!isValid) {
          console.log('byy');
          setSubmitting(false);
          return;
        }
      }

      if (data) dispatch(EditFeed(reqObj, data.id));
      else {
        dispatch(AddFeed(reqObj));
        // if (props.hasOwnProperty('onProductInsert'))
        //   setTimeout(() => {
        //     props.onProductInsert(true);
        //   }, 2000);
      }

      // productMediaFolderName = '';
      fileByFolder.length = 0;
      feedById.length = 0;
      // else {
      // 	// dispatch(SetEmptyFolderName())
      // }
    },
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setFieldValue, setFieldError } = formik;
  const videoArrayLength = videoArray.length;

  const getDataRun = React.useRef(true);

  // if (isSubmitting && getDataRun.current) {
  //   getDataRun.current = false;
  //   if (values.feedMediaType === 'VIDEO') {
  //     validateVideoList().then((isValid) => {
  //       if (isValid) {
  //         handleSubmit();
  //       }
  //     });
  //   } else {
  //     handleSubmit();
  //   }
  // }

  // console.log("productMediaFolderName",productMediaFolderName)
  // console.log("dataName",dataName)

  const [previewValues, setPreviewValues] = useState({
    title: data ? data.feed_title : ' ',
    description: data ? data.feed_description : ' ',
    feedMediaType: data ? data.feed_media_type : '',
    productMediaFolderName: '',
    // productMediaUrl: data ? data.feed_video[0]?.file_path : '',
    productMediaUrl: data
      ? data.feed_media_type === 'VIDEO'
        ? data.feed_video && data.feed_video.length > 0
          ? data.feed_video[0].file_path
          : ''
        : data.feed_media_type === 'IMAGE'
        ? data.feed_image && data.feed_image.length > 0
          ? data.feed_image[0].file_path
          : ''
        : ''
      : '',
  });
  console.log('previewValues', previewValues);
  const [showLike, setShowLike] = useState(data?.is_show_like_btn === 1 || false);
  console.log('showLike', showLike);
  const [showShare, setShowShare] = useState(data?.is_show_share_btn === 1 || false);
  const [index, setIndex] = useState();

  const handleLikeToggle = () => {
    setShowLike(!showLike);
    setFieldValue('is_show_like_btn', !showLike);
  };

  const handleShareToggle = () => {
    setShowShare(!showShare);
    setFieldValue('is_show_share_btn', !showShare);
  };

  const getLikeState = () => {
    return showLike ? 'Yes' : 'No';
  };

  const getShareState = () => {
    return showShare ? 'Yes' : 'No';
  };
  // diabled feild
  const [disabled, setDisabled] = useState(false); // State to control the disabled state of the preview card

  const handleDisableToggle = () => {
    setDisabled((prev) => !prev);
  };

  const handleFieldBlur = (fieldName, value) => {
    console.log('handle blur');
    setPreviewValues((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const onImgChange = (e) => {
    setMediaFolderName(e.target.value);
  };
  const handleImgChange = (e) => {
    console.log('e-img', e.target.value);
    setMediaTypeChanged(true);
    if (e.target.value) {
      dispatch(ShowUploadLoader({ loading: true, type: 'productMedia' }));
      dispatch(GetFileByFolderName({ folder_name: e.target.value }));
      setIsFolderNameAvailable(true);
      setChangeImg(true);
    } else {
      setIsFolderNameAvailable(false);
    }
  };

  const handleCheckboxChange = (index, image) => {
    console.log('fejhdgjhe', image);

    if (index === checkedIndex) {
      // Uncheck the checkbox if it's already checked
      setCheckedIndex(-1);
      setBlobURL('');
      setSelectedImage(null);
      setFieldValue('feed_images', '');
    } else {
      // Check the clicked checkbox and uncheck the previously checked one
      setCheckedIndex(index);
      setSelectedImage(image.file_name);
      setFieldValue('feed_images', image.file_name);
      handleFieldBlur('feed_images', image.file_name);

      const URL = `${process.env.REACT_APP_BASE_URL}/api/get-folder-file-by-path?media_file=${image.file_path}`;
      setShowAudioLoader(true);
      fetch(URL, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
        .then((response) => response.blob())
        .then((blob) => {
          // RETRIEVE THE BLOB AND CREATE LOCAL URL
          setBlobURL(blob);
          setShowAudioLoader(false);
        })
        .catch((err) => {
          console.log(err);
          setShowAudioLoader(false);
        });
    }
    setMediaTypeChanged(true);
  };

  return (
    <React.Fragment key={1001}>
      <Box>
        <Dialog
          PaperProps={{
            // Override PaperProps to set custom styles
            style: {
              backgroundColor: 'transparent', // Set background color to transparent
              boxShadow: 'none', // Remove the box shadow
              padding: '0px',
              height: '98vh',
            },
          }}
          open={props.open}
          // onClose={props.onClose}
          // className="dialog-box-section"
          fullWidth // Set fullWidth to true
          maxWidth="lg" // Set maxWidth to adjust the maximum width
        >
          <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={showAudioLoader}>
            <CircularProgress color="primary" />
          </Backdrop>

          <DialogContent>
            <Grid container spacing={1}>
              {/* Form Card */}
              <Grid item xs={12} md={6}>
                <Card>
                  <CardContent>
                    {showLoader ? (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '400px',
                          width: '400px',
                        }}
                      >
                        <CircularProgress style={{ color: '#FFA728' }} />
                      </div>
                    ) : (
                      <Grid item container spacing={2}>
                        <Typography sx={{ color: 'black', marginLeft: '22px', fontWeight: 'bold' }}>
                          Latest Feed
                        </Typography>
                        <DialogTitle sx={{ m: 0 }}>
                          <IconButton
                            aria-label="close"
                            onClick={props.onClose}
                            sx={{
                              position: 'absolute',
                              right: 12,
                              top: 8,
                              color: 'black',
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                          {/* ) : null} */}
                        </DialogTitle>
                        <Grid item xs={12} sm={12} md={12}>
                          <TextField
                            id="Title"
                            name="title"
                            label="Title*"
                            size="small"
                            onChange={(e) => {}}
                            // value={values.title}
                            fullWidth
                            error={Boolean(touched.title && errors.title)}
                            helperText={touched.title && errors.title}
                            {...getFieldProps('title')}
                            onBlur={(e) => {
                              formik.handleBlur(e);
                              handleFieldBlur('title', e.target.value);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            multiline
                            name="description"
                            id="outlined-name-input"
                            label="Description*"
                            inputProps={{ maxLength: 200 }}
                            type="text"
                            fullWidth
                            // size="small"
                            error={Boolean(touched.description && errors.description)}
                            helperText={touched.description && errors.description}
                            {...getFieldProps('description')}
                            onBlur={(e) => {
                              formik.handleBlur(e);
                              handleFieldBlur('description', e.target.value);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <TextField
                            id="sequence"
                            name="sequence"
                            label="Sequence*"
                            size="small"
                            inputProps={{ maxLength: 5 }}
                            type="number"
                            onChange={(e) => {
                              if (e.target.value.length === 6) {
                                return;
                              }
                              setFieldValue('sequence', e.target.value);
                              formik.handleChange(e);
                            }}
                            value={values.sequence}
                            fullWidth
                            error={Boolean(touched.sequence && errors.sequence)}
                            helperText={touched.sequence && errors.sequence}
                            onBlur={(e) => {
                              formik.handleBlur(e);
                              handleFieldBlur('sequence', e.target.value);
                            }}
                            // {...getFieldProps('sequence')}
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12}>
                          <TextField
                            id="ActiveStatus"
                            select
                            name="status"
                            label="Is Active?"
                            fullWidth
                            size="small"
                            error={Boolean(touched.isActive && errors.isActive)}
                            helperText={touched.isActive && errors.isActive}
                            {...getFieldProps('isActive')}
                            onBlur={(e) => handleFieldBlur('isActive', e.target.value)}
                          >
                            {DisplayStatusArray.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12}>
                          <TextField
                            id="feedMediaType"
                            select
                            size="small"
                            name="feedMediaType"
                            label="Media Type*"
                            fullWidth
                            value={values.feedMediaType}
                            onChange={(e) => {
                              handleMediaTypeChange(e.target.value);
                              formik.handleChange(e);
                            }}
                            error={Boolean(touched.feedMediaType && errors.feedMediaType)}
                            helperText={touched.feedMediaType && errors.feedMediaType}
                            // {...getFieldProps('feedMediaType')}
                            onBlur={(e) => {
                              formik.handleBlur(e);
                              handleFieldBlur('feedMediaType', e.target.value);
                            }}
                          >
                            {MediaType.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        {/* ===================Media start===================== */}
                        {values.feedMediaType === 'VIDEO' ? (
                          <React.Fragment key={2001}>
                            {videoArray?.map((value, index) => (
                              // eslint-disable-next-line react/jsx-key
                              <Grid item key={index + 1} xs={12} sm={12} md={12} lg={12}>
                                <Stack
                                  style={{ marginLeft: '15px' }}
                                  direction="row"
                                  spacing={2}
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={12}
                                >
                                  <Grid item xs={7} sm={8} md={8} lg={8}>
                                    <TextField
                                      id="videoTitle"
                                      name="videoTitle"
                                      label="Video Title*"
                                      type="text"
                                      size="small"
                                      onChange={(e) => {
                                        handleVideoTitleChange(e, index);
                                        formik.handleChange(e);
                                      }}
                                      value={value.title}
                                      inputProps={{ style: { fontSize: '0.9rem' } }}
                                      fullWidth
                                      // error={Boolean(touched.videoTitle && errors.videoTitle)}
                                      // helperText={touched.videoTitle && errors.videoTitle}
                                      // {...getFieldProps('title')}
                                    />
                                    {/* <div className='errorMessage'>{touched.videoTitle && errors.videoTitle}</div> */}
                                    <div className="errorMessage">{value.titleError}</div>
                                  </Grid>
                                  <Grid item xs={2} sm={4} md={4} lg={4}>
                                    <TextField
                                      id="videoId"
                                      name="videoId"
                                      label="Video Id*"
                                      type="text"
                                      size="small"
                                      inputProps={{ style: { fontSize: '0.8rem' } }}
                                      // fullWidth
                                      onChange={(e) => {
                                        handleVideoIdChange(e, index);
                                        formik.handleChange(e);
                                      }}
                                      onBlur={(e) => handleFieldBlur('productMediaUrl', e.target.value)}
                                      value={value.videoId}
                                      // error={Boolean(touched.videoId && errors.videoId)}
                                      // helperText={touched.videoId && errors.videoId}
                                      // {...getFieldProps('videoId')}
                                    />
                                    {/*  <div className='errorMessage'>{touched.videoId && errors.videoId}</div> */}
                                    <div className="errorMessage">{value.videoIdError}</div>
                                  </Grid>
                                </Stack>
                              </Grid>
                            ))}
                          </React.Fragment>
                        ) : (
                          values.feedMediaType === 'IMAGE' && (
                            <React.Fragment key={3010}>
                              <Grid item xs={12} sm={12} md={12}>
                                <TextField
                                  id="productMediaUrl"
                                  name="productMediaUrl"
                                  label="Image Folder Name*"
                                  type="text"
                                  fullWidth
                                  value={mediaFolderName}
                                  // defaultValue={mediaFolderName}
                                  onChange={(e) => {
                                    onImgChange(e);
                                    formik.handleChange(e);
                                  }}
                                  onBlur={(e) => {
                                    handleImgChange(e);
                                    formik.handleChange(e);
                                  }}
                                  // error={Boolean(touched.productMediaUrl && errors.productMediaUrl)}
                                  // helperText={touched.productMediaUrl && errors.productMediaUrl}
                                  // {...getFieldProps('productMediaUrl')}
                                />
                              </Grid>
                              {isFolderNameAvailable && values.productMediaUrl ? (
                                showUploadLoader?.loading && showUploadLoader.type === 'productMedia' ? (
                                  <Grid item xs={12} sm={12} md={12}>
                                    <CircularProgress style={{ color: '#FFA728' }} />
                                  </Grid>
                                ) : imgProductList?.length === 0 ? (
                                  <Grid item xs={12} sm={12} md={12}>
                                    <div className="errorMessage">
                                      {errors.productMediaUrl ? 'Image folder name required' : 'Image folder not found'}
                                    </div>
                                  </Grid>
                                ) : (
                                  <Box
                                    sx={{ height: '200px', overflowX: 'hidden', overflowY: 'scroll', width: '100%' }}
                                  >
                                    {mediaFolderName &&
                                      imgProductList?.map((value, ind) => (
                                        <Stack
                                          key={ind + 1}
                                          direction="row"
                                          spacing={2}
                                          alignItems="center"
                                          style={{
                                            width: '100%',
                                            marginTop: '15px',
                                            marginLeft: '15px',
                                          }}
                                        >
                                          <Grid
                                            item
                                            xs={8}
                                            sm={8}
                                            md={8}
                                            sx={{ display: 'flex', justifyContent: 'flex-start' }}
                                          >
                                            <InputLabel sx={{ mb: '10px', fontWeight: 600, width: '100%' }}>
                                              {value.file_name.split('/').pop().split('.')[0]}
                                            </InputLabel>
                                          </Grid>
                                          {/* <Grid item xs={4} sm={4} md={4}>
                                          <StyledAvatar onClick={() => viewFile(value.file_path)}>
                                            <Iconify icon={'eva:eye-outline'} sx={{ width: 22, height: 22 }} />
                                          </StyledAvatar>
                                        </Grid> */}
                                          <Grid
                                            item
                                            xs={4}
                                            sm={4}
                                            md={4}
                                            sx={{
                                              display: 'flex',
                                              justifyContent: 'flex-end',
                                              marginRight: '20px!important',
                                            }}
                                          >
                                            {values.subscriptionType === SubscriptionType[0].value ||
                                            values.subscriptionType === SubscriptionType[2].value ? (
                                              <FormControlLabel
                                                control={
                                                  <Checkbox
                                                    type="checkbox"
                                                    checked={checkedIndex === ind}
                                                    onChange={() => handleCheckboxChange(ind, value)}
                                                  />
                                                }
                                                disabled
                                              />
                                            ) : (
                                              <Checkbox
                                                type="checkbox"
                                                id="subscriptionCheckbox"
                                                name="subscriptionCheckbox[]"
                                                inputProps={{ 'aria-label': 'controlled' }}
                                                onChange={() => handleCheckboxChange(ind, value)}
                                                checked={checkedIndex === ind}
                                                // onChange={() => handleCheckboxChange(ind, value.is_Premium)}
                                              />
                                            )}
                                          </Grid>
                                        </Stack>
                                      ))}
                                  </Box>
                                )
                              ) : null}
                              {touched.productMediaUrl && errors.productMediaUrl ? (
                                <Grid item xs={12} sm={12} md={12}>
                                  <div className="errorMessage">{'Image folder name required'}</div>
                                </Grid>
                              ) : null}
                            </React.Fragment>
                          )
                        )}
                      </Grid>
                    )}
                    <Box>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          marginBottom: '5px',
                          marginTop: '15px',
                        }}
                      >
                        <Typography>Show Like Button</Typography>
                        <Switch {...label} checked={showLike} onChange={handleLikeToggle} />
                      </Box>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography>Show Share Button </Typography>
                        <Switch {...label} checked={showShare} onChange={handleShareToggle} />
                      </Box>
                    </Box>

                    <DialogActions sx={{ justifyContent: 'end' }}>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          setMediaFolderName(null);
                          fileByFolder.length = 0;
                          feedById.length = 0;
                          props.onClose();
                        }}
                        sx={{
                          minWidth: '100px',
                          color: (theme) => theme.palette.text.darkGrey,
                          borderColor: (theme) => theme.palette.text.darkGrey,
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        disabled={showUploadLoader?.loading}
                        onClick={formik.handleSubmit}
                        style={{ minWidth: '100px', marginLeft: '10px' }}
                      >
                        Save
                      </Button>
                    </DialogActions>
                  </CardContent>
                </Card>
              </Grid>

              {/* Preview Card================================================== */}
              <Grid item xs={12} md={6}>
                <Card sx={{ height: '85vh', position: 'relative' }}>
                  <CardContent>
                    <Grid item xs={12}>
                      <Typography sx={{ color: 'black', marginLeft: '0px', fontWeight: 'bold' }}>Preview</Typography>
                    </Grid>
                    {/* Preview content based on form values */}
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          fontWeight: 'bold',
                          marginBottom: '20px',
                          marginTop: '8px',
                          letterSpacing: '3px',
                          display: 'flex',
                          flexWrap: 'wrap',
                          maxHeight: '50px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {previewValues.title}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          textAlign: 'start',
                          letterSpacing: '2px',
                          // fontWeight: 'bold',
                          marginBottom: '20px',
                          marginTop: '8px',

                          display: 'flex',
                          flexWrap: 'wrap',
                          maxHeight: '70px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {previewValues.description}
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      {previewValues?.feedMediaType === 'IMAGE' && (
                        <Box>
                          <img
                            src={
                              mediaTypeChanged && blobURL
                                ? URL.createObjectURL(blobURL)
                                : `${process.env.REACT_APP_BASE_URL}/api/get-admin-image-file/${data?.feed_image[0]?.id}`
                            }
                            // src={`${process.env.REACT_APP_BASE_URL}/api/get-admin-image-file/${data?.feed_image[0]?.id}`}
                            // src={`${process.env.REACT_APP_BASE_URL}/api/get-admin-image-file/${previewValues.productMediaUrl[0]?.id}`}
                            alt={previewValues?.productMediaUrl}
                            width="100%"
                            height="200"
                          />
                        </Box>
                      )}

                      {previewValues.feedMediaType === 'VIDEO' && previewValues.productMediaUrl && (
                        <Box>
                          <iframe
                            width="100%"
                            height="250"
                            src={`https://www.youtube.com/embed/${previewValues.productMediaUrl}`}
                            allowfullscreen
                            autoPlay
                            title="video"
                          />
                          {/* // <Grid item xs={12} sm={12} md={12} key={index + 1}>
                          //   <InputLabel sx={{ mb: '10px', fontWeight: 600 }}>{value.file_name}</InputLabel>
                          //   <StyledAvatar
                          //     onClick={() =>
                          //       window.open(`https://www.youtube.com/watch?v=${value.file_path}`, '_blank').focus()
                          //     }
                          //   >
                          //     <Iconify icon={'eva:eye-outline'} sx={{ width: 22, height: 22 }} />
                          //   </StyledAvatar>
                          // </Grid> */}
                        </Box>
                      )}

                      {
                        previewValues.feedMediaType === 'MPG' && previewValues.productMediaUrl && (
                          <Box>
                            <p>{previewValues.productMediaUrl}</p>
                            <audio controls>
                              <track kind="captions" src="" srcLang="en" label="English" />
                              <source src={`${previewValues.productMediaUrl}`} type="audio/mpeg" />
                            </audio>
                          </Box>
                        )

                        //  <Grid item xs={6} sm={6} md={6}>
                        //     <InputLabel sx={{ mb: '10px', fontWeight: 600 }}>
                        //       {value.fileName.split('/').pop().split('.')[0]}
                        //     </InputLabel>
                        //   </Grid>
                        //   <Grid item xs={4} sm={4} md={4}>
                        //     <StyledAvatar onClick={() => viewFile(value.filePath)}>
                        //       <Iconify icon={'eva:eye-outline'} sx={{ width: 22, height: 22 }} />
                        //     </StyledAvatar>
                        //   </Grid>
                      }

                      {previewValues.feedMediaType === 'NONE' && (
                        <Box>
                          <p> No media selected</p>
                        </Box>
                      )}
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      lg={12}
                      sx={{
                        borderTop: '1px solid gray',
                        position: 'absolute',
                        bottom: '20px',
                        width: '100%',
                        display: 'flex',
                        color: '#919EAB',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Button disabled={!showLike} variant="outlined" sx={{ marginRight: '15px', marginTop: '15px' }}>
                        Like
                      </Button>
                      <Button disabled={!showShare} variant="outlined" sx={{ marginTop: '15px' }}>
                        Share
                      </Button>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </Box>
    </React.Fragment>
  );
}
