import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
//	@mui
import {
	Card,
	Table,
	TableRow,
	TableBody,
	TableCell,
	Container,
	TableContainer,
	TablePagination,
	Tooltip,
	Avatar,
} from '@mui/material';
//	components
import Scrollbar from '../components/scrollbar';
//	sections
import { PagesHead, PagesToolbar, PagesMoreMenu } from '../sections/@dashboard/common';
import NotificationDialogBox from '../components/DiaglogBox/NotificationDialogBox';
import DeleteConfirmationDialog from '../components/DiaglogBox/DeleteConfirmationDialog';
import ViewNotificationDialog from '../components/DiaglogBox/ViewNotificationDialog';
import { DeleteNotification, GetAllNotification } from '../actions/NotificationAction';
import ViewImage from '../components/image-upload/ViewImage';
//	----------------------------------------------------------------------

//	Datatable header/column 
const TABLE_HEAD = [
	{ id: 'image', label: 'Image', alignRight: false },
	{ id: 'title', label: 'Title', alignRight: false },
	{ id: 'description', label: 'Message', alignRight: false },
	{ id: 'created_at', label: 'Created', alignRight: false },
	{ id: 'action', label: 'Action' },
];// END TABLE_HEAD

export default function NotificationPage() 
{
	/*	Setup global variables and states	*/
	const dispatch = useDispatch();
	/*	set state variable for pagination & search	*/
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [searchValue, setSearchValue] = React.useState('');
	/*	set state variable for dialog box	*/
	const [open, setOpen] = React.useState(false);
	const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
	const [viewNotificationDialogOpen, setViewNotificationDialogOpen] = React.useState(false);
	const [imageDialogOpen, setImageDialogOpen] = React.useState(false);
	const [imageData, setImageData] = useState({});
	/*	set initial data state for notification 	*/
	const [data, setData] = React.useState(null);

	//	Get all states required for this page
	const { notifications, pageInfo, addNotificationLog, editNotificationLog, deleteNotificationLog } = useSelector(
		(state) => ({
			notifications: state.notifications.notifications,
			pageInfo: state.notifications.pageInfo,
			addNotificationLog: state.notifications.addNotificationLog,
			editNotificationLog: state.notifications.editNotificationLog,
			deleteNotificationLog: state.notifications.deleteNotificationLog,
		})
	);

	/*	Set up initial data and states	*/
	useEffect(() => {
		setConfirmationDialogOpen(false);
		//	Fetch all notification list
		dispatch(GetAllNotification(page + 1, rowsPerPage, searchValue));
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [addNotificationLog, editNotificationLog, deleteNotificationLog]);

	/*	Get notification list on search */
	useEffect(() => {
		const delayDebounce = setTimeout(() => {
			dispatch(GetAllNotification(1, rowsPerPage, searchValue));
			setPage(0);
		}, 1000);
		return () => clearTimeout(delayDebounce);
	}, [searchValue]);
	
	/*	Handle action/event related to pagination	*/
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
		dispatch(GetAllNotification(newPage + 1, rowsPerPage, searchValue));
	};// END handleChangePage 

	/*	Handle action/event on change of page number */
	const handleChangeRowsPerPage = (event) => {
		setPage(0);
		setRowsPerPage(parseInt(event.target.value, 10));
		dispatch(GetAllNotification(1, parseInt(event.target.value, 10), searchValue));
	};// END handleChangeRowsPerPage

	/*	Handle action/event related to search	*/
	const handleSearchChange = (event) => {
		setSearchValue(event.target.value);
	};// END handleSearchChange

	/*	Handle action/event on click of search cross */
	const handleClearClick = () => {
		dispatch(GetAllNotification(1, rowsPerPage, ''));
		setPage(0);
		setSearchValue('');
	};// END handleClearClick

	/*	Handle action/event related to view notification	*/
	const handleViewNotificationDialogClose = () => {
		// console.log('handleViewNotificationDialogClose', window.scrollY);
		setViewNotificationDialogOpen(false);
		setData(null);
		window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
	};// END handleViewNotificationDialogClose

	const handleViewNotificationDialogOpen = (data) => {
		setViewNotificationDialogOpen(true);
		setData(data);
	};// END handleViewNotificationDialogOpen

	//	Open/close dialog click
	const handleClickOpen = (e) => {
		setData(null);
		setOpen(true);
	};// END handleClickOpen

	const handleClose = async () => {
		// console.log('handleClose', window.scrollY);
		// window.scrollTo(0,0);
		window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
		setOpen(false);
		setData(null);
	};// END handleClose

	/*	Handle action/event related to edit notification	*/
	const handleEdit = (data) => {
		setData(data);
		setOpen(true);
	};// END handleEdit

	/*	Handle action/event related to delete notification	*/
	const handleDelete = (data) => {
		setConfirmationDialogOpen(true);
		setData(data);
	};// END handleDelete

	/*	Handle action/event of confirmations	*/
	const handleConfirmationClose = (message) => {
		// console.log('handleConfirmationClose', window.scrollY);
		document.body.style.overflow = 'hidden';
		document.body.style.overflow = 'unset';
		window.scrollTo(0, 0);
		if (message) {
			dispatch(DeleteNotification(data.id));
		} else {
			setConfirmationDialogOpen(false);
			setData(null);
		}
	};// END handleConfirmationClose
 
	return (
		<>
			<Helmet>
				<title> Notification | Rushipeetham </title>
			</Helmet>
			<Container>
				{open ? <NotificationDialogBox open={open} data={data} onClose={handleClose} /> : null}
				{viewNotificationDialogOpen ? (
					<ViewNotificationDialog
						open={viewNotificationDialogOpen}
						data={data}
						onClose={handleViewNotificationDialogClose}
					/>
				) : null}
				{confirmationDialogOpen ? (
					<DeleteConfirmationDialog
						message="Notification"
						isOpenConfirm={confirmationDialogOpen}
						handleClose={handleConfirmationClose}
					/>
				) : null}

				<Card>
					<PagesToolbar
						placeHolder={'Search Notification...'}
						onFilterName={handleSearchChange}
						filterName={searchValue}
						handleClearClick={handleClearClick}
						buttonName="New Notification"
						handleClickOpen={handleClickOpen}
					/>
					<Scrollbar>
						<TableContainer sx={{ minWidth: 800 }}>
							<Table aria-label="simple table" size="small">
								<PagesHead headLabel={TABLE_HEAD} />
								{
									notifications.length !== 0 ? 
									<TableBody>
										{
											notifications?.map(
											(row, index) => {
												const day = Math.ceil((new Date()-new Date(row?.updated_at)) / (1000 * 3600 * 24));
												return <>
												<TableRow key={index+1}>
													{/*	 <TableCell>{(page*rowsPerPage)+(index + 1)}</TableCell> 	*/}
													<TableCell>	
														<ViewImage 
															imagData={row?.notification_images.length!==0?row?.notification_images?.[0]:[]}
															imgID={row?.notification_images?.[0]?.id}/>								
													</TableCell>
													<TableCell>
														<Tooltip title={row?.notification_title}>
															<div id={row?.id}
																style={{
																	whiteSpace: 'nowrap',
																	overflow: 'hidden',
																	textOverflow: 'ellipsis',
																	width: '200px',
																}}>
																{row?.notification_title}...
															</div>
														</Tooltip>
													</TableCell>
													<TableCell>
														<Tooltip title={row?.notification_message}>
															<div id={row?.id}
																style={{
																	whiteSpace: 'nowrap',
																	overflow: 'hidden',
																	textOverflow: 'ellipsis',
																	width: '300px',
																}}>
																{row?.notification_message}...
															</div>
														</Tooltip>
													</TableCell>
													<TableCell>{
														(day <= 1) ? 'Today' : `${day} Days ago `
													} </TableCell>											
													<TableCell align="center">
														<PagesMoreMenu 
															handleEdit={() => handleEdit(row)}
															handleView={() => handleViewNotificationDialogOpen(row)} 
															handleDelete={() => handleDelete(row)}							
														/>
													</TableCell>
												</TableRow>
												</>
											})										
										}
									</TableBody>	
									: 
									<TableRow> 
										<TableCell colSpan={12}>
											<div className='text-align-center font-18 font-bold py-15' style={{'textAlign': 'center'}} >
												No data found...
											</div>
										</TableCell>
									</TableRow>
								}						
							</Table>
						</TableContainer>
					</Scrollbar>
					<TablePagination
						rowsPerPageOptions={[10, 20, 30]}
						component="div"
						count={pageInfo.total ? pageInfo.total : 0}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				</Card>
			</Container>
		</>
		
	);// END Return 

}// END NotificationPage
