import { SetNewAlert } from "./AlertActions";
import { Logout, SessionExpired } from "./AuthActions";
import { ShowLoader, ShowLoadingButton, ShowUploadLoader } from "./CommonAction";

const HandleExceptionWithSecureCatch = (exception) => (dispatch, getState) => {
    const {message} = exception.response.data;
    const statusCode = exception.response.status;

    if(statusCode===401 && window.location.pathname!=="/login"){
      dispatch(SetNewAlert({
        msg: "Session Expired",
        alertType: "danger",
      }));
      dispatch(SessionExpired())
  
      dispatch(ShowLoader(false))
      dispatch(ShowUploadLoader({loading:false,type:""}))
    }

    else {
      dispatch(SetNewAlert({
        msg: message,
        alertType: "danger",
      }));
  
      dispatch(ShowLoader(false))
      dispatch(ShowUploadLoader({loading:false,type:""}))
    }

    };

const HandleManualException = (exception) => {
  console.log("EXCEPTION",exception);
  return (dispatch, getState) => {

      dispatch(SetNewAlert({
        msg: exception.meta.message,
        alertType: "danger",
      }));
  
      dispatch(ShowLoadingButton(false));
      dispatch(ShowLoader(false))
      dispatch(ShowUploadLoader({loading:false,type:""}))
    }
};



export { HandleExceptionWithSecureCatch, HandleManualException };
