import { CHANGE_STATUS_OF_USER, GET_USERS, RESET_STATE,GET_ALL_USERS } from "../actions/Types";
    
    const INIT_STATE = {
      users:[],
      allUsers:[],
      pageInfo:{},
      userChangeLog: false
    };
    
    export default function UserReducer (state = INIT_STATE, action)  {
      const { type, payload } = action;
      
      switch (type) {
        case GET_USERS:
          return {
            ...state,
            users: payload.data,
            pageInfo: payload.data
          };
          case GET_ALL_USERS:
            return {
              ...state,
              allUsers: payload.data,
              // pageInfo: payload.data
            };
        case CHANGE_STATUS_OF_USER:
        return {
            ...state,
            userChangeLog: !state.userChangeLog
        };
        case RESET_STATE:
          return { ...INIT_STATE };

        default:
          return state;
      }
    };
    