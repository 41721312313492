import React from 'react';
import { useSelector } from 'react-redux';
/* @mui */
import {
    TextField,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    InputLabel,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
// ----------------------------------------------------------------------

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2}} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 12,
                        top: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function ViewTagsDialog(props) {
    const {data} = props;    
    const { addCategoryLog, editCategoryLog } = useSelector((state) => ({
        addCategoryLog: state.categories.addCategoryLog,
        editCategoryLog: state.categories.editCategoryLog,
    }));

    const firstRun = React.useRef(true);
    React.useEffect(()=>{
      if (firstRun.current) {
        firstRun.current = false;
        return;
      }
      props.onClose()
    },[addCategoryLog,editCategoryLog])

    return (
        <>
            <Dialog
                open={props.open}
                // onClose={props.onClose}
                className="dialog-box-section"
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={props.onClose}>
                    Tags
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12}>
                            <InputLabel sx={{mb:"5px",fontWeight:600}}>
                                Tag Name
                            </InputLabel>
                            <TextField
                                id="outlined-name-input"
                                type="text"
                                fullWidth
                                InputProps={{
                                    readOnly: true,
                                }}
                                value={data.tag_name}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <InputLabel sx={{mb:"5px",fontWeight:600}}>
                                Status
                            </InputLabel>
                            <TextField
                                id="ActiveStatus"
                                name="status"
                                InputProps={{
                                    readOnly: true,
                                }}
                                fullWidth
                                value={data?.is_active===1?"Active":"Inactive"}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel sx={{mb:"5px",fontWeight:600}}>
                                Attached Product
                            </InputLabel>
                            <TextField
                                multiline
                                rows={2}
                                id="outlined-name-input"
                                type="text"
                                fullWidth
                                InputProps={{
                                    readOnly: true,
                                }}
                                value={data?.product_tag_reference?.reduce((accum, item)=>(`${accum},  ${item?.products?.length > 0 ? item.products[0].product_name:' '}`),"")?.substring(1).replaceAll(' ,', '')}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    );
}
