/* eslint-disable no-fallthrough */
/* eslint-disable react/jsx-key */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { styled } from '@mui/material/styles';
import _, { replace } from 'lodash';
// @mui
import {
  Switch,
  Autocomplete,
  FormControlLabel,
  Chip,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Button,
  MenuItem,
  IconButton,
  InputLabel,
  Avatar,
  Stack,
  CircularProgress,
  Backdrop,
  Checkbox,
  Typography,
} from '@mui/material';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import CloseIcon from '@mui/icons-material/Close';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import PropTypes from 'prop-types';
import { productValidationSchema } from '../../utils/validation/ProductValidation';
import { GetCategoryForDropdown } from '../../actions/CategoriesAction';
import { GetTagsForDropdown } from '../../actions/TagsAction';
import { UploadProductImages, UploadProductFiles, GetFileByFolderName } from '../../actions/UploadActions';
import { AddProduct, EditProduct, GetProductById } from '../../actions/ProductActions';
import { ShowLoader, ShowUploadLoader } from '../../actions/CommonAction';
import Iconify from '../iconify/Iconify';
import { SetNewAlert } from '../../actions/AlertActions';
import {
  DisplayStatusArray,
  MediaType,
  SubscriptionType,
  Months,
  MaxDateValue,
  MinDateValue,
} from '../common/constants';
// import { makeStyles } from '@material-ui/core/styles';
// import './common/category.css';
// ----------------------------------------------------------------------
const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 12,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: 'green',
  color: '#FFFFFF',
  cursor: 'pointer',
  width: 34,
  height: 34,
  marginLeft: 5,
}));

const AddRemoveAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: '#FFFFFF',
  cursor: 'pointer',
  width: 34,
  height: 34,
  marginLeft: 5,
}));

export default function ProductDialogBox(props) {
  /* START Configure Data On Page */
  const dispatch = useDispatch();
  const { data } = props;
  // let productMediaFolderName = '';
  const [upload, setUpload] = useState([]);
  const [fileUrl, setFileUrl] = useState('');
  const [changeMp3, setChangeMp3] = useState(false);
  const [changePdf, setChangePdf] = useState(false);
  // const [showProductList, setShowProductList] = useState(false);
  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [isFolderNameAvailable, setIsFolderNameAvailable] = useState(false);
  const [showAudioLoader, setShowAudioLoader] = useState(false);
  const [videoArray, setVideoArray] = useState([{ title: '', videoId: '', titleError: '', videoIdError: '' }]);
  const [uploadedPDFPageCount, setUploadedPDFPageCount] = useState(0);
  const [releaseDateMagazine, setReleaseDateMagazine] = useState(dayjs(new Date()));
  const [expiryOfNewProd, setExpiryOfNewProd] = useState(dayjs(new Date()));
  // let audioProductList = [];
  const PDFProductList = [];
  const [tagData, setTagData] = useState([]);
  // console.log("tagData",tagData)
  const [tagValidation, setTagValidation] = useState(false);
  const [arrConstCategoryType, setArrConstCategoryType] = useState([]);
  const [isThisShortVideoCat, setIsThisShortVideoCat] = useState(false);
  const [mediaFolderName,setMediaFolderName] = useState('')
  const [pdfProductList,setPdfPoductList] = useState([])
  const [audioProductList,setAudioProductList] = useState([])

  const {
    addProductLog,
    editProductLog,
    categoriesForDropdown,
    tagsForDropdown,
    productImage,
    uploadProductImageLog,
    productFile,
    uploadProductFileLog,
    productById,
    showLoader,
    showUploadLoader,
    fileByFolder,
  } = useSelector((state) => ({
    addProductLog: state.products.addProductLog,
    editProductLog: state.products.editProductLog,
    categoriesForDropdown: state.categories.categoriesForDropdown,
    tagsForDropdown: state.tags.tagsForDropdown,
    productImage: state.upload.productImage,
    uploadProductImageLog: state.upload.uploadProductImageLog,
    productFile: state.upload.productFile,
    uploadProductFileLog: state.upload.uploadProductFileLog,
    productById: state.products.productById,
    showLoader: state.common.showLoader,
    showUploadLoader: state.common.showUploadLoader,
    fileByFolder: state.upload.fileByFolder,
  }));

  // /* Get Folder Name For AUdio Category Product */
  // if (productById?.product_media_type === 'MPG') {
  //   if (productById?.product_mpg.length > 0 && !changeMp3) {
  //     productMediaFolderName =
  //       data !== null
  //         ? productById?.product_mpg?.[0]?.file_path.split('/').slice(0, -1).toString().replaceAll(',', '/')
  //         : '';

  //     audioProductList = JSON.parse(JSON.stringify(productById?.product_mpg));
  //   } else if (fileByFolder?.mp3_files.length > 0 ) {
  //     productMediaFolderName = fileByFolder?.mp3_files[0]?.split('/').slice(0, -1).toString().replaceAll(',', '/');
  //     audioProductList = JSON.parse(
  //       JSON.stringify(
  //         fileByFolder?.mp3_files.map((option) => ({
  //           file_name: option,
  //           file_path: option,
  //           is_premium: 0,
  //         }))
  //       )
  //     );
  //   }
  // } else {
  //   // console.log("mp3 condition3")
  //   // productMediaFolderName =
  //   //   data !== null ? fileByFolder?.mp3_files[0]?.split('/').slice(0, -1).toString().replaceAll(',', '/') : '';
  //   // audioProductList = JSON.parse(
  //   //   JSON.stringify(
  //   //     fileByFolder?.mp3_files.map((option) => ({
  //   //       file_name: option,
  //   //       file_path: option,
  //   //       is_premium: 0,
  //   //     }))
  //   //   )
  //   // );
  // }

  // // ==================================
  // /* Get Folder Name For PDF Category Product */
  // if (productById?.product_media_type === 'PDF') {
  //   if (productById?.product_pdf && !changePdf) {
  //     productMediaFolderName =
  //       data !== null
  //         ? productById?.product_pdf?.[0]?.file_path.split('/').slice(0, -1).toString().replaceAll(',', '/')
  //         : '';
  //     PDFProductList = JSON.parse(JSON.stringify(productById?.product_pdf));
  //   } else if (fileByFolder?.pdf_files[0]?.length > 0) {
  //     productMediaFolderName = fileByFolder?.pdf_files[0]?.split('/').slice(0, -1).toString().replaceAll(',', '/');
  //     PDFProductList = JSON.parse(
  //       JSON.stringify(
  //         fileByFolder?.pdf_files.map((option) => ({
  //           file_name: option,
  //           file_path: option,
  //           is_premium: 0,
  //         }))
  //       )
  //     );
  //   }
  // } else {
  //   // console.log("condition3")
  //   // productMediaFolderName = data !== null ? fileByFolder?.pdf_files[0] : '';
  //   // console.log("productMediaFolderName",productMediaFolderName)
  //   // PDFProductList = JSON.parse(
  //   //   JSON.stringify(
  //   //     {
  //   //       // fileByFolder?.pdf_files?.map((option) => ({
  //   //       file_name: fileByFolder?.pdf_files,
  //   //       file_path: fileByFolder?.pdf_files,
  //   //       is_premium: 0,
  //   //     }
  //   //     // })
  //   //     // )
  //   //   )
  //   // );
  //   // console.log("productMediaFolderName",productMediaFolderName)
  // }


  const checkData = (e) => {
    if (!values.category) {
      dispatch(
        SetNewAlert({
          msg: 'Please select category first',
          alertType: 'danger',
        })
      );
      e.preventDefault();
      return false;
    }
    if (!values.productName) {
      dispatch(
        SetNewAlert({
          msg: 'Please enter product name first',
          alertType: 'danger',
        })
      );
      e.preventDefault();
      return false;
    }
    return true;
  }; // END checkData

  const findCategoryNameById = (id) => {
    const found = categoriesForDropdown.find((e) => e.id === id);
    if (found) {
      return found.category_name;
    }
    return null;
  }; // END findCategoryNameById

  const findCategoryTypeById = (id) => {
    const found = categoriesForDropdown.find((e) => e.id === id);
    if (found) {
      return found.category_type;
    }
    return null;
  }; // END findCategoryTypeById

  /* END Configure Data On Page */

  /* START Use Effects */
  React.useEffect(() => {
    dispatch(GetCategoryForDropdown());
    dispatch(GetTagsForDropdown());
    if (data) {
      dispatch(GetProductById(data.id));
      dispatch(ShowLoader(true));
      setTagData(
        data?.product_tag_references
          ?.map((item, index) => item.product_tag.map((innerItem, innerIndex) => innerItem.id))
          .flat() || []
      );
    }
    // SET CATEGORY_TYPE ARRAY
    setArrConstCategoryType(
      categoriesForDropdown?.filter((option) => option.category_type !== null).map((option) => option.category_type)
    );
  }, [data, dispatch]); // END DATA useEffect

  const fileByFolderRun = React.useRef(true);
  React.useEffect(() => {
    if (fileByFolderRun.current) {
      fileByFolderRun.current = false;
      return;
    }
    dispatch(ShowUploadLoader({ loading: false, type: '' }));

    if (fileByFolder?.pdf_files?.length > 0) {
      const productMediaFolderName = fileByFolder?.pdf_files[0]?.split('/').slice(0, -1).toString().replaceAll(',', '/');
      setMediaFolderName(productMediaFolderName)
      const PDFProductList = JSON.parse(
        JSON.stringify(
          fileByFolder?.pdf_files.map((option) => ({
            file_name: option,
            file_path: option,
            is_premium: 0,
          }))
        )
      );
      setPdfPoductList(PDFProductList)
    }
    else if (fileByFolder?.mp3_files.length > 0 ) {
      const productMediaFolderName = fileByFolder?.mp3_files[0]?.split('/').slice(0, -1).toString().replaceAll(',', '/');
      setMediaFolderName(productMediaFolderName)
      const audioProduct = JSON.parse(
        JSON.stringify(
          fileByFolder?.mp3_files.map((option) => ({
            file_name: option,
            file_path: option,
            is_premium: 0,
          }))
        )
      );
      setAudioProductList(audioProduct)
    }
    else {
      setPdfPoductList([])
      setAudioProductList([])
    }

}, [fileByFolder]); // END useEffect

  // React.useEffect(() => {
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   productMediaFolderName =
  //     data !== null
  //       ? audioProductList
  //         ? [0]?.file_name?.split('/').slice(0, -1).toString().replaceAll(',', '/')
  //         : ''
  //       : '';
  //       // console.log("productMediaFolderName",productMediaFolderName)
  // }, [audioProductList]);
  
  // React.useEffect(() => {
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   productMediaFolderName ="kajalll"
  //     // data !== null
  //     //   ? PDFProductList
  //     //     ?[0]?.file_name?.split('/').slice(0, -1).toString().replaceAll(',', '/')
  //     //     : ''
  //     //   : ''; 
  //       console.log("productMediaFolderName",productMediaFolderName)
  // }, [PDFProductList]);


  const getProductRun = React.useRef(true);

  React.useEffect(() => {
    if (getProductRun.current) {
      getProductRun.current = false;
      return;
    }
    dispatch(ShowLoader(false));

    if (productById) {
      if (productById?.product_tag_references?.length > 0) {
        setTagData(
          productById?.product_tag_references
            ?.map((item, index) => item.product_tag.map((innerItem, innerIndex) => innerItem.id))
            .flat() || []
        );
      }
      // setShowProductList(true)
      if (productById?.product_image?.length !== 0) {
        setUpload(productById?.product_image);
        setIsImageUploaded(true);
        setFieldValue('image', 'something');
      } else {
        setIsImageUploaded(false);
      }
      if (productById?.product_media_type === 'PDF') {
        if (productById?.product_pdf.length !== 0) {
          setIsFileUploaded(true);
          setFileUrl(productById?.product_pdf[0]?.file_path);
          setFieldValue('productMediaUrl', productById?.product_pdf[0].file_path?.split('/').pop());
         
          const productMediaFolderName = productById?.product_pdf[0]?.file_path
            ?.split('/')
            .slice(0, -1)
            .toString()
            .replaceAll(',', '/');
          setMediaFolderName(productMediaFolderName)
            console.log("productMediaFolderName",productMediaFolderName)
          const PDFProductList = JSON.parse(JSON.stringify(productById?.product_pdf));
          setPdfPoductList(PDFProductList)
          dispatch(GetFileByFolderName({ folder_name: productMediaFolderName }));
          dispatch(ShowUploadLoader({ loading: true, type: 'productMedia' }));
          setIsFolderNameAvailable(true);
          // setChangePdf(true);
        } else {
          setIsFileUploaded(false);
          setIsFolderNameAvailable(true);
        }
      } else if (productById.product_media_type === 'MPG') {
        if (productById?.product_mpg.length !== 0) {
          setIsFileUploaded(true);
          setFileUrl(productById?.product_mpg[0].file_path);
          setFieldValue('productMediaUrl', productById?.product_mpg[0].file_path);
          // dispatch(GetFileByFolderName({"folder_name":productById?.product_mpg[0].file_path}))
          // eslint-disable-next-line react-hooks/exhaustive-deps
          const productMediaFolderName = productById?.product_mpg[0].file_path
            ?.split('/')
            .slice(0, -1)
            .toString()
            .replaceAll(',', '/');
            setMediaFolderName(productMediaFolderName)
            const audioProduct = JSON.parse(JSON.stringify(productById?.product_mpg));
            setAudioProductList(audioProduct)
          dispatch(GetFileByFolderName({ folder_name: productMediaFolderName }));
          dispatch(ShowUploadLoader({ loading: true, type: 'productMedia' }));
          setIsFolderNameAvailable(true);
        } else {
          setIsFileUploaded(false);
          setIsFolderNameAvailable(true);
        }
      } else if (productById.product_media_type === 'VIDEO') {
        if (productById?.product_video.length !== 0) {
          setIsFileUploaded(true);
          setFileUrl(productById?.product_video[0].file_path);
          setFieldValue('productMediaUrl', 'BsolkMHI0Ok');
          const newVideoArray = [];
          productById?.product_video?.map((value, index) => {
            const infoToAdd = {
              title: value.file_name,
              videoId: value.file_path,
              titleError: '',
              videoIdError: '',
            };
            newVideoArray.push(infoToAdd);
            return null;
          });
          setVideoArray(newVideoArray);
        } else {
          setIsFileUploaded(false);
        }
      }
      if (
        findCategoryTypeById(productById?.category_id) === 'eMagazines' &&
        productById?.release_date_of_magazine !== null
      ) {
        setReleaseDateMagazine(dayjs(productById?.release_date_of_magazine));
      } else if (findCategoryTypeById(productById?.category_id) === 'Short_Videos') {
        setIsThisShortVideoCat(true);
      }
      if (productById?.new_product_expiry_date !== null) {
        setExpiryOfNewProd(dayjs(productById?.new_product_expiry_date));
      }
    }
  }, [productById]); // END useEffect

  const firstRun = React.useRef(true);

  React.useEffect(() => {
    if (firstRun.current) {
      firstRun.current = false;
      return;
    }
    props.onClose();
  }, [addProductLog, editProductLog]); // END useEffect

  const secondRun = React.useRef(true);
  React.useEffect(() => {
    if (secondRun.current) {
      secondRun.current = false;
      return;
    }
    const productImageArray = upload;
    //   productImage?.map((value,index)=>{
    //     productImageArray.push({"url":value})
    //     return null;
    //   })
    productImageArray.push({ file_path: productImage.url });
    setUpload(productImageArray);
    dispatch(ShowUploadLoader({ loading: false, type: '' }));
    setIsImageUploaded(true);
  }, [uploadProductImageLog]); // END useEffect

  const uploadFileRun = React.useRef(true);
  React.useEffect(() => {
    if (uploadFileRun.current) {
      uploadFileRun.current = false;
      return;
    }
    setFileUrl(productFile?.url);
    setFieldValue('productMediaUrl', productFile?.url?.split('/').pop());
    setIsFileUploaded(true);
    dispatch(ShowUploadLoader({ loading: false, type: '' }));
  }, [uploadProductFileLog]); // END useEffect

  /* END Use Effects */

  /* START Handle Change */

  const handleImgChange = (e) => {
    if (e.target.files && e.target.files[0] && checkData(e)) {
      const ext = e.target.value.split('.').pop();
      const formData = new FormData();
      if (ext !== 'jpg' && ext !== 'png' && ext !== 'jpeg') {
        dispatch(
          SetNewAlert({
            msg: 'Only png jpeg and jpg type file allowed',
            alertType: 'danger',
          })
        );
      } else {
        formData.append('upload_for', 'PRODUCT_IMAGES');
        formData.append('image', e.target.files[0]);
        formData.append('category_name', findCategoryNameById(values.category));
        formData.append('product_name', values.productName);
        dispatch(ShowUploadLoader({ loading: true, type: 'productImage' }));
        dispatch(UploadProductImages(formData));
        // setUpload(uploadedContent)
        if (e.target.files.length !== 0) {
          setFieldValue('image', 'something');
        } else {
          setFieldValue('image', '');
        }
      }
      e.target.value = '';
    }
  }; // END handleImgChange

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const formData = new FormData();
      const ext = e.target.value.split('.').pop();
      if (values.productMediaType === 'PDF' && ext !== 'pdf') setFieldError('productMediaUrl', 'Only pdf file allowed');
      else {
        /* To get pdf pages */
        const reader = new FileReader();
        reader.readAsBinaryString(e.target.files[0]);
        reader.onloadend = function () {
          setUploadedPDFPageCount(reader.result.match(/\/Type[\s]*\/Page[^s]/g).length);
        };
        formData.append('upload_for', 'Test_Folder');
        formData.append('file', e.target.files[0]);
        formData.append('category_name', findCategoryNameById(values.category));
        formData.append('product_name', values.productName);
        dispatch(ShowUploadLoader({ loading: true, type: 'productMedia' }));
        dispatch(UploadProductFiles(formData));
        // setUpload(uploadedContent)
        if (e.target.files.length !== 0) {
          setFileUrl(productFile?.url);
          setFieldValue('productMediaUrl', productFile?.url?.split('/').pop());
        } else {
          setFieldValue('productMediaUrl', 'BsolkMHI0Ok');
        }
      }
    }
  }; // END handleFileChange

  const handleImageDelete = (data) => {
    const filteredArray = upload.filter((item) => item.file_path !== data.file_path);
    setUpload(filteredArray);
    if (filteredArray.length === 0) {
      setIsImageUploaded(false);
      setFieldValue('image', '');
    }
  }; // END handleImageDelete

  const handleFileDelete = (data) => {
    setIsFileUploaded(false);
    setFileUrl('');
    setFieldValue('productMediaUrl', '');
  }; // END handleFileDelete

  const handleCategoryChange = (e, oldValue) => {
    setIsThisShortVideoCat(false);
    switch (findCategoryTypeById(e.target.value)) {
      case 'eMagazines':
        setFieldValue('emagazineYear', String(new Date().getFullYear()));
        setFieldValue('emagazineMonth', String(new Date().getMonth() + 1).padStart(2, '0'));
      case 'eBooks':
        setFieldValue('productMediaType', 'PDF');
        setVideoArray([{ title: '', videoId: '', titleError: '', videoIdError: '' }]);
        if (findCategoryTypeById(oldValue) === 'Audios') {
          setIsFileUploaded(false);
          setFileUrl('');
        }
        break;
      case 'Audios':
        setFieldValue('productMediaType', 'MPG');
        setFieldValue('productMediaUrl', '');
        setFieldValue('subscriptionType', SubscriptionType[0].value);
        setIsFileUploaded(false);
        setFileUrl('');
        setVideoArray([{ title: '', videoId: '', titleError: '', videoIdError: '' }]);
        break;
      case 'Short_Videos':
        setIsThisShortVideoCat(true);
      case 'Videos':
      case 'Bundles':
      case 'Fresh_Arrivals':
      case 'Recently_Viewed':
      default:
        setFieldValue('productMediaType', 'VIDEO');
        setIsFileUploaded(false);
        setFileUrl('');
        // reset all video title and video id
        setVideoArray([{ title: '', videoId: '', titleError: '', videoIdError: '' }]);
      // regain all video data from data or productbyid
      // if(productById?.product_video?.length > 0 && 'Videos' === findCategoryTypeById(e.target.value)){
      // 	const newVideoArray = [];
      // 	productById?.product_video?.map((value,index)=>{
      // 		const infoToAdd = {
      // 			title:value.file_name,
      // 			videoId: value.file_path,
      // 			titleError:"",
      // 			videoIdError:""
      // 		};
      // 		newVideoArray.push(infoToAdd);
      // 		return null;
      // 	})
      // 	setVideoArray(newVideoArray);
      // } else if('eMagazines' === findCategoryTypeById(e.target.value) && productById?.product_video?.length > 0){
      // 	setVideoArray([{title:productById?.product_video[0].file_name||"",videoId:productById?.product_video[0].file_path||"",titleError:"",videoIdError:""}]);
      // }
      // else{
      // 	setVideoArray([{title:"",videoId:"",titleError:"",videoIdError:""}]);
      // }
    }
    formik.handleChange(e);
    // console.log('formik.handleChange(e)', formik.handleChange(e));
  }; // END handleCategoryChange

  const handleProductMediaChange = (e) => {
    console.log(
      'arrConstCategoryType',
      arrConstCategoryType,
      'values.category',
      values.category,
      findCategoryTypeById(values.category)
    );
    // Check Category eMagazine and keep default media type PDF Also
    // Checking Category Short Video and keep default media type Videos
    if (
      findCategoryTypeById(values.category) !== 'Short_Videos' &&
      findCategoryTypeById(values.category) !== 'eMagazines'
    ) {
      setIsFileUploaded(false);
      setFileUrl('');
      setFieldValue('productMediaUrl', '');
      setVideoArray([{ title: '', videoId: '', titleError: '', videoIdError: '' }]);
      formik.handleChange(e);
    }
  }; // END handleProductMediaChange

  const onMp3Change = (e) => {
    setMediaFolderName(e.target.value)
  }

  // let timer = null;
  const handleMp3Change = (e) => {
    if (e.target.value) {
      dispatch(ShowUploadLoader({ loading: true, type: 'productMedia' }));
      dispatch(GetFileByFolderName({ folder_name: e.target.value }));
      setIsFolderNameAvailable(true);
      setChangeMp3(true);
    } else {
      setIsFolderNameAvailable(false);
    }
  }; // END handleMp3Change
 
  const onPdfChange = (e) => {
    setMediaFolderName(e.target.value)
  }

  const handlePdfChange = (e) => {
    // console.log('e pdf', e.target.value);
    if (e.target.value) {
      dispatch(ShowUploadLoader({ loading: true, type: 'productMedia' }));
      dispatch(GetFileByFolderName({ folder_name: e.target.value }));
      setIsFolderNameAvailable(true);
      setChangePdf(true);
   
    } else {
      setIsFolderNameAvailable(false);
    }
  }; // END handleMp3Change

  const handleVideoIdChange = (e, index) => {
    const previousVideoArray = videoArray;
    const obj = previousVideoArray[index];
    setFieldValue('productMediaUrl', 'BsolkMHI0Ok');
    if (e.target.value === '' && !e.target.value.match(/^[a-zA-Z0-9_-]{11}$/)) {
      obj.videoIdError = 'Please enter valid video Id';
    } else {
      obj.videoIdError = '';
    }
    obj.videoId = e.target.value;
    previousVideoArray[index] = obj;
    setVideoArray(previousVideoArray);
  }; // END handleVideoIdChange

  const handleVideoTitleChange = (e, index) => {
    const previousVideoArray = videoArray;
    const obj = previousVideoArray[index];
    if (e.target.value === '') {
      obj.titleError = 'Please enter video title';
    } else {
      obj.titleError = '';
    }
    obj.title = e.target.value;
    previousVideoArray[index] = obj;
    setVideoArray(previousVideoArray);
  }; // END handleVideoTitleChange

  const handleAddRemoveVideoArray = (value, index) => {
    if (value === 'add') {
      if (
        videoArray[index].title !== '' &&
        videoArray[index].videoId !== '' &&
        videoArray[index].videoId.match(/^[a-zA-Z0-9_-]{11}$/)
      ) {
        const newVideoArray = [...videoArray];
        const infoToAdd = {
          title: '',
          videoId: '',
          titleError: '',
          videoIdError: '',
        };
        newVideoArray.push(infoToAdd);
        setVideoArray(newVideoArray);
      } else {
        const newVideoArray = [...videoArray];
        const obj = newVideoArray[index];
        if (newVideoArray[index].title === '') {
          obj.titleError = 'Please enter video title';
        }
        if (newVideoArray[index].videoId === '') {
          obj.videoIdError = 'Please enter valid video Id';
        }
        if (!newVideoArray[index].videoId.match(/^[a-zA-Z0-9_-]{11}$/)) {
          obj.videoIdError = 'Please enter valid video Id';
        }
        newVideoArray[index] = obj;
        setVideoArray(newVideoArray);
      }
    } else if (value === 'delete') {
      const newVideoArray = [...videoArray];
      if (newVideoArray.length > 1) {
        newVideoArray.splice(index, 1);
        setVideoArray(newVideoArray);
      } else {
        dispatch(
          SetNewAlert({
            msg: 'If product media type is video then at least one video title and video id required.',
            alertType: 'danger',
          })
        );
      }
    }
  }; // END handleAddRemoveVideoArray

  /* END Handle Change */

  /* START Actions Functions */

  const validateVideoList = () => {
    let isValidate = true;
    setVideoArray(
      videoArray.map((value, index) => {
        if (value.title === '' || value.title === undefined) {
          value.titleError = 'Video title is required';
          isValidate = false;
        }
        if (value.videoId === '' || value.videoId === undefined) {
          value.videoIdError = 'Video id is required';
          isValidate = false;
        }
        if (value.videoId !== '' && !value.videoId.match(/^[a-zA-Z0-9_-]{11}$/)) {
          value.videoIdError = 'Please enter valid video Id';
          isValidate = false;
        }

        return value;
      })
    );
    return isValidate;
  }; // END validateVideoList

  const viewFile = async (url) => {
    // console.log("url",url)
  
    if (values.productMediaType === 'MPG') {
      const URL = `${process.env.REACT_APP_BASE_URL}/api/get-folder-file-by-path?media_file=${url}`;
      setShowAudioLoader(true);
      fetch(URL, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
        .then((response) => response.blob())
        .then((blob) => {
          // RETRIEVE THE BLOB AND CREATE LOCAL URL
          const _url = window.URL.createObjectURL(blob);
          setShowAudioLoader(false);
          window.open(_url, '_blank').focus(); // window.open + focus
        })
        .catch((err) => {
          console.log(err);
          setShowAudioLoader(false);
        });
    } else {
      const URL = `${process.env.REACT_APP_BASE_URL}/api/get-folder-file-by-path?media_file=${url}`;
      setShowAudioLoader(true);
      fetch(URL, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
        .then((response) => response.blob())
        .then((blob) => {
          // RETRIEVE THE BLOB AND CREATE LOCAL URL
          const _url = window.URL.createObjectURL(blob);
          setShowAudioLoader(false);
          window.open(_url, '_blank').focus(); // window.open + focus
        })
        .catch((err) => {
          console.log(err);
          setShowAudioLoader(false);
        });
    }
  }; // END viewFile

  const validateSubscriptionCheckbox = (subscriptionType) => {
    const messages = [
      'Please select atleast one audio to make product freemium.',
      'All checkboxes are checked, make subscription type as premium.',
    ];
    let selectMsg = 0;
    const lengthOfMp3Files = audioProductList.length;
    const lengthOfChecked = audioProductList?.filter((option) => option.is_premium === 1).length;
    if (subscriptionType === SubscriptionType[1].value) {
      selectMsg = lengthOfChecked === 0 ? 1 : lengthOfChecked === lengthOfMp3Files ? 2 : 0;
    }

    if (selectMsg) {
      dispatch(
        SetNewAlert({
          msg: messages[selectMsg - 1],
          alertType: 'danger',
        })
      );
      return false;
    }
    return true;
  }; // END validateSubscriptionCheckbox

  /* END Actions Functions */

  /* START Formik */

  const initialFormValue = {
    productName: data ? productById?.product_name : '',
    shortDescription: data ? productById?.short_descripton : '',
    longDescription: data ? productById?.long_descripton : '',
    price: data ? productById?.price : '',
    rating: data ? productById?.rating : '',
    image: data ? 'something' : '',
    sequence: data ? productById.sequence : '',
    displayOnHomePage: data ? data.is_display_on_homepage : 0,
    isActive: data ? data.is_active : 1,
    category: data ? data?.category_id : '',
    productMediaType: data ? data?.product_media_type : 'VIDEO',
    // productMediaUrl:data? productById?.product_media_type==="PDF"?"BsolkMHI0Ok":productById?.product_media_type==="VIDEO"?productById?.product_video?.[0]?.file_path:productById?.product_mpg?.[0]?.file_path:""
    productMediaUrl: data
      ? productById?.product_media_type === 'PDF'
        ? productById?.product_pdf[0]?.file_path
        : productById?.product_media_type === 'VIDEO'
        ? productById?.product_video?.[0]?.file_path
        : mediaFolderName
      : '',
    subscriptionType: data
      ? data?.subscription_type !== null
        ? data?.subscription_type
        : 'free'
      : 'free',
    noOfAllowedPages: data ? productById?.product_media_type === 'PDF'
        ? productById.product_pdf[0]?.pdf_page_count === undefined
          ? 0
          : productById.product_pdf[0]?.pdf_page_count
        : 0
      : 0,
    subscriptionCheckbox: [],
    productTags: data
      ? data?.product_tag_references
          ?.map((item, index) =>
            item.product_tag.map((innerItem, innerIndex) => ({ id: innerItem.id, tag_name: innerItem.tag_name }))
          )
          .flat()
      : [],
    emagazineYear: data
      ? String(new Date(productById?.month_of_magazine).getFullYear())
      : String(new Date().getFullYear()),
    emagazineMonth: data
      ? String(new Date(productById?.month_of_magazine).getMonth() + 1).padStart(2, '0')
      : String(new Date().getMonth() + 1).padStart(2, '0'),
    releaseDateOfMagazine: releaseDateMagazine,
    isNewProduct: data ? (data?.is_new_product === null ? 0 : data?.is_new_product) : 0,
    expiryDateOfNewProd: expiryOfNewProd,
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialFormValue,
    validationSchema: productValidationSchema,
    onSubmit: (value) => {
      console.log('value99999', value);
      /* Collect Images From Upload Array Before Submit */
      const productImages = [];
      if (upload.length === 0) {
        return dispatch(
          SetNewAlert({
            msg: 'Please select image.',
            alertType: 'danger',
          })
        );
      }
      upload?.map((value, index) => {
        productImages.push(value.file_path);
        return null;
      });

      if (tagData.length === 0 || new Set(tagData).size !== tagData.length) {
        return dispatch(
          SetNewAlert({
            msg: 'Please select valid tags.',
            alertType: 'danger',
          })
        );
      }

      if (value.productMediaType === 'PDF' && value.subscriptionType === SubscriptionType[1].value) {
        if (value.noOfAllowedPages === 0 || value.noOfAllowedPages === null) {
          return dispatch(
            SetNewAlert({
              msg: 'Please enter valid page count to make product freemium.',
              alertType: 'danger',
            })
          );
        }
        if (uploadedPDFPageCount <= value.noOfAllowedPages && data === null) {
          return dispatch(
            SetNewAlert({
              msg: 'No. of page allowed should be less than actual number of pages in pdf.',
              alertType: 'danger',
            })
          );
        }
      }

      if (value.productMediaType === 'MPG') {
        const checkboxes = document.getElementsByName('subscriptionCheckbox[]');
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < checkboxes.length; i++) {
          if (checkboxes[i].checked) {
            audioProductList[i].is_premium = 1;
          } else {
            audioProductList[i].is_premium = 0;
          }
        }
      }

      if (value.category === 0) {
        return dispatch(
          SetNewAlert({
            msg: 'Please select valid category',
            alertType: 'danger',
          })
        );
      }

      const reqObj = {
        product_name: value.productName,
        short_descripton: value.shortDescription,
        category_id: value.category,
        is_display_on_homepage: value.displayOnHomePage,
        is_new_product: value.isNewProduct,
        is_active: value.isActive,
        sequence: value.sequence,
        long_descripton: value.longDescription,
        product_media_type: value.productMediaType,
        price: value.price,
        rating: value.rating,
        product_images: productImages,
        // "product_media_url": value.productMediaType==="VIDEO"?latestVideoArray:value.productMediaType==="MPG"?fileByFolder?.mp3_files:fileUrl,
        product_media_url: [],
        pdf_page_count: value.noOfAllowedPages,
        subscription_type: value.subscriptionType,
        is_premium: value.subscriptionType === 'premium' ? 1 : 0,
        product_tags: tagData,
        month_of_magazine: null,
        release_date_of_magazine: null,
        new_product_expiry_date: null,
      }; // END reqObj

      if (findCategoryTypeById(value?.category) === 'eMagazines') {
        if (value.emagazineYear === null || value.emagazineYear === null) {
          return dispatch(
            SetNewAlert({
              msg: 'Please select e-magazine year & month properly',
              alertType: 'danger',
            })
          );
        }
        if (
          dayjs(value.releaseDateOfMagazine, 'YYYY-MM-DD+h:mm').format('YYYY-MM-DD') === 'Invalid Date' ||
          Number(dayjs(value.releaseDateOfMagazine).year()) < 2000 ||
          Number(dayjs(value.releaseDateOfMagazine).year()) > 2050
        ) {
          return dispatch(
            SetNewAlert({
              msg: 'Please select e-magazine release date properly',
              alertType: 'danger',
            })
          );
        }
        reqObj.month_of_magazine = `${value.emagazineYear}-${value.emagazineMonth}-01`;
        reqObj.release_date_of_magazine = dayjs(value.releaseDateOfMagazine, 'YYYY-MM-DD+h:mm').format('YYYY-MM-DD');
      }

      if (value.productMediaType === 'VIDEO' && validateVideoList()) {
        videoArray.map((value, index) => {
          const obj = {};
          obj.title = value.title;
          obj.url = value.videoId;
          obj.is_premium = reqObj.is_premium;
          reqObj.product_media_url.push(obj);
          return null;
        });
      } else if (
        value.productMediaType === 'MPG' &&
        fileByFolder?.mp3_files?.length !== 0 &&
        validateSubscriptionCheckbox(value.subscriptionType)
      ) {
        if (value.subscriptionType === SubscriptionType[2].value) {
          reqObj.product_media_url = audioProductList?.map((option) => ({
            file_name: option.file_name,
            is_premium: 1,
          }));
        } else if (value.subscriptionType === SubscriptionType[0].value) {
          reqObj.product_media_url = audioProductList?.map((option) => ({
            file_name: option.file_name,
            is_premium: 0,
          }));
        } else if (value.subscriptionType === SubscriptionType[1].value) {
          reqObj.product_media_url = audioProductList?.map((option) => ({
            file_name: option.file_name,
            is_premium: option.is_premium,
          }));
        }
        // reqObj.product_media_url = fileByFolder?.mp3_files;
        // reqObj.product_media_url = audioProductList?.map((option)=>({
        // 	"file_name": option.file_name,
        // 	"is_premium": option.is_premium
        // }))
        reqObj.subscription_type = value.subscriptionType;
        reqObj.pdf_page_count = 0;
      } else if (value.productMediaType === 'PDF' && fileByFolder?.pdf_files?.length !== 0) {
        // reqObj.product_media_url = {file_name:PDFProductList[0]?.file_name,is_premium: PDFProductList[0]?.is_premium};
        reqObj.product_media_url = pdfProductList[0]?.file_name;
        reqObj.subscription_type = value.subscriptionType;
        reqObj.pdf_page_count = reqObj.subscription_type === SubscriptionType[1].value ? value.noOfAllowedPages : '';
      } else return false;

      if (reqObj.subscription_type === SubscriptionType[2].value) {
        reqObj.is_premium = 1;
        // reqObj.product_media_url = is_premium;
      }

      if (value.isNewProduct === 1) {
        reqObj.new_product_expiry_date = dayjs(value.expiryDateOfNewProd, 'YYYY-MM-DD+h:mm').format('YYYY-MM-DD');
      }

      if (data && isImageUploaded) dispatch(EditProduct(reqObj, data.id));
      else {
        dispatch(AddProduct(reqObj));
        // eslint-disable-next-line no-prototype-builtins
        if (props.hasOwnProperty('onProductInsert'))
          setTimeout(() => {
            props.onProductInsert(true);
          }, 2000);
      }

      // productMediaFolderName = null;
      fileByFolder.length = 0;
      productById.length = 0;
      // else {
      // 	// dispatch(SetEmptyFolderName())
      // }
      return true;
    },
  }); // END useFormik

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setFieldValue, setFieldError } = formik;

  const videoArrayLength = videoArray.length;
  const getDataRun = React.useRef(true);

  if (isSubmitting) {
    if (getDataRun.current) {
      getDataRun.current = false;
      if (values.productMediaType === MediaType[1].value) validateVideoList();
      if (tagData.length === 0) setTagValidation(true);
      // if(values.productMediaType === MediaType[0].value) setFieldValue("productMediaUrl", productFile?.url?.split('/').pop());
    }
  }
  const [tag, setTag] = useState(tagsForDropdown);
  // console.log("productMediaFolderName",productMediaFolderName)
  // console.log("dataName",dataName)
  

  return (
    <React.Fragment key={1001}>
      <Dialog
        open={props.open}
        // onClose={props.onClose}
        className="dialog-box-section"
      >
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={showAudioLoader}>
          <CircularProgress color="primary" />
        </Backdrop>
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => {
            // productMediaFolderName = null;
            fileByFolder.length = 0;
            productById.length = 0;
            props.onClose();
          }}
        >
          Product
        </BootstrapDialogTitle>
        <DialogContent dividers>
          {showLoader ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '400px',
                width: '400px',
              }}
            >
              <CircularProgress style={{ color: '#FFA728' }} />
            </div>
          ) : (
            <Grid item container spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  id="category"
                  name="category"
                  select
                  label="Category*"
                  onChange={(e) => {
                    handleCategoryChange(e, values.category);
                  }}
                  value={values.category}
                  fullWidth
                  error={Boolean(touched.category && errors.category)}
                  helperText={touched.category && errors.category}
                  // {...getFieldProps('category')}
                >
                  {categoriesForDropdown?.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.category_name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  id="outlined-name-input"
                  label="Product Name*"
                  inputProps={{ maxLength: 50 }}
                  type="text"
                  fullWidth
                  error={Boolean(touched.productName && errors.productName)}
                  helperText={touched.productName && errors.productName}
                  {...getFieldProps('productName')}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="outlined-name-input"
                  label="Short Description*"
                  inputProps={{ maxLength: 100 }}
                  type="text"
                  fullWidth
                  error={Boolean(touched.shortDescription && errors.shortDescription)}
                  helperText={touched.shortDescription && errors.shortDescription}
                  {...getFieldProps('shortDescription')}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  multiline
                  rows={2}
                  id="outlined-name-input"
                  label="Long Description*"
                  inputProps={{ maxLength: 200 }}
                  type="text"
                  fullWidth
                  error={Boolean(touched.longDescription && errors.longDescription)}
                  helperText={touched.longDescription && errors.longDescription}
                  {...getFieldProps('longDescription')}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="price"
                  name="price"
                  label="Price*(₹)"
                  inputProps={{ maxLength: 10 }}
                  onChange={(e) => {
                    if (e.target.value.length === 6) {
                      return;
                    }
                    setFieldValue('price', e.target.value);
                    formik.handleChange(e);
                  }}
                  value={values.price}
                  type="number"
                  fullWidth
                  error={Boolean(touched.price && errors.price)}
                  helperText={touched.price && errors.price}
                  // {...getFieldProps('price')}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="rating"
                  name="rating"
                  label="Rating*"
                  inputProps={{ maxLength: 1 }}
                  type="number"
                  onChange={(e) => {
                    if (e.target.value.length === 2) {
                      return;
                    }
                    setFieldValue('rating', e.target.value);
                    formik.handleChange(e);
                  }}
                  value={values.rating}
                  fullWidth
                  error={Boolean(touched.rating && errors.rating)}
                  helperText={touched.rating && errors.rating}
                  // {...getFieldProps('rating')}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  id="sequence"
                  name="sequence"
                  label="Sequence*"
                  inputProps={{ maxLength: 5 }}
                  type="number"
                  onChange={(e) => {
                    if (e.target.value.length === 6) {
                      return;
                    }
                    setFieldValue('sequence', e.target.value);
                    formik.handleChange(e);
                  }}
                  value={values.sequence}
                  fullWidth
                  error={Boolean(touched.sequence && errors.sequence)}
                  helperText={touched.sequence && errors.sequence}
                  // {...getFieldProps('sequence')}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  id="DisplayStatus"
                  select
                  label="Display On Home Page?"
                  fullWidth
                  error={Boolean(touched.displayOnHomePage && errors.displayOnHomePage)}
                  helperText={touched.displayOnHomePage && errors.displayOnHomePage}
                  {...getFieldProps('displayOnHomePage')}
                >
                  {DisplayStatusArray.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  id="ActiveStatus"
                  select
                  name="status"
                  label="Is Active?"
                  fullWidth
                  error={Boolean(touched.isActive && errors.isActive)}
                  helperText={touched.isActive && errors.isActive}
                  {...getFieldProps('isActive')}
                >
                  {DisplayStatusArray.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  id="isNewProduct"
                  select
                  name="isNewProduct"
                  label="Is New Product?"
                  fullWidth
                  error={Boolean(touched.isNewProduct && errors.isNewProduct)}
                  helperText={touched.isNewProduct && errors.isNewProduct}
                  {...getFieldProps('isNewProduct')}
                >
                  {DisplayStatusArray.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              {values.isNewProduct === 1 && (
                <Grid xs={12} sm={12} md={12} item container spacing={3}>
                  <Grid item xs={6}>
                    <InputLabel sx={{ mb: '8px', fontWeight: 600 }}>Expiry date of new product</InputLabel>
                  </Grid>
                  <Grid item xs={6}>
                    <LocalizationProvider sx={{ width: '100%' }} dateAdapter={AdapterDayjs}>
                      <DatePicker
                        fullWidth
                        id="expiryDateOfNewProd"
                        name="expiryDateOfNewProd"
                        label="Expiry Date *"
                        // inputFormat="DD-MM-YYYY"
                        format="DD-MM-YYYY"
                        views={['day', 'year', 'month']}
                        // disablePast
                        onChange={(newValue) => {
                          // console.log('newValue', newValue);
                          // setExpiryOfNewProd(dayjs(newValue));
                          setFieldValue('expiryDateOfNewProd', dayjs(newValue));
                          formik.handleChange();
                        }}
                        // defaultValue={dayjs('2022-04-17')}
                        value={values.expiryDateOfNewProd}
                        error={Boolean(touched.expiryDateOfNewProd && errors.expiryDateOfNewProd)}
                        helperText={touched.expiryDateOfNewProd && errors.expiryDateOfNewProd}
                        // {...getFieldProps('expiryDateOfNewProd')}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              )}
              <Grid item xs={12} sm={12} md={12}>
                <Autocomplete
                  multiple
                  id="tags"
                  options={tag.filter((option) => values.productTags.every((item) => item.id !== option.id))}
                  // options={tag}
                  getOptionLabel={(option) => `${option.tag_name}`}
                  // getOptionLabel={(option) => option.title}
                  onChange={(e, newValue) => {
                    // console.log("jhgjgftukftfty")
                    setTagData(newValue.map((item, index) => item.id) || []);
                    if (newValue.length === 0) {
                      setTagValidation(true);
                    } else {
                      setTagValidation(false);
                    }
                    
                    // const tags = tag.filter((option) => newValue.every((item) => item.id !== option.id));
                    // setTag(tags);
                  }}
                  filterSelectedOptions
                  // selectOnFocus
                  // freeSolo
                  // autoSelect
                  // limitTags={2}
                  defaultValue={values?.productTags || []}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip variant="outlined" label={option.tag_name} {...getTagProps({ index })} />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      variant="outlined"
                      name="tags"
                      label="Tags *"
                      {...params}
                      placeholder={
                        tagsForDropdown?.length > 0
                          ? tagData?.length !== tagsForDropdown?.length
                            ? 'Select tags'
                            : ''
                          : ''
                      }
                    />
                  )}
                />
                <div className="errorMessage">{tagValidation && 'Tags is required.'}</div>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  id="productMediaType"
                  select
                  name="productMediaType"
                  label="Media Type*"
                  fullWidth
                  value={values.productMediaType}
                  onChange={(e) => {
                    handleProductMediaChange(e);
                  }}
                  error={Boolean(touched.productMediaType && errors.productMediaType)}
                  helperText={touched.productMediaType && errors.productMediaType}
                  // {...getFieldProps('productMediaType')}
                >
                  {MediaType.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              {findCategoryTypeById(values.category) === 'eMagazines' ? (
                <>
                  <Grid xs={12} sm={12} md={12} item container spacing={3}>
                    <Grid item xs={4}>
                      <InputLabel sx={{ mb: '8px', fontWeight: 600 }}>Month Of Magazine</InputLabel>
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        id="emagazineMonth"
                        select
                        name="emagazineMonth"
                        label="Month *"
                        fullWidth
                        value={values.emagazineMonth}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                        error={Boolean(touched.emagazineMonth && errors.emagazineMonth)}
                        helperText={touched.emagazineMonth && errors.emagazineMonth}
                        // {...getFieldProps('emagazineMonth')}
                      >
                        {Months.map((option) => (
                          <MenuItem key={option.id} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        id="emagazineYear"
                        select
                        name="emagazineYear"
                        label="Year *"
                        fullWidth
                        value={values.emagazineYear}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                        error={Boolean(touched.emagazineYear && errors.emagazineYear)}
                        helperText={touched.emagazineYear && errors.emagazineYear}
                        // {...getFieldProps('emagazineYear')}
                      >
                        {Array(
                          new Date().getFullYear() - (new Date().getFullYear() - (new Date().getFullYear() - 2019))
                        )
                          .fill('')
                          .map((v, index, array) => new Date().getFullYear() - index)
                          .map((option) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                      </TextField>
                    </Grid>
                  </Grid>
                  <Grid xs={12} sm={12} md={12} item container spacing={3}>
                    <Grid item xs={4}>
                      <InputLabel sx={{ mb: '8px', fontWeight: 600 }}>Release Date</InputLabel>
                    </Grid>
                    <Grid item xs={8}>
                      <LocalizationProvider sx={{ width: '100%' }} dateAdapter={AdapterDayjs}>
                        <DatePicker
                          fullWidth
                          id="releaseDateOfMagazine"
                          name="releaseDateOfMagazine"
                          label="Release Date *"
                          // inputFormat="DD-MM-YYYY"
                          format="DD-MM-YYYY"
                          views={['day', 'year', 'month']}
                          // disablePast
                          minDate={dayjs(MinDateValue)}
                          maxDate={dayjs(MaxDateValue)}
                          // open={openDatePicker.startDate}
                          // onOpen={() => setOpenDatePicker({startDate: true, endDate: false})}
                          // onClose={() => setOpenDatePicker({startDate: false, endDate: false})}
                          // slotProps={{ textField: { fullWidth: true, onClick: (e) => setOpenDatePicker({startDate: true, endDate: false})}  }}
                          onChange={(newValue) => {
                            // setReleaseDateMagazine(dayjs(newValue));
                            setFieldValue('releaseDateOfMagazine', dayjs(newValue));
                            formik.handleChange();
                          }}
                          // defaultValue={dayjs('2022-04-17')}
                          value={values.releaseDateOfMagazine}
                          error={Boolean(touched.releaseDateOfMagazine && errors.releaseDateOfMagazine)}
                          helperText={touched.releaseDateOfMagazine && errors.releaseDateOfMagazine}
                          // {...getFieldProps('releaseDateOfMagazine')}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                </>
              ) : null}
              {
                // values.productMediaType !== MediaType[1].value ?
                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    id="subscriptionType"
                    select
                    name="subscriptionType"
                    label="Subscription Type*"
                    // InputLabelProps={{ shrink: true }}
                    fullWidth
                    value={values.subscriptionType}
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                    error={Boolean(touched.subscriptionType && errors.subscriptionType)}
                    helperText={touched.subscriptionType && errors.subscriptionType}
                    // {...getFieldProps('subscriptionType')}
                  >
                    {SubscriptionType.map((option) => (
                      <MenuItem key={option.id} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                // : null
              }

              {values.productMediaType === 'VIDEO' ? (
                <React.Fragment key={2001}>
                  {videoArray?.map((value, index) => (
                    // eslint-disable-next-line react/jsx-key
                    <Grid item key={index + 1} xs={12} sm={12} md={12} lg={12}>
                      <Stack
                        style={{ marginTop: '10px', marginLeft: '15px' }}
                        direction="row"
                        spacing={2}
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                      >
                        <Grid item xs={7} sm={8} md={8} lg={8}>
                          <TextField
                            id="videoTitle"
                            name="videoTitle"
                            label="Video Title*"
                            type="text"
                            size="medium"
                            onChange={(e) => {
                              handleVideoTitleChange(e, index);
                              formik.handleChange(e);
                            }}
                            value={value.title}
                            inputProps={{ style: { fontSize: '0.9rem' } }}
                            fullWidth
                            // error={Boolean(touched.videoTitle && errors.videoTitle)}
                            // helperText={touched.videoTitle && errors.videoTitle}
                            // {...getFieldProps('title')}
                          />
                          {/* <div className='errorMessage'>{touched.videoTitle && errors.videoTitle}</div> */}
                          <div className="errorMessage">{value.titleError}</div>
                        </Grid>
                        <Grid item xs={2} sm={4} md={4} lg={4}>
                          <TextField
                            id="videoId"
                            name="videoId"
                            label="Video Id*"
                            type="text"
                            size="medium"
                            inputProps={{ style: { fontSize: '0.8rem' } }}
                            // fullWidth
                            onChange={(e) => {
                              handleVideoIdChange(e, index);
                              formik.handleChange(e);
                            }}
                            value={value.videoId}
                            // error={Boolean(touched.videoId && errors.videoId)}
                            // helperText={touched.videoId && errors.videoId}
                            // {...getFieldProps('videoId')}
                          />
                          {/*  <div className='errorMessage'>{touched.videoId && errors.videoId}</div> */}
                          <div className="errorMessage">{value.videoIdError}</div>
                        </Grid>
                        {!isThisShortVideoCat && (
                          <Grid item xs={1} sm={1} md={1} lg={1} style={{ marginTop: '10px' }}>
                            {index + 1 < videoArrayLength ? (
                              <AddRemoveAvatar
                                style={{ color: 'red' }}
                                onClick={() => handleAddRemoveVideoArray('delete', index)}
                              >
                                <Iconify icon={'eva:minus-circle-outline'} sx={{ width: 22, height: 22 }} />
                              </AddRemoveAvatar>
                            ) : (
                              _.times(2, (i) => (
                                <AddRemoveAvatar
                                  style={{ color: i < 1 ? 'red' : 'green' }}
                                  onClick={() => handleAddRemoveVideoArray(i < 1 ? 'delete' : 'add', index)}
                                >
                                  <Iconify
                                    icon={i < 1 ? 'eva:minus-circle-outline' : 'eva:plus-circle-fill'}
                                    sx={{ width: 22, height: 22 }}
                                  />
                                </AddRemoveAvatar>
                              ))
                            )}
                          </Grid>
                        )}
                      </Stack>
                    </Grid>
                  ))}
                  <Grid item xs={12} sm={12} md={12}>
                    <InputLabel sx={{ mt: '5px', fontSize: '1.0rem' }}>
                      <center>
                        {'Note* : Video url will be completed after adding video id'}
                        <br />
                        {'https://www.youtube.com/watch?v={video id}'}
                      </center>
                    </InputLabel>
                  </Grid>
                </React.Fragment>
              ) : values.productMediaType === 'MPG' ? (
                <React.Fragment key={3001}>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      id="productMediaUrl"
                      name="productMediaUrl"
                      label="MP3 Folder Name*"
                      type="text"
                      fullWidth
                      value={ mediaFolderName}
                      // defaultValue={ mediaFolderName}
                      onChange={(e) => {
                        onMp3Change(e)
                        formik.handleChange(e);
                      }}
                      onBlur={(e) => {
                        handleMp3Change(e);
                        formik.handleChange(e);
                      }}
                      // error={Boolean(touched.productMediaUrl && errors.productMediaUrl)}
                      // helperText={touched.productMediaUrl && errors.productMediaUrl}
                      // {...getFieldProps('productMediaUrl')}
                    />
                  </Grid>
                  {isFolderNameAvailable && values.productMediaUrl ? (
                    showUploadLoader?.loading && showUploadLoader.type === 'productMedia' ? (
                      <Grid item xs={12} sm={12} md={12}>
                        <CircularProgress style={{ color: '#FFA728' }} />
                      </Grid>
                    ) : audioProductList?.length === 0 ? (
                      <Grid item xs={12} sm={12} md={12}>
                        <div className="errorMessage">
                          {errors.productMediaUrl ? 'MP3 folder name required' : 'MP3 folder not found'}
                        </div>
                      </Grid>
                    ) : (
                      audioProductList?.map((value, ind) => (
                        <Stack
                          key={ind + 1}
                          direction="row"
                          spacing={2}
                          alignItems="center"
                          style={{ width: '100%', marginTop: '15px', marginLeft: '15px' }}
                        >
                          <Grid item xs={6} sm={6} md={6}>
                            <InputLabel sx={{ mb: '10px', fontWeight: 600 }}>
                              {value.file_name.split('/').pop().split('.')[0]}
                            </InputLabel>
                          </Grid>
                          <Grid item xs={4} sm={4} md={4}>
                            <StyledAvatar onClick={() => viewFile(value.file_path)}>
                              <Iconify icon={'eva:eye-outline'} sx={{ width: 22, height: 22 }} />
                            </StyledAvatar>
                          </Grid>
                          <Grid item xs={4} sm={4} md={4}>
                            {values.subscriptionType === SubscriptionType[0].value ||
                            values.subscriptionType === SubscriptionType[2].value ? (
                              <FormControlLabel
                                control={
                                  values.subscriptionType === SubscriptionType[0].value ? (
                                    <Checkbox type="checkbox" checked={false} />
                                  ) : values.subscriptionType === SubscriptionType[2].value ? (
                                    <Checkbox type="checkbox" checked />
                                  ) : (
                                    <></>
                                  )
                                }
                                disabled
                              />
                            ) : (
                              <Checkbox
                                type="checkbox"
                                id="subscriptionCheckbox"
                                name="subscriptionCheckbox[]"
                                inputProps={{ 'aria-label': 'controlled' }}
                                defaultChecked={value.is_premium === 1}
                                value={value.is_premium}
                              />
                            )}
                          </Grid>
                        </Stack>
                      ))
                    )
                  ) : null}
                  {touched.productMediaUrl && errors.productMediaUrl ? (
                    <Grid item xs={12} sm={12} md={12}>
                      <div className="errorMessage">{'MP3 folder name required'}</div>
                    </Grid>
                  ) : null}
                </React.Fragment>
              ) : (
                <React.Fragment key={4001}>
                  {/* <Grid item xs={12}>
                    <InputLabel sx={{ mb: '10px', fontWeight: 600 }}>
                      {values.productMediaType === 'MPG' ? 'MP3' : values.productMediaType}
                    </InputLabel>
                    {isFileUploaded ? (
                      <>
                        <Stack direction="row" alignItems="center">
                          <Grid item xs={8}>
                            <TextField
                              id="productMediaUrl"
                              name="productMediaUrl"
                              type="text"
                              fullWidth
                              InputProps={{
                                readOnly: true,
                              }}
                              value={
                                values?.productMediaUrl !== undefined
                                  ? values?.productMediaUrl
                                  : productFile?.url !== undefined
                                  ? productFile?.url.split('/').pop()
                                  : fileUrl?.url !== undefined
                                  ? fileUrl?.url.split('/').pop()
                                  : 'kjnnc65174abu78e223r'
                              }
                              error={Boolean(touched.productMediaUrl && errors.productMediaUrl)}
                              helperText={touched.productMediaUrl && errors.productMediaUrl}
                              {...getFieldProps('productMediaUrl')}
                            />
                          </Grid>
                          <Grid xs={1}>
                            <CloseIcon
                              onClick={() => handleFileDelete(fileUrl)}
                              style={{ marginLeft: '10px', cursor: 'pointer' }}
                            />
                          </Grid>
                          {values.subscriptionType === SubscriptionType[1].value ? (
                            <Grid item xs={3} sm={3} md={3}>
                              <TextField
                                id="noOfAllowedPages"
                                name="noOfAllowedPages"
                                label="Page Count*"
                                inputProps={{ maxLength: 3 }}
                                type="number"
                                onChange={(e) => {
                                  if (e.target.value.length < 1 || e.target.value < 1) {
                                    setFieldValue('noOfAllowedPages', null);
                                    return;
                                  }
                                  setFieldValue('noOfAllowedPages', e.target.value);
                                  formik.handleChange(e);
                                }}
                                value={values.noOfAllowedPages}
                                fullWidth
                                // error={Boolean(touched.noOfAllowedPages && errors.noOfAllowedPages)}
                                // helperText={touched.noOfAllowedPages && errors.noOfAllowedPages}
                                // {...getFieldProps('noOfAllowedPages')}
                              />
                            </Grid>
                          ) : null}
                        </Stack>
                      </>
                    ) : showUploadLoader?.loading && showUploadLoader.type === 'productMedia' ? (
                      <CircularProgress style={{ color: '#FFA728' }} />
                    ) : (
                      <IconButton color="#393939" aria-label="upload picture" component="label" onClick={checkData}>
                        <input
                          hidden
                          accept={values.productMediaType === 'PDF' ? 'application/pdf' : '.mp3'}
                          type="file"
                          onChange={handleFileChange}
                        />
                        <CloudUploadOutlinedIcon fontSize="large" />
                      </IconButton>
                    )}
                    <div className="errorMessage">{touched.productMediaUrl && errors.productMediaUrl}</div>
                  </Grid> */}
                  <React.Fragment key={3010}>
                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        id="productMediaUrl"
                        name="productMediaUrl"
                        label="PDF Folder Name*"
                        type="text"
                        fullWidth
                        value={mediaFolderName}
                        // defaultValue={mediaFolderName}
                        onChange={(e)=> {
                          onPdfChange(e);
                          formik.handleChange(e)
                        }}
                        onBlur={(e) => {
                          handlePdfChange(e);
                          formik.handleChange(e);
                        }}
                        // error={Boolean(touched.productMediaUrl && errors.productMediaUrl)}
                        // helperText={touched.productMediaUrl && errors.productMediaUrl}
                        // {...getFieldProps('productMediaUrl')}
                      />
                    </Grid>
                    {isFolderNameAvailable && values.productMediaUrl ? (
                      showUploadLoader?.loading && showUploadLoader.type === 'productMedia' ? (
                        <Grid item xs={12} sm={12} md={12}>
                          <CircularProgress style={{ color: '#FFA728' }} />
                        </Grid>
                      ) : pdfProductList?.length === 0 ? (
                        <Grid item xs={12} sm={12} md={12}>
                          <div className="errorMessage">
                            {errors.productMediaUrl ? 'PDF folder name required' : 'PDF folder not found'}
                          </div>
                        </Grid>
                      ) : (
                        // PDFProductList?.map((value, ind) => (
                        
                        <Stack
                          // key={ind + 1}
                          direction="row"
                          spacing={2}
                          alignItems="center"
                          style={{ width: '100%', marginTop: '15px', marginLeft: '15px' }}
                        >
                            {mediaFolderName?(<>
                          <Grid item xs={6} sm={6} md={6}>
                            <InputLabel sx={{ mb: '10px', fontWeight: 600 }}>
                              {pdfProductList[0]?.file_name.split('/').pop().split('.')[0]}
                            </InputLabel>
                          </Grid>
                          <Grid item xs={4} sm={4} md={4}>
                            <StyledAvatar onClick={() => viewFile(pdfProductList[0]?.file_path)}>
                              <Iconify icon={'eva:eye-outline'} sx={{ width: 22, height: 22 }} />
                            </StyledAvatar>
                          </Grid>
                          </>):""}
                          <Grid item xs={4} sm={4} md={4}>
                            {values.subscriptionType === SubscriptionType[0].value ||
                            values.subscriptionType === SubscriptionType[2].value ? (
                              <FormControlLabel
                                control={
                                  values.subscriptionType === SubscriptionType[0].value ? (
                                    <Checkbox type="checkbox" checked={false} />
                                  ) : values.subscriptionType === SubscriptionType[2].value ? (
                                    <Checkbox type="checkbox" checked />
                                  ) : (
                                    <></>
                                  )
                                }
                                disabled
                              />
                            ) : (
                              <Checkbox
                                type="checkbox"
                                id="subscriptionCheckbox"
                                name="subscriptionCheckbox[]"
                                inputProps={{ 'aria-label': 'controlled' }}
                                defaultChecked={pdfProductList[0].is_premium === 1}
                                value={pdfProductList[0].is_premium}
                              />
                            )}
                          </Grid>
                        </Stack>
                        // ))
                      )
                    ) : null}
                    {touched.productMediaUrl && errors.productMediaUrl ? (
                      <Grid item xs={12} sm={12} md={12}>
                        <div className="errorMessage">{'PDF file name required'}</div>
                      </Grid>
                    ) : null}
                    {values.subscriptionType === SubscriptionType[1].value ? (
                      <Grid item xs={12} sm={12} md={12}>
                        <TextField
                          id="noOfAllowedPages"
                          name="noOfAllowedPages"
                          label="Page Count*"
                          inputProps={{ maxLength: 3 }}
                          type="number"
                          onChange={(e) => {
                            // console.log('count', e.target.value);
                            if (e.target.value < 1) {
                              setFieldValue('noOfAllowedPages', null);
                              return;
                            }
                            setFieldValue('noOfAllowedPages', e.target.value);
                            formik.handleChange(e);
                          }}
                          defaultValue={values?.noOfAllowedPages}
                          fullWidth
                          error={Boolean(touched.noOfAllowedPages && errors.noOfAllowedPages)}
                          helperText={touched.noOfAllowedPages && errors.noOfAllowedPages}
                          {...getFieldProps('noOfAllowedPages')}
                        />
                      </Grid>
                    ) : null}
                  </React.Fragment>
                </React.Fragment>
              )}


              <Grid item xs={12}>
                <InputLabel sx={{ mb: '10px', fontWeight: 600 }}>Product Image</InputLabel>
                <Grid item xs={12}>
                  <Grid item xs={12}>
                    {showUploadLoader?.loading && showUploadLoader.type === 'productImage' ? (
                      <CircularProgress style={{ color: '#FFA728' }} />
                    ) : (
                      <IconButton color="#393939" aria-label="upload picture" component="label" onClick={checkData}>
                        <input hidden accept="image/*" type="file" onChange={handleImgChange} />
                        <CloudUploadOutlinedIcon fontSize="large" />
                      </IconButton>
                    )}
                    <div className="errorMessage">{touched.image && errors.image}</div>
                  </Grid>
                  {upload?.map((value, index) => (
                    <Stack key={index + 1} direction="row" alignItems="center" marginBottom="5px">
                      <Grid item xs={8}>
                        <TextField
                          id="image"
                          name="image"
                          type="text"
                          fullWidth
                          InputProps={{
                            readOnly: true,
                          }}
                          value={value?.file_path?.split('/').pop()}
                          error={Boolean(touched.image && errors.image)}
                          helperText={touched.image && errors.image}
                          // {...getFieldProps('productMediaUrl')}
                        />
                      </Grid>
                      <CloseIcon
                        onClick={() => handleImageDelete(value)}
                        style={{ marginLeft: '10px', cursor: 'pointer' }}
                      />
                    </Stack>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'end', pr: '25px' }}>
          <Button
            variant="outlined"
            onClick={() => {
              setMediaFolderName(null);
              fileByFolder.length = 0;
              productById.length = 0;
              props.onClose();
            }}
            sx={{
              minWidth: '100px',
              color: (theme) => theme.palette.text.darkGrey,
              borderColor: (theme) => theme.palette.text.darkGrey,
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={showUploadLoader.loading}
            onClick={formik.handleSubmit}
            style={{ minWidth: '100px' }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
