import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
/* @mui */
import {
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Button,
    MenuItem,
    IconButton,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { TagsValidationSchema } from '../../utils/validation/TagsValidation';
import { AddTags, EditTags } from '../../actions/TagsAction';
import { DisplayStatusArray } from '../common/constants';
// ----------------------------------------------------------------------

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
        <DialogTitle sx={{ m: 0, p: 2}} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 12,
                        top: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function TagsDialogBox(props) 
{
    const dispatch = useDispatch();
    const {data} = props;
    const { addTagsLog, editTagsLog } = useSelector((state) => ({
        addTagsLog: state.tags.addTagsLog,
        editTagsLog: state.tags.editTagsLog,
    }));

    const firstRun = React.useRef(true);
    React.useEffect(()=>{
        if (firstRun.current) {
            firstRun.current = false;
            return;
        }
        props.onClose()
    },[addTagsLog, editTagsLog])

    const formik = useFormik({
        initialValues: {
          tagName:data?data.tag_name:"",
          isActive: data?data.is_active:1
        },
        validationSchema: TagsValidationSchema,
        onSubmit: (value) => {
            const reqObj = {
                "tag_name": value.tagName,
                "is_active": value.isActive
            }
            if(data) {                
                dispatch(EditTags(reqObj,data.id));
            }
            else {                
                dispatch(AddTags(reqObj));
            }            
        },
    });

    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps,setFieldValue } = formik;

    return (
        <>
            <Dialog
                open={props.open}
                // onClose={props.onClose}
                className="dialog-box-section"
                maxWidth={'sm'}
				fullWidth
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={props.onClose}>
                    Tags
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12}>
                            <TextField
                                id="tagName"
                                name="tagName"
                                label="Tag Name*"                                
                                inputProps={{ maxLength: 40 }}
                                type="text"
                                fullWidth
                                value= {values?.tagName}
                                error={Boolean(touched.tagName && errors.tagName)}
                                helperText={touched.tagName && errors.tagName}
                                {...getFieldProps('tagName')}
                            />
                        </Grid>                                       
                        <Grid item xs={12} sm={12} md={12}>
                            <TextField
                                id="isActive"
                                name="isActive"
                                label="Is Active"
                                select
                                fullWidth
                                error={Boolean(touched.isActive && errors.isActive)}
                                helperText={touched.isActive && errors.isActive}
                                // onChange={formik.handleChange()}
                                value={ values?.isActive }
                                {...getFieldProps('isActive')}
                            >
                                {DisplayStatusArray.map((option) => (
                                    <MenuItem key={option.id} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ justifyContent: "end",pr:"25px" }}>
                    <Button  variant="outlined" onClick={props.onClose} sx={{minWidth:'100px',color:(theme) => theme.palette.text.darkGrey,borderColor:(theme) => theme.palette.text.darkGrey}} >Cancel</Button>
                    <Button variant="contained" onClick={formik.handleSubmit} style={{minWidth:'100px'}}>Save</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
