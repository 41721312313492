import React, { useEffect,useState } from "react";
import {Avatar} from '@mui/material';

export default function ViewImage(props) {
    const [imageList, setImageList] = useState([]);
    // const [showList, setShowList] = useState(false); 
    // useEffect(()=>{        
    //     async function getImageAuthURL() {
    //         if(props?.imagData?.id) {
    //             // const URL = `${process.env.REACT_APP_BASE_URL}/api/get-file/${props?.imagData?.id}`;
    //             const URL = `${process.env.REACT_APP_BASE_URL}/api/get-image-file/${props?.imgID}`;
    //             // const URL = `${process.env.REACT_APP_BASE_URL}/api/get-admin-image-file/${props?.imgID}`;
    //             fetch(URL, { 
    //                 method: "GET",
    //                 headers: { 
    //                     Authorization: `Bearer ${localStorage.getItem("token")}`,
    //                     Origin: ['http://localhost:3000', 'https://uat.playbyplay.tech'],                        
    //                 },
    //                 credentials: 'same-origin',
	// 				redirect: 'follow'
    //             })
    //             .then((response) => response.blob())
    //             .then((blob) => {
    //                 const _url = window.URL.createObjectURL(blob);
    //                 setImageList({ "file_path": _url });
    //             }).catch((err) => {
    //                 console.log('error while fetch image: ',err);
    //             });
    //         }else {
    //             setImageList({ "file_path": '/assets/placeholders/placeholder.png'});
    //         }
    //     }// END getImageAuthURL

    //     getImageAuthURL();
    
    // },[props?.imagData, props?.imgID])

    /* Set Placeholder Image On Fetch Image Data Fail */
    // if(imageList.length === 0)
    //     setImageList({ "file_path": '/assets/placeholders/placeholder.png'}); 
           
    /* return (        
        <Avatar 
            key={imageList.file_path} 
            id={imageList.file_path} 
            alt="Travis Howard" 
            variant='rounded' 
            src={imageList.file_path} 
            sx={{ width: 60, height: 60 }} 
        />
    ); */

    // For non authorization
    return (<Avatar key={props?.imgID} id={props?.imgID} alt="Travis Howard" variant='rounded' src={`${process.env.REACT_APP_BASE_URL}/api/get-admin-image-file/${props?.imgID}`} sx={{ width: 60, height: 60 }} />);
   
}

