import React,{useState} from "react";
import { styled } from '@mui/material/styles';
import { sentenceCase } from 'change-case';
import { Navigate, useNavigate } from "react-router-dom";

import {
	Typography,
	Box,
	Button,
	Card,
	TableContainer,
	Table,
	TableBody,
	TableRow,
	TableCell,
	Divider,
	Stack,
	Toolbar
} from '@mui/material';
import { PagesHead } from "../../sections/@dashboard/common";
import Iconify from "../iconify/Iconify";
import Scrollbar from "../scrollbar/Scrollbar";
import Label from "../label/Label";
import ViewProductDialog from "../DiaglogBox/ViewProductDialog";
import ViewImage from '../image-upload/ViewImage';

const TABLE_HEAD = [
	{ id: 'name', label: 'Name', alignRight: false },
	{ id: 'price', label: 'Price', alignRight: false },
];

const StyledRoot = styled(Toolbar)(({ theme }) => ({
	height: 70,
	display: 'flex',
	justifyContent: 'space-between',
	padding: theme.spacing(0, 1, 0, 3),
}));

export default function LatestProduct(props) {
	const navigate = useNavigate();
	const [page, setPage] = useState(0);
	const [data, setData] = React.useState(null);
	const [viewProductDialogOpen, setViewProductDialogOpen] = React.useState(false);
	const [rowsPerPage, setRowsPerPage] = useState(10);

	const onCloseIconClick = (data) => {
		props.handleDelete(data);
	};

	const navigateToSection = () => {
		navigate('/dashboard/products', { replace: true });
	}
	const handleViewProductDialogClose = () => {
		setViewProductDialogOpen(false);
		setData(null);
	}

	const handleViewProductDialogOpen = (data) => {
		const productData = {id:data.productId}
		setViewProductDialogOpen(true);
		setData(productData);
	}

	return (
		<Card>
			{
				viewProductDialogOpen?
				<ViewProductDialog
				open={viewProductDialogOpen}
				data={data}
				onClose={handleViewProductDialogClose}
				/>:
				null
			}
			<StyledRoot>
				<Stack direction="row" alignItems="center" justifyContent="space-between" sx={{width:'100%'}}>
					<Typography variant="h6">
						Latest Product
					</Typography>
					<Button variant="contained"  onClick={()=>navigateToSection()}>
						View all
					</Button>
				</Stack>
			</StyledRoot>
			<Scrollbar>
				<TableContainer>
					<Table aria-label="simple table" size="medium">
						<PagesHead headLabel={TABLE_HEAD} />
						<TableBody>
						{
							props?.latestProduct?.map((row, index) => (
								<TableRow 
									key={index} 
									style={{cursor:'pointer'}} 
									onClick={() => handleViewProductDialogOpen(row.product)}>
									<TableCell>
										<Stack direction="row" alignItems="center" spacing={2}>                        
											<ViewImage 
												imagData={row.uploaded_files?.images.length!==0?row.uploaded_files?.images?.[0]:[]} 
												imgID={row.uploaded_files?.images?.[0]?.file_upload_id}/>
											<Box>
												{row.product.product_name}
											</Box>
										</Stack>
									</TableCell>
									<TableCell>
										₹{row.product.price}
									</TableCell>
									{/* 
										<TableCell>
											<Label color={(row.product.is_active === 0 && 'error') || 'success'}>{sentenceCase(row.is_active?"Active":"Inactive")}</Label>
										</TableCell> 
									*/}
								</TableRow>
							))
						}
						</TableBody>
					</Table>
				</TableContainer>
			</Scrollbar>
		</Card>    
	);
}

