import {
    CREATE_TAGS,
    DELETE_TAGS,
    GET_ALL_TAGS,
    GET_TAGS_BY_ID,
    GET_TAGS_FOR_DROPDOWN,
    RESET_STATE,
    UPDATE_TAGS,
} from "../actions/Types";

const INIT_STATE = {
    tags:[],
    tagsForDropdown:[],
    tagsById:{},
    pageInfo:{},
    addTagsLog:false,
    editTagsLog: false,
    deleteTagsLog : false,
};
    
export default function TagsReducer (state = INIT_STATE, action)  {
    const { type, payload } = action;  
    switch (type) {
        case GET_ALL_TAGS:
            // console.log('GET_ALL_TAGS reducer', payload);
            return {
                ...state,
                tags: payload.data.data,
                pageInfo: payload.data
            };
        case UPDATE_TAGS:
            return {
                ...state,
                editTagsLog: !state.editTagsLog,
            };
        case CREATE_TAGS:
            return {
                ...state,
                addTagsLog: !state.addTagsLog,
            };
        case DELETE_TAGS:
            console.log('delete tags reducer ', "state.deleteTagsLog", state.deleteTagsLog);
            return {
                ...state,
                deleteTagsLog: !state.deleteTagsLog,
            };
        case GET_TAGS_FOR_DROPDOWN:
            return {
                ...state,
                tagsForDropdown: payload.data,
            };
        case GET_TAGS_BY_ID:
            return {
                ...state,
                tagsById: payload.data,
            };
        case RESET_STATE:
            return { ...INIT_STATE };
        default:
            return state;
    }
};
    