import * as React from 'react';
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Grid,
  Button,
  MenuItem,
  IconButton,
  Divider,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import DialogContentText from '@mui/material/DialogContentText';
import Box from '@mui/material/Box';
import caution from '../../assets/caution.png';

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
      <DialogTitle sx={{ m: 0, p: 2}} {...other}>
          {children}
          {onClose ? (
              <IconButton
                  aria-label="close"
                  onClick={onClose}
                  sx={{
                      position: 'absolute',
                      right: 12,
                      top: 8,
                  }}
              >
                  <CloseIcon />
              </IconButton>
          ) : null}
      </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function BannerImageDialog(props) {
  const { open } = props;


  const handleClose = () => {
    props.handleClose(false);
  };

  const handleTrueClose = () => {
    props.handleClose(true);
  };

  return (
    <>
      <Dialog
        open={open}
        // onClose={handleClose}
        hasCloseButton
        style={{ maxWidth: "100%", maxHeight: "100%" }}
      >
        <img
      style={{ width: 'auto', height: '100%' }}
      src={props.imageData}
      alt="banner"
    />

    </Dialog>
    </>
  );}
