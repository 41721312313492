import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

// @mui
import {
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Paper,
    Grid,
    Button,
    MenuItem,
    IconButton,
    InputAdornment,
    InputLabel,
} from '@mui/material';


import CloseIcon from '@mui/icons-material/Close';

import { changePasswordValidationSchema } from '../../utils/validation/ChangePasswordValidation';
import { ChangePassword } from '../../actions/AuthActions';
import Iconify from '../iconify/Iconify';


// import { makeStyles } from '@material-ui/core/styles';
// import './common/category.css';


// ----------------------------------------------------------------------
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2}} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 12,
                        top: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};
const DisplayStatusArray = [
    {
      id: 1,
      value: 1,
      label: 'Yes'
    },
    {
      id: 2,
      value: 0,
      label: 'No'
    }
  ]

export default function ChangePasswordDialog(props) {
    const dispatch = useDispatch();
    const {data} = props;

    const [showPassword, setShowPassword] = useState(false);
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const { changePasswordLog} = useSelector((state) => ({
        changePasswordLog: state.auth.changePasswordLog,
      }));

    const firstRun = React.useRef(true);
    React.useEffect(()=>{
      if (firstRun.current) {
        firstRun.current = false;
        return;
      }
      props.onClose()
    },[changePasswordLog])


    const formik = useFormik({
        initialValues: {
          password:"",
          oldPassword:"",
          confirmPassword:""
        },
        validationSchema: changePasswordValidationSchema,
        onSubmit: (value) => {
            const reqObj = {
                "old_password":value.oldPassword,
                "new_password": value.password
            }
            dispatch(ChangePassword(reqObj));
            
        },
      });
    
      const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps,setFieldValue } = formik;

    return (
        <>

            <Dialog
                open={props.open}
                // onClose={props.onClose}
                className="dialog-box-section"
                sx={{
                    "& .MuiDialog-container": {
                      "& .MuiPaper-root": {
                        maxWidth: "500px", 
                      },
                    },
                  }}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={props.onClose}>
                    Change Password
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12}>
                        <InputLabel  >
                        Old password
                        </InputLabel>
                        <TextField
                        id="oldPassword"
                        name="oldPassword"
                        placeholder='Enter your old password'
                        type={showOldPassword ? 'text' : 'password'}
                        style={{marginTop:'2px',width:'100%'}}
                        InputProps={{
                            endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setShowOldPassword(!showOldPassword)} edge="end">
                                <Iconify icon={showOldPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                </IconButton>
                            </InputAdornment>
                            ),
                        }}
                        {...getFieldProps('oldPassword')}
                        error={Boolean(touched.oldPassword && errors.oldPassword)}
                        helperText={touched.oldPassword && errors.oldPassword}
                        />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                        <InputLabel  >
                        New password
                        </InputLabel>
                        <TextField
                        id="password"
                        name="password"
                        placeholder='Enter your new password'
                        type={showPassword ? 'text' : 'password'}
                        style={{marginTop:'2px',width:'100%'}}
                        InputProps={{
                            endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                </IconButton>
                            </InputAdornment>
                            ),
                        }}
                        {...getFieldProps('password')}
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                        />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                        <InputLabel >
                        Confirm password
                        </InputLabel>
                        <TextField
                        id="confirmPassword"
                        name="confirmPassword"
                        placeholder='Confirm your new password'
                        type={showConfirmPassword ? 'text' : 'password'}
                        style={{marginTop:'2px',width:'100%'}}
                        InputProps={{
                            endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)} edge="end">
                                <Iconify icon={showConfirmPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                </IconButton>
                            </InputAdornment>
                            ),
                        }}
                        {...getFieldProps('confirmPassword')}
                        error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                        helperText={touched.confirmPassword && errors.confirmPassword}
                        />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ justifyContent: "end",pr:"25px" }}>
                    <Button  variant="outlined" onClick={props.onClose} sx={{minWidth:'100px',color:(theme) => theme.palette.text.darkGrey,borderColor:(theme) => theme.palette.text.darkGrey}} >Cancel</Button>
                    <Button variant="contained" onClick={formik.handleSubmit} style={{minWidth:'100px'}}>Save</Button>
                </DialogActions>
            </Dialog>

        </>
    );
}
