import formDataJWTServer from '../api/formDataJWTServer';
import { SetNewAlert } from './AlertActions';
import { HandleExceptionWithSecureCatch, HandleManualException } from './CombineCatch';
import { ShowUploadLoader } from './CommonAction';
import { GET_FILE_BY_FOLDER_NAME, UPLOAD_BANNER_IMAGE, UPLOAD_PRODUCT_FILES, UPLOAD_PRODUCT_IMAGE, UPLOAD_NOTIFICATION_IMAGE, UPLOAD_BUNDLE_IMAGE } from './Types';

const UploadBannerImage = (params) => async (dispatch) => {
	try {
		// const response = await formDataJWTServer.post('/api/upload-image', params);
		const response = await formDataJWTServer.post('/api/upload-banner-image', params); // For prod and development
		if (response.data.meta.status === 200) {
			dispatch({
				type: UPLOAD_BANNER_IMAGE,
				payload: response.data,
			});
		} else {
			dispatch(HandleManualException(response.data));
		}
	} catch (e) {
		dispatch(HandleExceptionWithSecureCatch(e));
	}
};

const UploadProductImages = (params) => async (dispatch) => {
	try {
		const response = await formDataJWTServer.post('/api/upload-image', params);
		if (response.data.meta.status === 200) {
			dispatch({
				type: UPLOAD_PRODUCT_IMAGE,
				payload: response.data,
			});
		} else {
			dispatch(HandleManualException(response.data));
		}
	} catch (e) {
		dispatch(HandleExceptionWithSecureCatch(e));
	}
};

const UploadProductFiles = (params) => async (dispatch) => {
	try {
		const response = await formDataJWTServer.post('/api/upload-file', params);
		// const response = {
		//   data : {
		//     "message": "File uploaded successfully!",
		//     "data": {
		//       "url": "https://www.africau.edu/images/default/sample.pdf"
		//     }
		//   }
		// }
		if (response.data.meta.status === 200) {
			dispatch({
				type: UPLOAD_PRODUCT_FILES,
				payload: response.data,
			});
		} else {
			dispatch(HandleManualException(response.data));
		}
	} catch (e) {
		if (!e.response) {
			dispatch(
				SetNewAlert({
					msg: 'Maximum File Size Limit Exceed',
					alertType: 'danger',
				})
			);
			dispatch(ShowUploadLoader({ loading: false, type: '' }));
		}
		dispatch(HandleExceptionWithSecureCatch(e));
	}
};

const GetUploadedFile = (params) => async (dispatch) => {
  	try {
		const response = await formDataJWTServer.get(`/api/admin-get-file/${params}`);
		// const response = {
		//   data : {
		//     "message": "File uploaded successfully!",
		//     "data": {
		//       "url": "https://www.africau.edu/images/default/sample.pdf"
		//     }
		//   }
		// }
		// if(response.data.meta.status===200){
		// dispatch({
		//   type: UPLOAD_PRODUCT_FILES,
		//   payload: response.data,
		// });
		// }
		// else {
		//   dispatch(HandleManualException(response.data))
		// }
	} catch (e) {
		dispatch(HandleExceptionWithSecureCatch(e));
	}
};

const GetFileByFolderName = (params) => async (dispatch) => {
	try {
		const response = await formDataJWTServer.post('/api/get-folder-files', params);

		if (response.data.meta.status === 200) {
			dispatch({
				type: GET_FILE_BY_FOLDER_NAME,
				payload: response.data,
			});
		} else {
			dispatch({
				type: GET_FILE_BY_FOLDER_NAME,
				payload: {
					data: {
						mp3_files: [],
					},
				},
			});
			dispatch(HandleManualException(response.data));
		}
	} catch (e) {
		dispatch(HandleExceptionWithSecureCatch(e));
	}
};

const SetEmptyFolderName = () => async (dispatch) => {
	try {
		dispatch({
			type: GET_FILE_BY_FOLDER_NAME,
			payload: {
				data: {
					mp3_files: [],
				},
			},
		});
	} catch (e) {
		console.log(e);
	}
};

const UploadNotificationImage = (params) => async (dispatch) => {
	try {
		const response = await formDataJWTServer.post('/api/upload-notification-image', params);
		if (response.data.meta.status === 200) {
			dispatch({
				type: UPLOAD_NOTIFICATION_IMAGE,
				payload: response.data,
			});
		} else {
			dispatch(HandleManualException(response.data));
		}
	} catch (e) {
		dispatch(HandleExceptionWithSecureCatch(e));
	}
};

const UploadBundleImage = (params) => async (dispatch) => {
	try {
		const response = await formDataJWTServer.post('/api/upload-image', params);
		// console.log('UploadBundleImage action response', response);
		if (response.data.meta.status === 200) {
			dispatch({
				type: UPLOAD_BUNDLE_IMAGE,
				payload: response.data,
			});
		} else {
			dispatch(HandleManualException(response.data));
		}
	} catch (e) {
		dispatch(HandleExceptionWithSecureCatch(e));
	}
};

export {
	UploadBannerImage,
	UploadProductImages,
	UploadProductFiles,
	GetUploadedFile,
	GetFileByFolderName,
	SetEmptyFolderName,
	UploadNotificationImage,
	UploadBundleImage
};
