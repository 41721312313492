import * as Yup from "yup";


export const categoriesValidationSchema = Yup.object().shape({
        name: Yup.string()
                .trim()
                .min(3, "Minimum 3 characters required")
                .max(30, "Too Long!")
                // .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed")
                .required("Category name is required."),
        specialNote: Yup.string()
                .trim()
                .min(3, "Minimum 3 characters required")
                .max(75, "Too Long!"),
        sequence: Yup.number().positive().required("Sequence is required"),
        displayOnHomePage:  Yup.string().required("Category should be display on Home page is required."),
        isActive : Yup.string().required("Field is required"),
  });