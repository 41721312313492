import React, { useState } from 'react';
// @mui
import {
    TextField,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    InputLabel,
    Avatar
    
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import ViewImageList from '../image-upload/ViewImageList';
import Iconify from '../iconify/Iconify';
import { NotificationDeviceTypeArr, NotificationLinkTypeArr } from '../common/constants';
// ----------------------------------------------------------------------

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
        <DialogTitle sx={{ m: 0, p: 2,}} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 12,
                        top: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const StyledAvatar = styled(Avatar)(({ theme }) => ({
    backgroundColor: 'green',
    color: '#FFFFFF',
    cursor: 'pointer',
    width: 34,
    height: 34,
    marginLeft: 5
}));

export default function ViewNotificationDialog(props) {
    
    const {data} = props;
    if(data?.notification_web_url && (data?.notification_web_url?.toLowerCase().includes("www") && !data?.notification_web_url?.toLowerCase().includes("http"))){
        data.notification_web_url = `http://${data.notification_web_url.toLowerCase()}`;
    }else{
        data.notification_web_url = `${data?.notification_web_url? data.notification_web_url.toLowerCase():""}`;
    }
    const [upload, setUpload] = useState([]);
    const [isImageUploaded, setIsImageUploaded] = useState(false);
    React.useEffect(()=>{
        if(data){
            if(data?.banner_image?.length!==0){
                setUpload(data?.notification_images);
                setIsImageUploaded(true)
            }
            else {
                setIsImageUploaded(false)
            }
        }
    },[data])

    const cancelClick = () =>{
        // window.scrollTo({
        //     top: 0,
        //     behavior: "smooth",
        // });
        props.onClose();
    }// END cancelClick

    return (
        <>
            <Dialog
                open={props.open}
                // onClose={props.onClose}
                className="dialog-box-section">
                <BootstrapDialogTitle id="customized-dialog-title" onClose={()=> cancelClick()}>
                    Notification
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <InputLabel sx={{mb:"5px",fontWeight:600}}>
                                Notification Image
                            </InputLabel>
                            {
                                isImageUploaded ? <ViewImageList imageArray={upload} /> : "No image uploaded"
                            }
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <InputLabel sx={{mb:"5px",fontWeight:600}}>
                                Notification Title
                            </InputLabel>
                            <TextField
                                id="outlined-name-input"
                                type="text"
                                fullWidth
                                InputProps={{
                                    readOnly: true,
                                }}
                                value={data.notification_title}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel sx={{mb:"5px",fontWeight:600}}>
                                Notification Message
                            </InputLabel>
                            <TextField
                                multiline
                                rows={2}
                                id="outlined-name-input"
                                InputProps={{
                                    readOnly: true,
                                }}
                                type="text"
                                fullWidth
                                value={data.notification_message}
                            />
                        </Grid>
                        { /* <Grid item xs={12} sm={12} md={12}>
                            <InputLabel sx={{mb:"5px",fontWeight:600}}>
                                Notification Status
                            </InputLabel>
                            <TextField
                                id="outlined-name-input"
                                type="text"
                                fullWidth
                                InputProps={{
                                    readOnly: true,
                                }}
                                value={data.status ? "Active" : "Inactive"}
                            />
                            </Grid> */}
                        <Grid item xs={12} sm={12} md={12}>
                            <InputLabel sx={{mb:"5px",fontWeight:600}}>
                                Notification Device Type
                            </InputLabel>
                            <TextField
                                id="device_type"
                                name="Notification Device Type"
                                InputProps={{
                                    readOnly: true,
                                }}
                                value={ data.device_type === "Both" ? "All" : data.device_type }
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <InputLabel sx={{mb:"5px",fontWeight:600}}>
                                Notification Link Type
                            </InputLabel>
                            <TextField
                                id="device_type"
                                name="Notification Link Type"
                                InputProps={{
                                    readOnly: true,
                                }}
                                value={ data?.notification_link_type }
                                fullWidth
                            />
                        </Grid>
                        {
                            data?.notification_link_type !== NotificationLinkTypeArr[0].value? 
                            ((data?.notification_product_id!==null && data?.notification_link_type === NotificationLinkTypeArr[2].value) ?
                            <Grid item xs={12} sm={12} md={12}>
                                <InputLabel sx={{mb:"5px",fontWeight:600}}>
                                    Target Product
                                </InputLabel>
                                <TextField
                                    id="product_id"
                                    name="Target Product"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    value={ data?.notification_product?.product_name }
                                    fullWidth
                                />
                            </Grid> 
                            :
                            <Grid item xs={12} sm={12} md={12}>
                                <InputLabel sx={{mb:"5px",fontWeight:600}}>
                                    Notification Web URL
                                </InputLabel>
                                <TextField
                                    id="web_url"
                                    name="Notification Web URL"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    value={ data?.notification_web_url }
                                    fullWidth
                                    sx={{mb:"10px",fontWeight:600}}
                                />
                                <StyledAvatar onClick={()=>window.open(`${data?.notification_web_url}`, "_blank").focus()}>
                                    <Iconify
                                        icon={'eva:eye-outline'}
                                        sx={{ width: 22, height: 22}}
                                    />
                                </StyledAvatar>
                            </Grid>)
                            : ""
                        }                        
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    );
}
