import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import { sentenceCase } from 'change-case';
import { Helmet } from 'react-helmet-async';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Avatar,
} from '@mui/material';
// components
import { BannerLinkTypeArr } from '../components/common/constants';
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { PagesHead, PagesToolbar, PagesMoreMenu } from '../sections/@dashboard/common';
import BannerDiaglogBox from '../components/DiaglogBox/BannerDiaglogBox';
import DeleteConfirmationDialog from '../components/DiaglogBox/DeleteConfirmationDialog';
import BannerImageDialog from '../components/DiaglogBox/BannerImageDialog';
import ViewBannerDialog from '../components/DiaglogBox/ViewBannerDialog';
import { DeleteBanner, GetAllBanners } from '../actions/BannersAction';
// ----------------------------------------------------------------------
const TABLE_HEAD = [
  // { id: 'srno', label: 'Sr No.', alignRight: false },
  { id: 'sequence', label: 'Sequence', alignRight: false },
  { id: 'title', label: 'Title', alignRight: false },
  { id: 'bannerLinkType', label: 'Banner Link Type', alignRight: false },
  { id: 'target', label: 'Target', alignRight: false },
  // { id: 'images', label: 'Image', alignRight: false },
  // { id: 'targetProduct', label: 'Product', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'action', label: 'Action' },
];
const statusFilterOptions = [
  { id: 1, status: 'All', value: 9 },
  { id: 2, status: 'Active', value: 1 },
  { id: 3, status: 'Inactive', value: 0 },
];

export default function BannerPage() {
  /* START Config Data For Page */

  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = React.useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [data, setData] = React.useState(null);
  const [searchValue, setSearchValue] = React.useState('');
  const [imageDialogOpen, setImageDialogOpen] = React.useState(false);
  const [viewBannerDialogOpen, setViewBannerDialogOpen] = React.useState(false);
  const [imageData, setImageData] = useState({});
  // Binding input field
  const [inputField, setInputField] = useState({
    Name: '',
    SpecialNote: '',
    Sequence: '',
    DisplayStatusName: '',
    ActiveStatus: '',
  });

  const { banners, pageInfo, addBannerLog, editBannerLog, deleteBannerLog } = useSelector((state) => ({
    banners: state.banners.banners,
    pageInfo: state.banners.pageInfo,
    addBannerLog: state.banners.addBannerLog,
    editBannerLog: state.banners.editBannerLog,
    deleteBannerLog: state.banners.deleteBannerLog,
  })); // END useSelector

  /* END Config Data For Page */

  // -----------status filter--------------------
  const [statusValue, setStatusValue] = React.useState(statusFilterOptions[0].value);
  const handleStatusChange = (data) => {
    setStatusValue(data);
  };

  /* START Use Effects */
  useEffect(() => {
    dispatch(GetAllBanners(1, rowsPerPage, searchValue, statusValue));
    setPage(0);
  }, [statusValue]);

  useEffect(() => {
    setConfirmationDialogOpen(false);
    dispatch(GetAllBanners(page + 1, rowsPerPage, searchValue, statusValue));
  }, [addBannerLog, editBannerLog, deleteBannerLog]); // END useEffect

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      dispatch(GetAllBanners(1, rowsPerPage, searchValue, statusValue));
      setPage(0);
    }, 1000);
    return () => clearTimeout(delayDebounce);
  }, [searchValue]); // END useEffect

  /* END Use Effects */

  /* START Handle Change */

  // pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(GetAllBanners(newPage + 1, rowsPerPage, searchValue, statusValue));
  }; // END handleChangePage

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
    dispatch(GetAllBanners(1, parseInt(event.target.value, 10), searchValue, statusValue));
  }; // END handleChangeRowsPerPage

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  }; // END handleSearchChange

  const handleClearClick = () => {
    dispatch(GetAllBanners(1, rowsPerPage, ''));
    setPage(0);
    setSearchValue('');
  }; // END handleClearClick

  // Open dialog click
  const handleClickOpen = (e) => {
    setData(null);
    setOpen(true);
  }; // END handleClickOpen

  const handleEdit = (data) => {
    setData(data);
    setOpen(true);
  }; // END handleEdit

  const handleDelete = (data) => {
    setConfirmationDialogOpen(true);
    setData(data);
  }; // END handleDelete

  const handleClose = () => {
    setOpen(false);
    setData(null);
  }; // END handleClose

  const handleViewBannerDialogClose = () => {
    setViewBannerDialogOpen(false);
    setData(null);
  }; // END handleViewBannerDialogClose

  const handleViewBannerDialogOpen = (data) => {
    setViewBannerDialogOpen(true);
    setData(data);
  }; // END handleViewBannerDialogOpen

  const handleImageModalOpen = (image) => {
    setImageDialogOpen(true);
    setImageData(image);
  }; // END handleImageModalOpen

  const handleImageModalClose = () => {
    setImageDialogOpen(false);
  }; // END handleImageModalClose

  const handleConfirmationClose = (message) => {
    if (message) {
      dispatch(DeleteBanner(data.id));
    } else {
      setConfirmationDialogOpen(false);
      setData(null);
    }
  }; // END handleConfirmationClose

  /* END Handle Change Action */

  return (
    <>
      <Helmet>
        <title> Banner | Rushipeetham </title>
      </Helmet>
      <Container>
        {open ? <BannerDiaglogBox open={open} data={data} onClose={handleClose} /> : null}
        {viewBannerDialogOpen ? (
          <ViewBannerDialog open={viewBannerDialogOpen} data={data} onClose={handleViewBannerDialogClose} />
        ) : null}
        {imageDialogOpen ? (
          <BannerImageDialog open={imageDialogOpen} imageData={imageData} handleClose={handleImageModalClose} />
        ) : null}
        {confirmationDialogOpen ? (
          <DeleteConfirmationDialog
            message="Banner"
            isOpenConfirm={confirmationDialogOpen}
            handleClose={handleConfirmationClose}
          />
        ) : null}
        <Card>
          <PagesToolbar
            placeHolder={'Search Banner...'}
            onFilterName={handleSearchChange}
            filterName={searchValue}
            handleClearClick={handleClearClick}
            buttonName="New Banner"
            handleClickOpen={handleClickOpen}
            statusFilterValue={statusValue}
            statusFilterOptions={statusFilterOptions}
            onStatusFilterChange={handleStatusChange}
          />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table aria-label="simple table" size="small">
                <PagesHead headLabel={TABLE_HEAD} />
                {banners.length !== 0 ? (
                  <TableBody>
                    {banners?.map((row, index) => (
                      <TableRow key={index}>
                        {/* <TableCell>{index + 1}</TableCell> */}
                        <TableCell>{row?.sequence}</TableCell>
                        <TableCell>{row?.banner_title}</TableCell>
                        {/* 
												<TableCell>
													{row?.banner_image?.length!==0?
													<IconButton
													aria-label="delete"
													size="large"
													// onClick={() => handleViewOpen(option.images)}
													onClick={(e) => {
													// window.open(`${process.env.REACT_APP_BASE_URL} + '/api/' + ${row?.banner_image[0].file_path}`);
													handleImageModalOpen(`${row?.banner_image[0].file_path}`);
													}}
													sx={{color: '#393939'}}
												>
													<Visibility />
												</IconButton>
													:
													"NA"
													}		
												</TableCell> 
											*/}
                        <TableCell>
                          {row?.banner_link_type === BannerLinkTypeArr[0].value
                            ? 'No Link Available'
                            : row?.banner_link_type}
                        </TableCell>
                        <TableCell>
                          {BannerLinkTypeArr[1].value === row?.banner_link_type
                            ? row?.banner_web_url
                            : BannerLinkTypeArr[2].value === row?.banner_link_type
                            ? row?.banner_product?.product_name
                            : null}
                        </TableCell>
                        {/* <TableCell>{row?.banner_product?.product_name}</TableCell> */}
                        <TableCell>
                          <Label color={(row?.is_active === 0 && 'error') || 'success'}>
                            {sentenceCase(row?.is_active ? 'Active' : 'Inactive')}
                          </Label>
                        </TableCell>
                        <TableCell align="center">
                          <PagesMoreMenu
                            handleEdit={() => handleEdit(row)}
                            handleDelete={() => handleDelete(row)}
                            handleView={() => handleViewBannerDialogOpen(row)}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                ) : (
                  <TableRow>
                    <TableCell colSpan={12}>
                      <div className="text-align-center font-18 font-bold py-15" style={{ textAlign: 'center' }}>
                        No data found...
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            component="div"
            count={pageInfo.total ? pageInfo.total : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </>
  ); // END return
} // END BannerPage
