import * as React from 'react';
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	Button,
	IconButton,
	CardContent,
	Box,	
	Typography	
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import DialogContentText from '@mui/material/DialogContentText';
import { SubscriberType } from '../common/constants';

const bull = (
	<Box component="span" sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}>
		•
	</Box>
);

const BootstrapDialogTitle = (props) => {

	const { children, onClose, ...other } = props;

	return (
		<DialogTitle sx={{ m: 0, p: 2 }} {...other}>
			{children}
			{onClose ? (
				<IconButton
				aria-label="close"
				onClick={onClose}
				sx={{
					position: 'absolute',
					right: 12,
					top: 8,
				}}
				>
				<CloseIcon />
				</IconButton>
			) : null}
		</DialogTitle>
	);
};

BootstrapDialogTitle.propTypes = {
	children: PropTypes.node,
	onClose: PropTypes.func.isRequired,
};

export default function CancelConfirmationDialog(props) {
	
	const [open, setOpen] = React.useState(false);
	const { isOpenConfirm } = props;
	
	// Optimization - can be handle in one function only 
	// onClick={(event) => handleClose(event, 'test')}
	const handleClose = () => {
		setOpen(false);
		props.handleClose(false);
	};
	const handleTrueClose = () => {
		props.handleClose(true);
	};

	return (
		<>
			<Dialog
				// open={open}
				open={isOpenConfirm}
				// onClose={handleClose}
				className="dialog-box-section"
				sx={{
					'& .MuiDialog-container': {
						'& .MuiPaper-root': {
						minWidth: '400px',
						minHeight: '220px',
						},
					},
				}}>
				<BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
					Cancel {props.message}
				</BootstrapDialogTitle>
				<DialogContent dividers>					
					<DialogContentText id="alert-dialog-description">
						{`Are you sure, you want to cancel ${props.message} for below details ?`}
					</DialogContentText>
					<CardContent>
						<Grid xs={12} sm={12} md={12} item container>
							<Grid item xs={5}>
								<Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
									{bull} Email
								</Typography>	
							</Grid>
							<Grid item xs={7}>	
								<Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
									{props.data.user.email}
								</Typography>
							</Grid>
						</Grid>
						<Grid xs={12} sm={12} md={12} item container>
							<Grid item xs={5}>	
								<Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
									{bull} Subscription Type
								</Typography>
							</Grid>
							<Grid item xs={7}>	
								<Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
									{SubscriberType.filter((item)=>item.value.toString() === props.data?.subscription_id?.trim().toString())[0]?.label || ''}
								</Typography>								
							</Grid>
						</Grid>
						<Grid xs={12} sm={12} md={12} item container>
							<Grid item xs={5}>	
								<Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
									{bull} Duration
								</Typography>
							</Grid>
							<Grid item xs={7}>	
								<Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
									{ `${dayjs(props.data.subscription_start_date, "YYYY-MM-DD+h:mm").format('DD/MM/YYYY') } to ${ dayjs(props.data.subscription_end_date, "YYYY-MM-DD+h:mm").format('DD/MM/YYYY')}` } 
								</Typography>								
							</Grid>
						</Grid>
					</CardContent>	
				</DialogContent>				
				<DialogActions sx={{ justifyContent: 'end', pr: '25px' }}>
					<Button
						variant="outlined"
						onClick={handleClose}
						sx={{
							minWidth: '100px',
							color: (theme) => theme.palette.text.darkGrey,
							borderColor: (theme) => theme.palette.text.darkGrey,
						}}>
						Cancel
					</Button>
					<Button onClick={handleTrueClose} variant="contained" style={{ minWidth: '100px' }}>
						yes
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}
