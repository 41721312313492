import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sentenceCase } from 'change-case';
import { Helmet } from 'react-helmet-async';
// @mui
import { Card, Table, TableRow, TableBody, TableCell, Container, TableContainer, TablePagination } from '@mui/material';
import { Visibility } from '@mui/icons-material';
// components
import dayjs from 'dayjs';
import Label from '../components/label';
import Scrollbar from '../components/scrollbar';
// sections
import { PagesHead, PagesToolbar, PagesMoreMenu } from '../sections/@dashboard/common';
import { CancelSubscription, GetAllSubscriptions, GetAllSubscriptionTypes } from '../actions/SubscriptionAction';
import SubscriptionDialogBox from '../components/DiaglogBox/SubscriptionDialogBox';
import CancelConfirmationDialog from '../components/DiaglogBox/CancelConfirmationDialog';
import ViewSubscriptionDialog from '../components/DiaglogBox/ViewSubscriptionDialog';
import { PaymentModeFilterArr } from '../components/common/constants';
// ----------------------------------------------------------------------
const TABLE_HEAD = [
  { id: 'srno', label: 'Sr No.', alignRight: false },
  { id: 'start_date', label: 'Start Date', alignRight: false },
  { id: 'end_date', label: 'End Date', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'package', label: 'Package', alignRight: false },
  { id: 'payment_mode', label: 'Payment Mode', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false },
];

export default function SubscriptionsPage() {
  /* START Config Data For Page */

  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState(null);
  const [filteredValue, setFilteredValue] = React.useState(PaymentModeFilterArr[0].value);
  const [viewSubscriptionDialogOpen, setViewSubscriptionDialogOpen] = React.useState(false);
  const [cancelSubscriptionDialogOpen, setCancelSubscriptionDialogOpen] = React.useState(false);
  const statusFilterOptions = [
    { id: 1, status: 'All', value: 9 },
    { id: 2, status: 'Active', value: 1 },
    { id: 3, status: 'Cancelled', value: 0 },
  ];
  const [statusValue, setStatusValue] = React.useState(statusFilterOptions[0].value);
  // console.log('statusValue', statusValue);
  const handleStatusChange = (data) => {
    setStatusValue(data);
  }; // END handleFilterChange

  const { subscriptions, pageInfo, addSubscriptionLog, cancelSubscriptionLog, subscriptionTypeList } = useSelector(
    (state) => ({
      subscriptions: state.subscriptions.subscriptions,
      pageInfo: state.subscriptions.pageInfo,
      addSubscriptionLog: state.subscriptions.addSubscriptionLog,
      cancelSubscriptionLog: state.subscriptions.cancelSubscriptionLog,
      subscriptionTypeList: state.subscriptions.subscriptionTypeList || [],
    })
  );

  /* END Config Data For Page */

  /* START Use Effects */

  useEffect(() => {
    setCancelSubscriptionDialogOpen(false);
    dispatch(GetAllSubscriptions(page + 1, rowsPerPage, '', filteredValue));
    dispatch(GetAllSubscriptionTypes());
  }, [addSubscriptionLog, cancelSubscriptionLog]); // END useEffect

  useEffect(() => {
    dispatch(GetAllSubscriptions(1, rowsPerPage, '', filteredValue));
    setPage(0);
  }, [filteredValue]); // END useEffect

  /* END Use Effects */

  /* START Handle Change Actions */

  // pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(GetAllSubscriptions(newPage + 1, rowsPerPage, '', filteredValue));
  }; // END handleChangePage

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
    dispatch(GetAllSubscriptions(1, parseInt(event.target.value, 10), '', filteredValue));
  }; // END handleChangeRowsPerPage

  // Open dialog click
  const handleClickOpen = (e) => {
    setData(null);
    setOpen(true);
  }; // END handleClickOpen

  const handleClose = () => {
    setOpen(false);
    setData(null);
  }; // END handleClose

  const handleViewSubscriptionDialogOpen = (data) => {
    setViewSubscriptionDialogOpen(true);
    setData(data);
  }; // END handleViewSubscriptionDialogOpen

  const handleViewSubscriptionDialogClose = () => {
    setViewSubscriptionDialogOpen(false);
    setData(null);
  }; // END handleViewSubscriptionDialogClose

  const handleCancelSubscriptionDialogOpen = (data) => {
    setCancelSubscriptionDialogOpen(true);
    setData(data);
  }; // END handleCancelSubscriptionDialogOpen

  const handleCancelSubscriptionDialogClose = (message) => {
    if (message) {
      dispatch(CancelSubscription(data.id));
    } else {
      setCancelSubscriptionDialogOpen(false);
      setData(null);
    }
  }; // END handleCancelSubscriptionDialogClose

  const handleFilterChange = (data) => {
    setFilteredValue(data);
  }; // END handleFilterChange

  /* END Handle Change Action */

  return (
    <>
      <Helmet>
        <title> Subscriptions | Rushipeetham </title>
      </Helmet>
      <Container>
        {open ? <SubscriptionDialogBox open={open} data={data} onClose={handleClose} /> : null}
        {viewSubscriptionDialogOpen ? (
          <ViewSubscriptionDialog
            open={viewSubscriptionDialogOpen}
            data={data}
            onClose={handleViewSubscriptionDialogClose}
          />
        ) : null}
        {cancelSubscriptionDialogOpen ? (
          <CancelConfirmationDialog
            message="subscription"
            data={data}
            isOpenConfirm={cancelSubscriptionDialogOpen}
            handleClose={handleCancelSubscriptionDialogClose}
          />
        ) : null}
        <Card>
          <PagesToolbar
            searchable={false}
            filterable
            onFilterChange={handleFilterChange}
            filterTitle={'Filter By Payment Mode'}
            filterValue={filteredValue}
            arrayOfFilterOptions={PaymentModeFilterArr}
            buttonName="Assign Subscription"
            handleClickOpen={handleClickOpen}
            statusFilterValue={statusValue}
            statusFilterOptions={statusFilterOptions}
            onStatusFilterChange={handleStatusChange}
          />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table aria-label="simple table" size="small">
                <PagesHead headLabel={TABLE_HEAD} />
                {statusValue === 0 ? (
                  <TableBody>
                    {subscriptions
                      ?.filter((row) => row?.cancellation_date_time !== null)
                      .map((row, index) => (
                        <TableRow key={index}>
                          <TableCell style={{ width: '15%' }}>{page * rowsPerPage + (index + 1)}</TableCell>
                          <TableCell style={{ width: '25%' }}>
                            {dayjs(row?.subscription_start_date, 'YYYY-MM-DD+h:mm').format('MMMM D, YYYY')}
                          </TableCell>
                          <TableCell style={{ width: '25%' }}>
                            {dayjs(row?.subscription_end_date, 'YYYY-MM-DD+h:mm').format('MMMM D, YYYY')}
                          </TableCell>
                          <TableCell style={{ width: '40%' }}>{row?.user?.email}</TableCell>
                          <TableCell style={{ width: '20%' }}>
                            {subscriptionTypeList.filter(
                              (item) =>
                                item.susbcription_type.trim().toString() === row?.subscription_id?.trim().toString()
                            )[0]?.subscription_name || ''}
                          </TableCell>
                          <TableCell style={{ width: '30%' }}>{row?.subscription_payment_mode}</TableCell>
                          <TableCell style={{ width: '20%' }}>
                            <Label color={(row?.cancellation_date_time !== null && 'error') || 'success'}>
                              {sentenceCase(row?.cancellation_date_time !== null ? 'Cancelled' : 'Active')}
                            </Label>
                          </TableCell>
                          <TableCell style={{ width: '20%' }}>
                            <PagesMoreMenu
                              handleView={() => handleViewSubscriptionDialogOpen(row)}
                              handleCancel={() => handleCancelSubscriptionDialogOpen(row)}
                              disableCancel={row?.cancellation_date_time === null}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                ) : statusValue === 1 ? (
                  <TableBody>
                    {subscriptions
                      ?.filter((row) => row?.cancellation_date_time == null)
                      .map((row, index) => (
                        <TableRow key={index}>
                          <TableCell style={{ width: '15%' }}>{page * rowsPerPage + (index + 1)}</TableCell>
                          <TableCell style={{ width: '25%' }}>
                            {dayjs(row?.subscription_start_date, 'YYYY-MM-DD+h:mm').format('MMMM D, YYYY')}
                          </TableCell>
                          <TableCell style={{ width: '25%' }}>
                            {dayjs(row?.subscription_end_date, 'YYYY-MM-DD+h:mm').format('MMMM D, YYYY')}
                          </TableCell>
                          <TableCell style={{ width: '40%' }}>{row?.user?.email}</TableCell>
                          <TableCell style={{ width: '20%' }}>
                            {subscriptionTypeList.filter(
                              (item) =>
                                item.susbcription_type.trim().toString() === row?.subscription_id?.trim().toString()
                            )[0]?.subscription_name || ''}
                          </TableCell>
                          <TableCell style={{ width: '30%' }}>{row?.subscription_payment_mode}</TableCell>
                          <TableCell style={{ width: '20%' }}>
                            <Label color={(row?.cancellation_date_time !== null && 'error') || 'success'}>
                              {sentenceCase(row?.cancellation_date_time !== null ? 'Cancelled' : 'Active')}
                            </Label>
                          </TableCell>
                          <TableCell style={{ width: '20%' }}>
                            <PagesMoreMenu
                              handleView={() => handleViewSubscriptionDialogOpen(row)}
                              handleCancel={() => handleCancelSubscriptionDialogOpen(row)}
                              disableCancel={row?.cancellation_date_time === null}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                ) : (
                  <>
                    {subscriptions?.length !== 0 ? (
                      <TableBody>
                        {subscriptions?.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell style={{ width: '15%' }}>{page * rowsPerPage + (index + 1)}</TableCell>
                            <TableCell style={{ width: '25%' }}>
                              {dayjs(row?.subscription_start_date, 'YYYY-MM-DD+h:mm').format('MMMM D, YYYY')}
                            </TableCell>
                            <TableCell style={{ width: '25%' }}>
                              {dayjs(row?.subscription_end_date, 'YYYY-MM-DD+h:mm').format('MMMM D, YYYY')}
                            </TableCell>
                            <TableCell style={{ width: '40%' }}>{row?.user?.email}</TableCell>
                            <TableCell style={{ width: '20%' }}>
                              {subscriptionTypeList.filter(
                                (item) =>
                                  item.susbcription_type.trim().toString() === row?.subscription_id?.trim().toString()
                              )[0]?.subscription_name || ''}
                            </TableCell>
                            <TableCell style={{ width: '30%' }}>{row?.subscription_payment_mode}</TableCell>
                            <TableCell style={{ width: '20%' }}>
                              <Label color={(row?.cancellation_date_time !== null && 'error') || 'success'}>
                                {sentenceCase(row?.cancellation_date_time !== null ? 'Cancelled' : 'Active')}
                              </Label>
                            </TableCell>
                            <TableCell style={{ width: '20%' }}>
                              <PagesMoreMenu
                                handleView={() => handleViewSubscriptionDialogOpen(row)}
                                handleCancel={() => handleCancelSubscriptionDialogOpen(row)}
                                disableCancel={row?.cancellation_date_time === null}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    ) : null}
                    {subscriptions === undefined ? (
                      <TableRow>
                        <TableCell colSpan={12}>
                          <div className="text-align-center font-18 font-bold py-15" style={{ textAlign: 'center' }}>
                            No data found...
                          </div>
                        </TableCell>
                      </TableRow>
                    ) : null}
                  </>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            component="div"
            count={pageInfo.total ? pageInfo.total : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </>
  ); // END return
} // END SubscriptionPage
