
import { SHOW_LOADER, SHOW_LOADER_BUTTON, SHOW_UPLOAD_LOADER } from "./Types";

const ShowLoader = (data) => (dispatch) => {
    dispatch({
      type: SHOW_LOADER,
      payload: data,
    });
  };

  const ShowLoadingButton = (data) => (dispatch) => {
    dispatch({
      type: SHOW_LOADER_BUTTON,
      payload: data,
    });
  };

  const ShowUploadLoader = (data) => (dispatch) => {
    dispatch({
      type: SHOW_UPLOAD_LOADER,
      payload: data,
    });
  };


export { 
    ShowLoader,
    ShowLoadingButton,
    ShowUploadLoader
};
