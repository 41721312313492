import Server from '../api';
import JWTServer from '../api/withJWTServer';

import { CHANGE_PASSWORD, FORGOT_PASSWORD, LOGIN, LOG_OUT, RESET_STATE } from './Types';

import { HandleExceptionWithSecureCatch, HandleManualException } from './CombineCatch';
import { SetNewAlert } from './AlertActions';
import { ShowLoader, ShowLoadingButton } from './CommonAction';

const ResetState = () => ({
	type: RESET_STATE,
	payload: null,
});

const LoginUser = (params) => async (dispatch) => {
	try {
		const response = await Server.post('/api/admin-login', params);
		if (response.data.meta.status === 200) {
			localStorage.setItem('token', response.data.data.access_token);
			localStorage.setItem('loggedUser', JSON.stringify(response.data.data));
			dispatch({
				type: LOGIN,
				payload: response.data,
			});
			dispatch(
				SetNewAlert({
					// msg: response.data.meta.message,
					msg: 'User logged in successfully',
					alertType: 'success',
				})
			);
			dispatch(ShowLoadingButton(false));
		} else {
			dispatch(HandleManualException(response.data));
		}
	} catch (e) {
		dispatch(ShowLoadingButton(false));
		dispatch(HandleExceptionWithSecureCatch(e));
	}
};

const Logout = () => async (dispatch) => {
	try {
		// const response = await JWTServer.post("/api/logout");
		// console.log("RESPONSE LOGOUT",response.data);
		localStorage.clear();
		dispatch({
			type: LOG_OUT,
			payload: null,
		});
		dispatch({
			type: RESET_STATE,
			payload: null,
		});
	} catch (e) {
		dispatch(HandleExceptionWithSecureCatch(e));
	}
};

const SessionExpired = () => async (dispatch) => {
	try {
		localStorage.clear();
		dispatch({
			type: LOG_OUT,
			payload: null,
		});
		dispatch({
			type: RESET_STATE,
			payload: null,
		});
	} catch (e) {
		dispatch(HandleExceptionWithSecureCatch(e));
	}
};

const ChangePassword = (params) => async (dispatch) => {
	try {
		const response = await JWTServer.post('/api/change-password', params);
		if (response.data.meta.status === 200) {
			dispatch({
				type: CHANGE_PASSWORD,
				payload: response.data,
			});
			dispatch(
				SetNewAlert({
				msg: response.data.meta.message,
				alertType: 'success',
				})
			);
			dispatch(ShowLoadingButton(false));
		} else {
			dispatch(HandleManualException(response.data));
		}
	} catch (e) {
		dispatch(ShowLoadingButton(false));
		dispatch(HandleExceptionWithSecureCatch(e));
	}
};

const ForgotPassword = (params) => async (dispatch) => {
	try {
		const response = await JWTServer.post('/api/forgot-password', params);
		if (response.data.meta.status === 200) {
			dispatch({
				type: FORGOT_PASSWORD,
				payload: response.data,
			});
			dispatch(
				SetNewAlert({
				msg: response.data.meta.message,
				alertType: 'success',
				})
			);
			dispatch(ShowLoadingButton(false));
		} else {
			dispatch(HandleManualException(response.data));
		}
	} catch (e) {
		dispatch(ShowLoadingButton(false));
		dispatch(HandleExceptionWithSecureCatch(e));
	}
};

export { LoginUser, Logout, SessionExpired, ChangePassword, ForgotPassword };
