import * as Yup from "yup";


export const TagsValidationSchema = Yup.object().shape({
        tagName: Yup.string()
                .trim()
                .min(3, "Minimum 3 characters required")
                .max(40, "Minimum 40 characters are allowed")
                // .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed")
                .required("Tag name is required."),        
        isActive : Yup.string().required("Field is required"),
  });