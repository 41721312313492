import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import { sentenceCase } from 'change-case';
import { Helmet } from 'react-helmet-async';

// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
} from '@mui/material';
import { Visibility } from '@mui/icons-material';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { PagesHead, PagesToolbar, PagesMoreMenu } from '../sections/@dashboard/common';
import { DeleteCategory, GetAllCategories } from '../actions/CategoriesAction';
// mock
import CATEGORY_LIST from '../_mock/categories';
import CategoryDialogBox from '../components/DiaglogBox/CategoryDialogBox';
import DeleteConfirmationDialog from '../components/DiaglogBox/DeleteConfirmationDialog';
import ViewCategoryDialog from '../components/DiaglogBox/ViewCategoryDialog';
// ----------------------------------------------------------------------
const TABLE_HEAD = [
  // { id: 'srno', label: 'Sr No.', alignRight: false },
  { id: 'sequence', label: 'Sequence', alignRight: false },
  { id: 'name', label: 'Name', alignRight: false },
  // { id: 'specialNote', label: 'Special Note', alignRight: false },
  // { id: 'images', label: 'Image', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false },
];

const statusFilterOptions = [
  { id: 1, status: 'All', value: 9 },
  { id: 2, status: 'Active', value: 1 },
  { id: 3, status: 'Inactive', value: 0 },
];

export default function CategoryPage() {
  /* START Config Data For Page */

  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = React.useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [data, setData] = React.useState(null);
  const [searchValue, setSearchValue] = React.useState('');
  const [realValue, setRealValue] = React.useState('');
  const [viewCategoryDialogOpen, setViewCategoryDialogOpen] = React.useState(false);
  // Binding input field
  const [inputField, setInputField] = useState({
    Name: '',
    SpecialNote: '',
    Sequence: '',
    DisplayStatusName: '',
    ActiveStatus: '',
  });

  const { categories, pageInfo, addCategoryLog, editCategoryLog, deleteCategoryLog } = useSelector((state) => ({
    categories: state.categories.categories,
    pageInfo: state.categories.pageInfo,
    addCategoryLog: state.categories.addCategoryLog,
    editCategoryLog: state.categories.editCategoryLog,
    deleteCategoryLog: state.categories.deleteCategoryLog,
  }));

  /* END Config Data For Page */

  // -----------status filter--------------------
  const [statusValue, setStatusValue] = React.useState(statusFilterOptions[0].value);
  const handleStatusChange = (data) => {
    setStatusValue(data);
  };

  /* START Use Effects */

  useEffect(() => {
    dispatch(GetAllCategories(1, rowsPerPage, searchValue, statusValue));
    setPage(0);
  }, [statusValue]);

  useEffect(() => {
    setConfirmationDialogOpen(false);
    dispatch(GetAllCategories(page + 1, rowsPerPage, searchValue, statusValue));
  }, [addCategoryLog, editCategoryLog, deleteCategoryLog]); // END useEffect

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      dispatch(GetAllCategories(1, rowsPerPage, searchValue, statusValue));
      setPage(0);
    }, 1000);
    return () => clearTimeout(delayDebounce);
  }, [searchValue]); // END useEffect

  /* END Use Effects */

  /* START Handle Change Actions */

  // pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(GetAllCategories(newPage + 1, rowsPerPage, searchValue, statusValue));
  }; // END handleChangePage

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
    dispatch(GetAllCategories(1, parseInt(event.target.value, 10), searchValue, statusValue));
  }; // END handleChangeRowsPerPage

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  }; // END handleSearchChange

  const handleClearClick = () => {
    dispatch(GetAllCategories(1, rowsPerPage, ''));
    setPage(0);
    setSearchValue('');
  }; // END handleClearClick

  // Open dialog click
  const handleClickOpen = (e) => {
    setData(null);
    setOpen(true);
  }; // END handleClickOpen

  const handleEdit = (data) => {
    setData(data);
    setOpen(true);
  }; // END handleEdit

  const handleDelete = (data) => {
    setConfirmationDialogOpen(true);
    setData(data);
  }; // END handleDelete

  const handleClose = () => {
    setOpen(false);
    setData(null);
  }; // END handleClose

  const handleViewCategoryDialogClose = () => {
    setViewCategoryDialogOpen(false);
    setData(null);
  }; // END handleViewCategoryDialogClose

  const handleViewCategoryDialogOpen = (data) => {
    setViewCategoryDialogOpen(true);
    setData(data);
  }; // END handleViewCategoryDialogOpen

  const handleConfirmationClose = (message) => {
    if (message) {
      dispatch(DeleteCategory(data.id));
    } else {
      setConfirmationDialogOpen(false);
      setData(null);
    }
  }; // END handleConfirmationClose

  /* END Handle Change Action */

  return (
    <>
      <Helmet>
        <title> Category | Rushipeetham </title>
      </Helmet>
      <Container>
        {open ? <CategoryDialogBox open={open} data={data} onClose={handleClose} /> : null}
        {viewCategoryDialogOpen ? (
          <ViewCategoryDialog open={viewCategoryDialogOpen} data={data} onClose={handleViewCategoryDialogClose} />
        ) : null}
        {confirmationDialogOpen ? (
          <DeleteConfirmationDialog
            message="Category"
            isOpenConfirm={confirmationDialogOpen}
            handleClose={handleConfirmationClose}
          />
        ) : null}
        <Card>
          <PagesToolbar
            placeHolder={'Search Categories...'}
            onFilterName={handleSearchChange}
            filterName={searchValue}
            handleClearClick={handleClearClick}
            buttonName="New Category"
            handleClickOpen={handleClickOpen}
            statusFilterValue={statusValue}
            statusFilterOptions={statusFilterOptions}
            onStatusFilterChange={handleStatusChange}
          />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table aria-label="simple table" size="small">
                <PagesHead headLabel={TABLE_HEAD} />
                {categories.length !== 0 ? (
                  <TableBody>
                    {categories?.map((row, index) => (
                      <TableRow key={index}>
                        {/* <TableCell>{(page*rowsPerPage)+(index + 1)}</TableCell> */}
                        <TableCell style={{ width: '20%' }}>{row?.sequence}</TableCell>
                        <TableCell style={{ width: '40%' }}>{row?.category_name}</TableCell>
                        {/* <TableCell>{row?.special_note}</TableCell> */}
                        {/* 
															<TableCell>
																<IconButton
																	aria-label="delete"
																	size="large"
																	// onClick={() => handleViewOpen(option.images)}
																	onClick={(e) => {
																	window.open(row?.image);
																	}}
																	sx={{color: '#393939'}}
																>
																	<Visibility />
																</IconButton>
															</TableCell> 
														*/}

                        <TableCell style={{ width: '20%' }}>
                          <Label color={(row?.is_active === 0 && 'error') || 'success'}>
                            {sentenceCase(row?.is_active ? 'Active' : 'Inactive')}
                          </Label>
                        </TableCell>
                        <TableCell style={{ width: '20%' }}>
                          <PagesMoreMenu
                            handleEdit={() => handleEdit(row)}
                            handleDelete={() => handleDelete(row)}
                            handleView={() => handleViewCategoryDialogOpen(row)}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                ) : (
                  <TableRow>
                    <TableCell colSpan={12}>
                      <div className="text-align-center font-18 font-bold py-15" style={{ textAlign: 'center' }}>
                        No data found...
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            component="div"
            count={pageInfo.total ? pageInfo.total : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </>
  ); // END return
} // END CategoryPage
