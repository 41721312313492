import { SET_ALERT, REMOVE_ALERT, RESET_STATE, SHOW_LOADER, SHOW_LOADER_BUTTON, SHOW_UPLOAD_LOADER } from "../actions/Types";

const INIT_STATE = {
    showLoader:false,
    showLoadingButton: false,
    showUploadLoader: {loading:false,type:""}
}

export default function CommonReducer (state = INIT_STATE, action)  {
  const { type, payload } = action;

  switch (type) {
   
    case SHOW_LOADER:
        return{
            ...state,
            showLoader: payload,
        };
        case SHOW_LOADER_BUTTON:
          return{
              ...state,
              showLoadingButton: payload,
          }
    case SHOW_UPLOAD_LOADER:
      return{
          ...state,
          showUploadLoader: payload,
      };
    case RESET_STATE:
      return {...INIT_STATE};

    default:
      return state;
  }
};
