// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/home',
    icon: icon('ic_analytics'),
    rolePermission: ['Admin'],
  },
  {
    title: 'banners',
    path: '/dashboard/banners',
    icon: icon('ic_banner'),
    rolePermission: ['Admin'],
  },
  {
    title: 'categories',
    path: '/dashboard/categories',
    icon: icon('ic_category'),
    rolePermission: ['Admin'],
  },
  {
    title: 'tags',
    path: '/dashboard/tags',
    icon: icon('ic_tag'),
    rolePermission: ['Admin'],
  },
  {
    title: 'products',
    path: '/dashboard/products',
    icon: icon('ic_cart'),
    rolePermission: ['Admin'],
  },
  // {
  // 	title: 'bundles',
  // 	path: '/dashboard/bundles',
  // 	icon: icon('ic_bundle'),
  // 	rolePermission: ['Admin'],
  // },
  {
    title: 'notifications',
    path: '/dashboard/notifications',
    icon: icon('ic_notification_mail'),
    rolePermission: ['Admin'],
  },
  {
    title: 'users',
    path: '/dashboard/users',
    icon: icon('ic_user'),
    rolePermission: ['Admin'],
  },
  {
    title: 'subscriptions',
    path: '/dashboard/subscriptions',
    icon: icon('ic_subscription'),
    rolePermission: ['Admin', 'SubAdmin'],
  },
  {
    title: 'latest feeds',
    path: '/dashboard/latest_feeds',
    icon: icon('ic_bundle'),
    rolePermission: ['Admin', 'SubAdmin'],
  },
];

export default navConfig;
