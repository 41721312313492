import {
	CREATE_SUBSCRIPTION,
	DELETE_SUBSCRIPTION,
	GET_ALL_SUBSCRIPTION,
	GET_SUBSCRIPTION_BY_ID,
	RESET_STATE,
	UPDATE_SUBSCRIPTION,
	GET_ALL_SUBSCRIPTION_TYPE,
	CANCEL_SUBSCRIPTION,
} from "../actions/Types";

const INIT_STATE = {
	subscriptions:[],
	subscriptionTypeList:[],
	subscriptionById:{},
	pageInfo:{},
	addSubscriptionLog:false,
	editSubscriptionLog: false,
	deleteSubscriptionLog : false,
	cancelSubscriptionLog: false,
};

export default function SubscriptionReducer (state = INIT_STATE, action)  {
	const { type, payload } = action;  
	
	switch (type) {
		case GET_ALL_SUBSCRIPTION:
			return {
				...state,
				subscriptions: payload.data.data,
				pageInfo: payload.data
			};
		case GET_ALL_SUBSCRIPTION_TYPE:
			return {
				...state,
				subscriptionTypeList: payload.data,
			};
		case UPDATE_SUBSCRIPTION:
			return {
				...state,
				editSubscriptionLog: !state.editSubscriptionLog,
			};
		case CREATE_SUBSCRIPTION:
			return {
				...state,
				addSubscriptionLog: !state.addSubscriptionLog,
			};
		case DELETE_SUBSCRIPTION:
			return {
				...state,
				deleteSubscriptionLog: !state.deleteSubscriptionLog,
			};
		case CANCEL_SUBSCRIPTION:
			return {
				...state,
				cancelSubscriptionLog: !state.cancelSubscriptionLog,
			}	
		case GET_SUBSCRIPTION_BY_ID:
			return {
				...state,
				subscriptionById: payload.data,
			};
		case RESET_STATE:
			return { ...INIT_STATE };
		default:
			return state;
	}
};
