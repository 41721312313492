export const LOGIN = 'login';
export const LOG_OUT = 'logout';
export const RESET_STATE = 'reset_state';

export const SET_ALERT = 'set-new-alert';
export const REMOVE_ALERT = 'remove-alert';
export const REMOVE_SPECIFIC_ALERT = 'remove-specific-alert';

export const REMINDER_ALERT = 'reminder-alert';
export const DELETE_ALL_ALERT = 'delete-all-alert';

// Related to Loader
export const SHOW_LOADER = 'show-loader';
export const SHOW_LOADER_BUTTON = 'show-loader-button';
export const SHOW_UPLOAD_LOADER = 'show-upload-loader';

// Related To Category
export const CREATE_CATEGORY = 'create-category';
export const UPDATE_CATEGORY = 'update-category';
export const DELETE_CATEGORY = 'delete-category';
export const GET_ALL_CATEGORY = 'get-all-category';
export const GET_CATEGORY_FOR_DROPDOWN = 'get-category-for-dropdown';
export const GET_CATEGORY_BY_ID = 'get-category-by-id';

// Related To Tags
export const CREATE_TAGS = 'create-tags';
export const UPDATE_TAGS = 'update-tags';
export const DELETE_TAGS = 'delete-tags';
export const GET_ALL_TAGS = 'get-all-tags';
export const GET_TAGS_FOR_DROPDOWN = 'get-tags-for-dropdown';
export const GET_TAGS_BY_ID = 'get-tags-by-id';

// Related To Banner
export const CREATE_BANNER = 'create-banner';
export const UPDATE_BANNER = 'update-banner';
export const DELETE_BANNER = 'delete-banner';
export const GET_ALL_BANNER = 'get-all-banner';

// Related to upload file
export const UPLOAD_BANNER_IMAGE = 'upload-banner-image';
export const UPLOAD_PRODUCT_IMAGE = 'upload-product-image';
export const UPLOAD_PRODUCT_FILES = 'upload-product-file';
export const GET_FILE_BY_FOLDER_NAME = 'get-file-by-folder-name';
export const UPLOAD_NOTIFICATION_IMAGE = 'upload-notification-image';
export const UPLOAD_BUNDLE_IMAGE = 'upload-bundle-image';

// Related to delete file
export const DELETE_IMAGE = 'delete-image';
export const DELETE_FILES = 'delete-file';

// Related to Product
export const CREATE_PRODUCT = 'create-product';
export const UPDATE_PRODUCT = 'update-product';
export const DELETE_PRODUCT = 'delete-product';
export const GET_ALL_PRODUCT = 'get-all-product';
export const GET_PRODUCT_BY_ID = 'get-product-by-id';

// Related to User
export const GET_USERS = 'get-users';
export const GET_ALL_USERS = 'get-all-users';
export const CHANGE_STATUS_OF_USER = 'change-status-of-user';
export const DELETE_USER = 'delete-user';

// Related to Dashboard
export const DASHBOARD_HOME = 'dashboard-home';

// Related to password
export const CHANGE_PASSWORD = 'change-password';
export const FORGOT_PASSWORD = 'forgot-password';

// Related To Notification
export const CREATE_NOTIFICATION = 'create-notification';
export const UPDATE_NOTIFICATION = 'update-notification';
export const DELETE_NOTIFICATION = 'delete-notification';
export const GET_ALL_NOTIFICATION = 'get-all-notification';

// Related To Subscription
export const CREATE_SUBSCRIPTION = 'create-subscription';
export const UPDATE_SUBSCRIPTION = 'update-subscription';
export const DELETE_SUBSCRIPTION = 'delete-subscription';
export const GET_ALL_SUBSCRIPTION = 'get-all-subscription';
export const GET_SUBSCRIPTION_BY_ID = 'get-subscription-by-id';
export const GET_ALL_SUBSCRIPTION_TYPE = 'get-all-subscription-type';
export const CANCEL_SUBSCRIPTION = 'cancel-subscription';

// Related to Bandle
export const CREATE_BUNDLE = 'create-bundle';
export const UPDATE_BUNDLE = 'update-bundle';
export const DELETE_BUNDLE = 'delete-bundle';
export const GET_ALL_BUNDLE = 'get-all-bundle';
export const GET_BUNDLE_BY_ID = 'get-bundle-by-id';
export const FETCH_BUNDLE_SEQUENCE = 'fetch-bundle-sequence';

// Related to Feed
export const CREATE_FEED = 'create-feed';
export const UPDATE_FEED = 'update-feed';
export const DELETE_FEED = 'delete-feed';
export const GET_ALL_FEED = 'get-all-feed';
export const GET_FEED_BY_ID = 'get-feed-by-id';
export const GET_MOST_FEV_FEED = 'GET_MOST_FEV_FEED';
