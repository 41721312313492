import JWTServer from '../api/withJWTServer';
import { SetNewAlert } from './AlertActions';
import { HandleExceptionWithSecureCatch, HandleManualException } from './CombineCatch';
import { CREATE_NOTIFICATION, DELETE_NOTIFICATION, GET_ALL_NOTIFICATION, UPDATE_NOTIFICATION } from './Types';

const GetAllNotification = (page, limit, searchValue) => async (dispatch) => {

	try {
		const response = await JWTServer.get(`/api/user-notifications?page=${page}&limit=${limit}&search=${searchValue}&status=1&role_id=3`);
		// const response = await JWTServer.get(`/api/banners?page=${page}&limit=${limit}&search=${searchValue}&status=1`);
		if (response.data.meta.status === 200) {
			dispatch({
				type: GET_ALL_NOTIFICATION,
				payload: response.data,
			});
		} else {
			dispatch(HandleManualException(response.data));
		}
	} catch (e) {
		dispatch(HandleExceptionWithSecureCatch(e));
	}
};

const AddNotification = (params) => async (dispatch) => {
	
	try {
		const response = await JWTServer.post('/api/create-user-notification', params);
		if (response.data.meta.status === 200) {
			dispatch({
				type: CREATE_NOTIFICATION,
				payload: response.data,
			});
			dispatch(
				SetNewAlert({
					msg: response.data.meta.message,
					alertType: 'success',
				})
			);
		} else {
			dispatch(HandleManualException(response.data));
		}
	} catch (e) {
		dispatch(HandleExceptionWithSecureCatch(e));
	}
};

const EditNotification = (params, notificationId) => async (dispatch) => {
	try {
		const response = await JWTServer.put(`/api/update-user-notification/${notificationId}`, params);
		if (response.data.meta.status === 200) {
			dispatch({
				type: UPDATE_NOTIFICATION,
				payload: response.data,
			});
			dispatch(
				SetNewAlert({
					msg: response.data.meta.message,
					alertType: 'success',
				})
			);
		} else {
			dispatch(HandleManualException(response.data));
		}
	} catch (e) {
		dispatch(HandleExceptionWithSecureCatch(e));
	}
};

const DeleteNotification = (params, status) => async (dispatch) => {
	try {
		const response = await JWTServer.delete(`/api/delete-user-notification/${params}`);
		if (response.data.meta.status === 200) {
			dispatch({
				type: DELETE_NOTIFICATION,
				payload: response.data,
			});
			dispatch(
				SetNewAlert({
					msg: response.data.meta.message,
					alertType: 'success',
				})
			);
		} else {
			dispatch(HandleManualException(response.data));
		}
	} catch (e) {
		dispatch(HandleExceptionWithSecureCatch(e));
	}
};

export { GetAllNotification, AddNotification, EditNotification, DeleteNotification };
