import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { sentenceCase } from 'change-case';
import { Helmet } from 'react-helmet-async';
// @mui
import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Container,
  TableContainer,
  TablePagination,
  Tooltip,
  Button,
  Box,
} from '@mui/material';
// components
import Iconify from '../components/iconify';
import Label from '../components/label';
import Scrollbar from '../components/scrollbar';
// sections
import { PagesHead, PagesToolbar, PagesMoreMenu } from '../sections/@dashboard/common';
import DeleteConfirmationDialog from '../components/DiaglogBox/DeleteConfirmationDialog';
import { DeleteFeed, GetAllFeeds, getMostFevFeed } from '../actions/FeedAction';
import { GetCategoryForDropdown } from '../actions/CategoriesAction';
import FeddDialogBox from '../components/DiaglogBox/FeedDialogBox';
import ViewFeedDialog from '../components/DiaglogBox/ViewFeedDialog';
import MostFevFeed from './MostFevFeed';

// ----------------------------------------------------------------------
const TABLE_HEAD = [
  // { id: 'srno', label: 'Sr No.', alignRight: false },
  { id: 'sequence', label: 'Sequence', alignRight: false },
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'Description', label: 'Description', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'action', label: 'Action' },
];

export default function FeedPage() {
  const dispatch = useDispatch();

  const { mostFevFeed, feeds, pageInfo, addFeedLog, editFeedLog, deleteFeedLog, categoriesForDropdown } = useSelector(
    (state) => ({
      feeds: state?.feeds?.feeds,
      pageInfo: state?.feeds.pageInfo,
      addFeedLog: state?.feeds.addFeedLog,
      editFeedLog: state?.feeds.editFeedLog,
      deleteFeedLog: state?.feeds.deleteFeedLog,
      mostFevFeed: state?.feeds?.mostFevFeed,
    })
  );
  // console.log('feeds', feeds);
  // console.log('mostFevFeed', mostFevFeed);

  const statusFilterOptions = [
    { id: 1, status: 'All', value: 9 },
    { id: 2, status: 'Active', value: 1 },
    { id: 3, status: 'Inactive', value: 0 },
  ];

  const [page, setPage] = useState(0);
  const navigate = useNavigate();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = React.useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [data, setData] = React.useState(null);
  const [fevView, setFevView] = useState(false);
  const [searchValue, setSearchValue] = React.useState('');
  const [viewFeedDialogOpen, setViewFeedDialogOpen] = React.useState(false);
  const filterCatArr = [{ id: 0, value: 0, label: 'All Records' }];
  const [filteredValue, setFilteredValue] = React.useState(filterCatArr[0].value);
  const [statusValue, setStatusValue] = React.useState(statusFilterOptions[0].value);
  const [mostFevFeedData, setMostFevFeedData] = useState(mostFevFeed);
  // console.log('mostFevFeedData', mostFevFeedData);

  // useEffect(() => {
  //   setMostFevFeedData([...mostFevFeedData]);
  // }, [mostFevFeed]);

  useEffect(() => {
    dispatch(GetAllFeeds(1, rowsPerPage, searchValue, filteredValue, statusValue));
    setPage(0);
  }, [filteredValue, statusValue]); // END useEffect

  useEffect(() => {
    setConfirmationDialogOpen(false);
    dispatch(GetAllFeeds(page + 1, rowsPerPage, searchValue, filteredValue, statusValue));
    dispatch(GetCategoryForDropdown());
  }, [addFeedLog, editFeedLog, deleteFeedLog]); // END useEffect

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      dispatch(GetAllFeeds(1, rowsPerPage, searchValue, filteredValue, statusValue));
      setPage(0);
    }, 1000);
    return () => clearTimeout(delayDebounce);
  }, [searchValue]); // END useEffect

  // pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(GetAllFeeds(newPage + 1, rowsPerPage, searchValue, filteredValue, statusValue));
  }; // END handleChangePage

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
    dispatch(GetAllFeeds(1, parseInt(event.target.value, 10), searchValue, filteredValue, statusValue));
  }; // END handleChangeRowsPerPage

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  }; // END handleSearchChange

  const handleClearClick = () => {
    dispatch(GetAllFeeds(1, rowsPerPage, ''));
    setPage(0);
    setSearchValue('');
  }; // END handleClearClick

  // Open dialog click
  const handleClickOpen = (e) => {
    setData(null);
    setOpen(true);
  }; // END handleClickOpen

  const handleEdit = (data) => {
    setData(data);
    setOpen(true);
  }; // END handleEdit

  const handleDelete = (data) => {
    setConfirmationDialogOpen(true);
    setData(data);
  }; // END handleDelete

  const handleClose = () => {
    setOpen(false);
    setData();
  }; // END handleClose

  const handleViewFeedDialogClose = () => {
    setViewFeedDialogOpen(false);
    setData(null);
  }; // END handleViewFeedDialogClose

  const handleViewFeedDialogOpen = (data) => {
    setViewFeedDialogOpen(true);
    setData(data);
  }; // END handleViewFeedDialogOpen

  const handleConfirmationClose = (message) => {
    if (message) {
      dispatch(DeleteFeed(data.id));
    } else {
      setConfirmationDialogOpen(false);
      setData(null);
    }
  }; // END handleConfirmationClose

  const handleShowAudioLoader = (data) => {
    // setShowAudioLoader(data);
  }; // END handleShowAudioLoader

  const handleFilterChange = (data) => {
    setFilteredValue(data);
  }; // END handleFilterChange

  const handleStatusChange = (data) => {
    setStatusValue(data);
  }; // END handleFilterChange

  /* END Handle Change Action */

  const handleMostFevFeed = () => {
    navigate('/dashboard/most_like_feeds');
    // dispatch(getMostFevFeed());
    // setFevView(!fevView);
  };

  const handleViewChnage = () => {
    setFevView(false);
  };
  return (
    <>
      <Helmet>
        <title> Latest Feeds | Rushipeetham </title>
      </Helmet>
      {fevView ? (
        <MostFevFeed setView={handleViewChnage} />
      ) : (
        <Container>
          {open ? (
            <FeddDialogBox
              open={open}
              data={data}
              onClose={handleClose}
              handleLoader={(data) => handleShowAudioLoader(data)}
            />
          ) : null}
          {viewFeedDialogOpen ? (
            <ViewFeedDialog
              open={viewFeedDialogOpen}
              data={data}
              feedId={data.id}
              onClose={handleViewFeedDialogClose}
              handleLoader={(data) => handleShowAudioLoader(data)}
            />
          ) : null}

          {confirmationDialogOpen ? (
            <DeleteConfirmationDialog
              message="Latest Feed"
              isOpenConfirm={confirmationDialogOpen}
              handleClose={handleConfirmationClose}
            />
          ) : null}
          <Card>
            <PagesToolbar
              placeHolder={'Search feeds...'}
              onFilterName={handleSearchChange}
              filterName={searchValue}
              handleClearClick={handleClearClick}
              buttonName="New Feed"
              handleClickOpen={handleClickOpen}
              filterValue={filteredValue}
              onFilterChange={handleFilterChange}
              filterTitle={'Filter by category'}
              arrayOfFilterOptions={filterCatArr}
              statusFilterValue={statusValue}
              statusFilterOptions={statusFilterOptions}
              onStatusFilterChange={handleStatusChange}
              mostLikeBtn
              handleMostFevFeed={handleMostFevFeed}
            />

            <Box>
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table aria-label="simple table" size="small">
                    <PagesHead headLabel={TABLE_HEAD} />
                    {feeds.length !== 0 ? (
                      <TableBody>
                        {feeds?.map((row, index) => (
                          <TableRow key={index}>
                            {/* <TableCell>{index + 1}</TableCell> */}
                            <TableCell>{row?.sequence}</TableCell>
                            <TableCell>{row?.feed_title}</TableCell>

                            <TableCell>
                              <Tooltip title={row?.feed_description}>
                                <div
                                  id={index + 1}
                                  style={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    width: '300px',
                                  }}
                                >
                                  {row?.feed_description}...
                                </div>
                              </Tooltip>
                            </TableCell>

                            <TableCell>
                              <Label color={(row?.is_active === 0 && 'error') || 'success'}>
                                {sentenceCase(row?.is_active ? 'Active' : 'Inactive')}
                              </Label>
                            </TableCell>
                            <TableCell align="center">
                              <PagesMoreMenu
                                handleEdit={() => handleEdit(row)}
                                handleDelete={() => handleDelete(row)}
                                handleView={() => handleViewFeedDialogOpen(row)}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    ) : (
                      <TableRow>
                        <TableCell colSpan={12}>
                          <div className="text-align-center font-18 font-bold py-15" style={{ textAlign: 'center' }}>
                            No data found...
                          </div>
                        </TableCell>
                      </TableRow>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>
              <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                component="div"
                count={pageInfo.total ? pageInfo.total : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>
          </Card>
        </Container>
      )}
    </>
  );
}
