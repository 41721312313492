import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sentenceCase } from 'change-case';
import { Helmet } from 'react-helmet-async';
// @mui
import {
	Card,
	Table,
	Paper,
	TableRow,
	TableBody,
	TableCell,
	Container,
	IconButton,
	TableContainer,
	TablePagination,
	Tooltip
} from '@mui/material';
import dayjs from 'dayjs';
import { Visibility} from '@mui/icons-material';
// components
import Label from '../components/label';
import Scrollbar from '../components/scrollbar';
// sections
import { PagesHead, PagesToolbar, PagesMoreMenu } from '../sections/@dashboard/common';
import ProductDialogBox from '../components/DiaglogBox/ProductDialogBox';
import DeleteConfirmationDialog from '../components/DiaglogBox/DeleteConfirmationDialog';
import ViewProductDialog from '../components/DiaglogBox/ViewProductDialog';
import { DeleteProduct, GetAllProducts} from '../actions/ProductActions';
import { GetCategoryForDropdown } from '../actions/CategoriesAction';
import { GetTagsForDropdown } from '../actions/TagsAction';
// ----------------------------------------------------------------------
const TABLE_HEAD = [
	// { id: 'srno', label: 'Sr No.', alignRight: false },
	{ id: 'sequence', label: 'Sequence', alignRight: false },
	{ id: 'name', label: 'Name', alignRight: false },
	{ id: 'short Description', label: 'Short Description', alignRight: false },
	// { id: 'images', label: 'Image', alignRight: false },
	{ id: 'price', label: 'Price', alignRight: false },
	{ id: 'category', label: 'Category', alignRight: false },
	{ id: 'status', label: 'Status', alignRight: false },
	{ id: 'action', label: 'Action',  },
];

export default function ProductsPage() {
	
	/* START Config Data For Page */

	const dispatch = useDispatch();
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [open, setOpen] = React.useState(false);
	const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
	const [data, setData] = React.useState(null);
	const [searchValue, setSearchValue] = React.useState("");
	const [viewProductDialogOpen, setViewProductDialogOpen] = React.useState(false);
	const [imageDialogOpen, setImageDialogOpen] = React.useState(false);
	const [imageData, setImageData] = useState({});
	const [showAudioLoader, setShowAudioLoader] = useState(false);
	let filterCatArr = [{'id':0, 'value':0, 'label':'All Records'}];
	const statusFilterOptions =[
		{id:1,status:"All",value:9},
		{id:2,status:"Active",value:1},
		{id: 3, status: 'Inactive', value: 0 },
	  ]
	const [filteredValue, setFilteredValue] = React.useState(filterCatArr[0].value);
	const [statusValue, setStatusValue] = React.useState(statusFilterOptions[0].value);
	// Binding input field
	const [inputField, setInputField] = useState({
		Name: "",
		SpecialNote: "",
		Sequence: "",
		DisplayStatusName: "",
		ActiveStatus: ""
	})
	const { products, pageInfo,addProductLog,editProductLog,deleteProductLog, categoriesForDropdown } = useSelector((state) => ({
		products: state.products.products,
		pageInfo: state.products.pageInfo,
		addProductLog: state.products.addProductLog,
		editProductLog: state.products.editProductLog,
		deleteProductLog: state.products.deleteProductLog,
		categoriesForDropdown: state.categories.categoriesForDropdown,
	}));// END useSelector
	if(categoriesForDropdown.length > 0){
		filterCatArr = [...filterCatArr, ...categoriesForDropdown?.filter((option) => option.category_type!==undefined).map((option)=> ({"id": option.id, "value": option.id, "label": option.category_name}))];
	}

	/* END Config Data For Page */
	
	/* START Use Effects */

	useEffect(()=>{
		dispatch(GetCategoryForDropdown());
    	dispatch(GetTagsForDropdown());
	},[])

	useEffect(()=>{
		dispatch(GetAllProducts(1,rowsPerPage,searchValue,filteredValue,statusValue));
		setPage(0);
	},[filteredValue,statusValue]); // END useEffect

	useEffect(() => {
		setConfirmationDialogOpen(false);
		dispatch(GetAllProducts(page+1,rowsPerPage,searchValue, filteredValue,statusValue));
		dispatch(GetCategoryForDropdown())

	}, [addProductLog,editProductLog,deleteProductLog]); // END useEffect

	useEffect(()=>{
		const delayDebounce = setTimeout(()=>{
			dispatch(GetAllProducts(1,rowsPerPage,searchValue, filteredValue,statusValue));
			setPage(0);
		},1000)
		return ()=> clearTimeout(delayDebounce)
	},[searchValue]); // END useEffect

	/* END Use Effects */

	/* START Handle Change Action */

	// pagination
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
		dispatch(GetAllProducts(newPage+1,rowsPerPage,searchValue,filteredValue,statusValue));
	};// END handleChangePage

	const handleChangeRowsPerPage = (event) => {
		setPage(0);
		setRowsPerPage(parseInt(event.target.value, 10));
		dispatch(GetAllProducts(1,parseInt(event.target.value, 10),searchValue,filteredValue,statusValue));
	};// END handleChangeRowsPerPage

	const handleSearchChange = (event) => {
		setSearchValue(event.target.value)
	};// END handleSearchChange

	const handleClearClick = () => {
		dispatch(GetAllProducts(1,rowsPerPage,""));
		setPage(0);
		setSearchValue("");
	};// END handleClearClick

	// Open dialog click
	const handleClickOpen = (e) => {
		setData(null);
		setOpen(true);
	};// END handleClickOpen

	const handleEdit = (data) => {
		setData(data);
		setOpen(true);
	};// END handleEdit

	const handleDelete = (data) => {
		setConfirmationDialogOpen(true);
		setData(data);
	};// END handleDelete

	const handleClose = () => {
		setOpen(false);
		setData();
	};// END handleClose

	const handleViewProductDialogClose = () => {
		setViewProductDialogOpen(false);
		setData(null);
	};// END handleViewProductDialogClose

	const handleViewProductDialogOpen = (data) => {
		setViewProductDialogOpen(true);
		setData(data);
	};// END handleViewProductDialogOpen

	const handleImageModalOpen = (image) => {
		setImageDialogOpen(true)
		setImageData(image)
	};// END handleImageModalOpen

	const handleImageModalClose = () => {
		setImageDialogOpen(false)
	};// END handleImageModalClose

	const handleConfirmationClose = (message) => {
		if(message){
			dispatch(DeleteProduct(data.id))
		}
		else {
			setConfirmationDialogOpen(false);
			setData(null);
		}
	};// END handleConfirmationClose

	const handleShowAudioLoader = (data) => {
		setShowAudioLoader(data)
	};// END handleShowAudioLoader

	const handleFilterChange = (data) => {
		setFilteredValue(data)
	}// END handleFilterChange

	const handleStatusChange = (data) => {
		setStatusValue(data)
	}// END handleFilterChange

	/* END Handle Change Action */
	
 
	return (
		<>
			<Helmet>
				<title> Product | Rushipeetham </title>
			</Helmet>
			<Container>
				{
					open?
					<ProductDialogBox
					open={open}
					data={data}
					onClose={handleClose}
					handleLoader={(data)=>handleShowAudioLoader(data)}
					/>:
					null
				}
				{
					viewProductDialogOpen?
					<ViewProductDialog
					open={viewProductDialogOpen}
					data={data}
					onClose={handleViewProductDialogClose}
					handleLoader={(data)=>handleShowAudioLoader(data)}
					/>:null
				}
				{/*
					imageDialogOpen?
					<BannerImageDialog 
					open={imageDialogOpen}
					imageData={imageData}
					handleClose={handleImageModalClose}
					/>:null
				*/} 
				{
					confirmationDialogOpen?
					<DeleteConfirmationDialog
						message="Product"
						isOpenConfirm={confirmationDialogOpen}
						handleClose={handleConfirmationClose}
					/>:null
				}
				<Card>
					<PagesToolbar 
						placeHolder={"Search Product..."}  
						onFilterName={handleSearchChange} 
						filterName={searchValue} 
						handleClearClick={handleClearClick} 
						buttonName="New Product" 
						handleClickOpen={handleClickOpen} 
						filterValue={filteredValue}
						filterable
						onFilterChange={handleFilterChange}
						filterTitle={"Filter by category"} 
						arrayOfFilterOptions={filterCatArr}

						statusFilterValue={statusValue}
						statusFilterOptions={statusFilterOptions}
						onStatusFilterChange={handleStatusChange}

					/>
					<Scrollbar>
						<TableContainer sx={{ minWidth: 800 }}>
							<Table aria-label="simple table" size="small">
								<PagesHead headLabel={TABLE_HEAD} />
								{
									products.length !== 0 ? 
									<TableBody>
										{
											products?.map((row, index) => (
												<TableRow key={index}>
													{/* <TableCell>{index + 1}</TableCell> */}
													<TableCell>{row?.sequence}</TableCell>
													<TableCell>
														{/* row?.product_name.toString().trim() } { row?.is_new_product === null ?<sup><mark><i> New </i></mark></sup> :'' */}
														{ row?.is_new_product === 1 && dayjs(row?.new_product_expiry_date).diff(dayjs(new Date()), 'days') >= 0 ?  <> {row?.product_name.toString().trim()} <img style={{marginLeft:'5px'}} src="/assets/images/new_product.gif" width="13%" height="13%" alt="New Product" /> </>:row?.product_name }
													</TableCell>
													{/* <TableCell>
															{
																row?.banner_image?.length!==0?
																<IconButton
																	aria-label="delete"
																	size="large"
																	// onClick={() => handleViewOpen(option.images)}
																	onClick={(e) => {
																	// window.open(`${process.env.REACT_APP_BASE_URL} + '/api/' + ${row?.banner_image[0].file_path}`);
																	handleImageModalOpen(`${row?.banner_image[0].file_path}`);
																	}}
																	sx={{color: '#393939'}}
																>
																	<Visibility />
																</IconButton>
																:
																"NA"
															}	
														</TableCell> 
													*/}
													<TableCell>												
														<Tooltip title={row?.short_descripton}>
															<div id={index + 1}
																style={{
																	whiteSpace: 'nowrap',
																	overflow: 'hidden',
																	textOverflow: 'ellipsis',
																	width: '300px',
																}}>
																{row?.short_descripton}...
															</div>
														</Tooltip>
													</TableCell>
													<TableCell>₹{row?.price}</TableCell>
													<TableCell>{row?.category?.category_name}</TableCell>
													<TableCell>
														<Label color={(row?.is_active === 0 && 'error') || 'success'}>
															{sentenceCase(row?.is_active?"Active":"Inactive")}
														</Label>
													</TableCell>
													<TableCell align="center">
														<PagesMoreMenu
															handleEdit={() => handleEdit(row)}
															handleDelete={() => handleDelete(row)}
															handleView = {() => handleViewProductDialogOpen(row)} 
														/>
													</TableCell>
												</TableRow>
											))
										}
									</TableBody>
									: 
									<TableRow> 
										<TableCell colSpan={12}>
											<div className='text-align-center font-18 font-bold py-15' style={{'textAlign': 'center'}}>
												No data found...
											</div>
										</TableCell>
									</TableRow>
								}
							</Table>
						</TableContainer>
					</Scrollbar>
					<TablePagination
						rowsPerPageOptions={[10, 20, 30]}
						component="div"
						count={pageInfo.total?pageInfo.total:0}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				</Card>
			</Container>
		</>
	);
}
